<template lang="pug">
  //- b-container(fluid).h-100.w-100.p-0.m-0
    //- .bg
  .poll
    livelike-widgets.animated.slideInDown.shadow-lg(id="livelikeWidget" programid="c14a7d72-5367-41fa-b990-14b25777326d")
</template>

<script>
import { mapState } from "vuex";

//import axios from "axios";

export default {
  name: "",
  data() {
    return {};
  },
  mounted: async function() {
    let clientId = '7x4nrfMirmTtcPXzgC3TagmoiwUOMw4b3FEJBwX0'
    let accessToken = localStorage.LiveLikeAccessToken
    
    if (accessToken) {
      // Already saved an access token: re-use it!
      await window.LiveLike.init({
        clientId,
        accessToken: accessToken
      });
    } else {
      // No saved access token yet, save it for next time
      const profile = await window.LiveLike.init({ clientId })
      localStorage.LiveLikeAccessToken = profile.access_token
      /* await window.LiveLike.updateProfile({
        nickName: this.fanID,
        clientId,
        accessToken
      }) */
    }
  },
  computed: {
    ...mapState({
      fanID: state => state.fanfest.registration.fan.objectId,
    }),
  },
  watch: {},
  sockets: {},
  methods: {
  },
};
</script>

<style scoped>
  .bg {
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.80;
    /* z-index: 10000; */
  }
  .poll {
    position: fixed;
    top: 0;
    left: 12.5vw;
    width: 75vw;
    opacity: 1.0;
    /* z-index: 10000; */
  }
</style>
