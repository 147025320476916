<template lang="pug">
div
  b-button.text-uppercase.font-weight-bold.d-block.mx-auto.my-5.w-75(variant='success' @click="showEditor('new')") {{ $t('admin.createofferbutton') }} 

  // List
  .table-responsive
    table.table.table-striped
      tbody
        tr(v-for="o, i in this.offers" style="height: 30px")
          td.align-middle(@click="showEditor(o.id)")
            img.mx-auto.d-block(:src="o.get('logo')" style="height:5vh")
          td.align-middle(@click="showEditor(o.id)")
            .table-text.text-dark.text-underline( :to="`${fanfest}/offer/${o.id}`" target="_self" :class="[o.get('isActive') ? 'font-weight-bold' : '']") {{ shortTitle(o.get('title')) }}
            //- router-link.table-text.text-dark.text-underline( :to="`${fanfest}/offer/${o.id}`" target="_self" :class="[o.get('isActive') ? 'font-weight-bold' : '']") {{ shortTitle(o.get('title')) }}
          td.align-middle 
            b-button.py-0.ml-3(v-if="i > 0" variant='outline-secondary' @click="moveToTop(o.id)") Show First
  // Create Offer
  b-modal(id="modal-offer-editor" size='lg' centered hide-header hide-footer)
    OfferEditor(:offerID='selectedOffer' @update="loadProfile()")
</template>


<script>
import OfferEditor from '@/components/admin/OfferEditor'

import Parse from "../../services/parse";
let Profile = Parse.Object.extend("FanfestConfig");
let Offer = Parse.Object.extend("Offer");

export default {
  name: "OfferList",
  data() {
    return {
      offers: null,
      selectedOffer: '',
      fanfest: this.$route.params.fanfest
    };
  },
  components: {
    OfferEditor
  },
  mounted: function () {
    this.loadProfile();
  },
  watch: {},
  sockets: {},
  methods: {
    loadProfile: async function () {
      let query = new Parse.Query(Profile);
      query.equalTo('name', this.$route.params.fanfest)
      this.profile = await query.first().catch((error) => alert(error))

      this.loadOffers()
    },
    loadOffers: async function () {
      if (!this.profile) return

      let query = new Parse.Query(Offer);
      query.equalTo('audience', 'Group')
      query.equalTo("audienceID", this.profile.get('group').id)
      query.equalTo("isActive", true)
      query.descending("updatedAt")
      query.limit = 10000
      this.offers = await query.find().catch((error) => alert(error))
    },
    moveToTop: async function(offerID) {
      let offer = this.offers.filter((o) => o.id === offerID)[0]
      await offer.save()
      await this.loadOffers()
    },
    showEditor: function(offerID) {
      this.selectedOffer = offerID
      this.$bvModal.show('modal-offer-editor')
    },
    shortTitle: function(title) {
      let limit = 50
      let shortTitle = title.substring(0,limit)
      if (title.length > limit) shortTitle += '...'
      return shortTitle
    }
  },
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 100vh;
  overflow-x: hidden;
}

.table-text {
  font-size: 15px;
}

.table-row {
  height: 30px
}
</style>