import Vue from 'vue'

import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

// Logging
// eslint-disable-next-line
import LogRocket from './services/logrocket'

// Vue-Meta
import Meta from 'vue-meta'
Vue.use(Meta)

// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Animate
import AnimateCSS from 'animate.css'
Vue.use(AnimateCSS)

// Chat Scroll
import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

// Socket.io
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
const socketInstance = SocketIO(process.env.VUE_APP_SOCKETS, {
  transports: ['websocket'],
});
Vue.use(new VueSocketIO({
  debug: true,
  connection: socketInstance
})
);

// Vue-Router
import router from './router'

// Vue-i18n
import i18n from './services/translation'

// Google Analytics
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: `${process.env.VUE_APP_GTAG}` }
}, router);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')