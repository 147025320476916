<template lang="pug">
div
  b-button.text-uppercase.font-weight-bold.d-block.mx-auto.mt-5.mb-3(variant='secondary') {{ `${fans.length} total fans` }}
  b-table.tall-table(striped responsive :small="windowWidth < 600" :items="fans")
</template>


<script>
//import axios from "axios";

import Parse from "../../services/parse";
let Registration = Parse.Object.extend("Registration");

export default {
  name: "FanList",
  data() {
    return {
      registrations: [],
      fans: [],
      windowWidth: window.innerWidth,
    };
  },
  mounted: function () {
    this.loadFans();
  },
  watch: {},
  sockets: {},
  methods: {
    loadFans: async function () {
      let query = new Parse.Query(Registration);
      query.equalTo('profile', this.$route.params.fanfest)
      query.descending('createdAt')
      query.include('fan')
      query.limit(100000)
      this.registrations = await query.find().catch((error) => alert(error))

      console.log(this.registrations.length)

      var fanIDs = new Set()
      for (var r of this.registrations) {
        let fan = r.get('fan')
        if (!this.shouldAddFan(fan)) continue
        if (fanIDs.has(fan.id)) continue

        let fanData = {
          name: `${fan.get('fname')} ${fan.get('lname').substring(0,1)}.`,
          email: `${fan.get('email').substring(0,2)}*******`,
          city: r.get('location') ? r.get('location').city : '',
          country: r.get('location') ? r.get('location').country : '',
          language: fan.get('language'),
          last_event: r.get('eventTitle'),
        }

        this.fans.push(fanData)

        fanIDs.add(fan.id)
      }
    },
    shouldAddFan: function (fan) {
      let email = fan.get('email')
      let name = fan.get('name')

      if (name.indexOf('Adam Jones') >= 0) return false
      if (email.indexOf('playvici') >= 0) return false

      return true
    }
  },
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 100vh;
}

.tall-table {
  height: 100vh
}
</style>