<template lang="pug">
  
    .row.justify-content-center(:class="[showVOD ? '' : 'd-none']")
      .col.align-self-center.p-0
        #buffoverlaywrapper.mx-auto.d-block
          //- b-overlay(:show='hasMenu' variant='dark' no-center )
          .embed-responsive.embed-responsive-16by9.d-flex(:class="(platformType != 'mobile' && totalStreamers == 0) ? 'video-full' : ''" style="overflow:visible;") 

              // VOD
              video.px-1(v-if="contentType==='vod'" ref='ffVOD' @volumechange='' @playing='' @pause=''  playsinline loop preload=auto muted controls autoplay style="overflow:hidden;")
              
              // Embed
              iframe.embed-responsive-item.px-1(v-if="contentType==='iframe' && iframeURL.length > 0" ref='ffEmbed' src='' frameborder="0" allowfullscreen="false" scrolling="no" playsinline autoplay muted style="overflow:hidden;")

              // Tweet
              .px-1(v-show="contentType==='tweet'" ref='tweet' :class="(platformType === 'mobile' && windowWidth < 575) ? 'tweet-mobile' : 'tweet'") 

              //- // Hidden Content Overlay
              //- .hidden-content(v-if='didHostHide')

              //- button.live-button.btn.btn-sm.font-weight-bold.text-uppercase(:class="[didHostHide ? 'btn-dark' : 'btn-danger', contentType === 'tweet' ? 'd-none' : '']") {{ contentStatus }}
</template>

<script>
import { mapState, mapMutations } from "vuex";

// import urlparse from "url-parse"
// import qs from "query-string"
// import _ from "underscore"

export default {
  name: "FanFestVOD",
  data() {
    return {
      hls: undefined,
      hlsConfig: {
        // debug: true,
        nudgeOffset: 1.0
        //capLevelToPlayerSize: true,
        //capLevelOnFPSDrop: true,
      },
      // hasMenu: false,
      // canSendUpdate: true,
      // contentType: "vod",
      // iframeURL: "",
      // customVODURL: "",
      // tweetID: "",
      // listVOD: [],
      canHide: false,
      windowWidth: 0,
    };
  },
  mounted: function() {
    this.hls = new window.Hls(this.hlsConfig);

    this.$refs.ffVOD.addEventListener('pause', async () => {
      if (!this.showVOD) return // don't autoplay if hidden
      if (this.platformType != 'mobile') return

      let muteState = this.$refs.ffVOD.muted
      this.$refs.ffVOD.muted = true
      await this.$refs.ffVOD.play().catch(() => {
        console.log('not a pause, switching content...')
      })
      setTimeout(() => {
        this.$refs.ffVOD.muted = muteState
      }, 1000);
    })

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    })

    /* this.$refs.ffVOD.addEventListener('click', async () => {
      if (!this.showVOD) return // don't react if hidden
      this.$refs.ffVOD.muted = false
    }) */

    // this.hls.autoLevelCapping = 0;

    /* window.addEventListener("orientationchange", () => {
      console.log("orientation change");
      // this.handleMobileVideo();
    }); */
    
    /* let self = this;
    window.addEventListener("beforeunload", function(event) {
      if (self.isHost) self.$socket.emit("hideFFVOD", self.roomForContent);
      console.log(event);
    }); */
  },
  computed: {
    ...mapState({
      // groupID: state => state.client.group.objectId,
      isEmbed: state => state.client.isEmbed,
      groupName: state => state.client.group.name,
      platformType: state => state.client.platformType,
      shouldMute: state => state.client.shouldMute,
      shouldMuteVideo: state => state.client.shouldMuteVideo,
    }),
    ...mapState({
      fanfestMemberCount: state => state.fanfest.numMembers,
      fanfestStreams: state => state.fanfest.streams,
      fanfestStreamsVIP: state => state.fanfest.streamsVIP,
      roomForContent: state => state.fanfest.roomForContent,
      roomForFans: state => state.fanfest.roomForFans,
      canHost: state => state.fanfest.canHost,
      hasHost: state => state.fanfest.hasHost,
      isHost: state => state.fanfest.isHost,
      showVOD: state => state.fanfest.showVOD,
      url: state => state.fanfest.urlVOD,
      iframeURL: state => state.fanfest.urlVideoEmbed,
      tweetID: state => state.fanfest.urlTweet,
      contentType: state => state.fanfest.contentType,
      didHostHide: state => state.fanfest.didHostHide,
      hasPolls: state => state.fanfest.hasPolls
    }),
    totalStreamers: function() {
      return this.fanfestStreams.length + this.fanfestStreamsVIP.length
    },
    contentStatus: function() {
      return this.didHostHide ? 'hidden' : 'live'
    }
  },
  watch: {
    /* fanfestMemberCount: function() {
      if (!this.showVOD) return
      if (this.platformType == 'mobile' && !this.shouldMuteVideo)
        this.handleMobileVideo();
    }, */
    /* shouldMuteVideo: function() {
      if (!this.showVOD) return
      /* if (!this.shouldMuteVideo)
        this.$refs.ffVOD.currentTime = this.hls.liveSyncPosition

      else if (this.platformType == 'mobile') {
        /* this.$refs.ffVOD.pause()
        setTimeout(() => {
          this.$refs.ffVOD.play()
        }, 3000);
      }
    }, */
    showVOD: function() {
      if (!this.showVOD) this.hide();
    },
    url: function() {
      if (this.isHost && this.url != '') 
        setTimeout(() => { this.show() }, 0);
    },
    iframeURL: function() {
      if (this.isHost && this.iframeURL != '')
        setTimeout(() => { this.updateEmbed() }, 0);
    },
    tweetID: function() {
      if (this.isHost && this.tweetID != '')
        setTimeout(() => { this.updateTweet() }, 1000);
    },
    didHostHide: function() {
      if (this.isHost) {
        this.hidePollFormatting(false)    
        removeAllChildNodes(this.$refs.tweet)    
        this.$emit('toggleVideo', this.didHostHide) 

        if (this.$refs.ffVOD && this.didHostHide) {
          this.$refs.ffVOD.muted = true     
          this.$refs.ffVOD.pause()    
        }
      }
    }
  },
  sockets: {
    showFFVOD: function(url) {
      if (!url) return;
      if (url == "") return;
      if (this.isHost) return;

      console.log('received host update: show ffvod')

      this.canHide = false

      // this.contentType = "vod";
      const self = this;
      setTimeout(() => {
        self.$store.commit("showFFVOD", url);
      }, 1000);
    },
    updateFFVOD: function(data) {
      // if (!this.$refs.ffVOD) return;
      if (this.isHost) return;
      if (!this.hasHost) this.$store.commit("hasHost", true);

      console.log('received host update: update ffvod')

      this.canHide = false

      // this.contentType = "vod";

      //url, muted, time
      let url = data[0];
      // let muted = data[1];
      //let time = data[2];
      let paused = data[3];
      let shouldShowVOD = data[4];

      // this.$refs.ffVOD.muted = true;

      if (url == "") return;

      //let timeDelta = Math.abs(parseInt(time) - this.$refs.ffVOD.currentTime);
      //if (timeDelta > 30) this.$refs.ffVOD.currentTime = parseInt(time);

      if (!shouldShowVOD && this.showVOD) this.$store.commit("hideFFVOD");
      else if (shouldShowVOD && !this.showVOD) {
        this.$store.commit("showFFVOD", url);
        this.show()
      }
      else if (url && this.url != url) {
        this.$store.commit("showFFVOD", url);
        this.show()
      }
      else if (paused && !this.$refs.ffVOD.paused) this.$refs.ffVOD.pause();
      else if (!paused && this.$refs.ffVOD.paused) this.$refs.ffVOD.play();

      // this.iframeURL = "";
      // this.tweetID = "";
      removeAllChildNodes(this.$refs.tweet)
      this.hidePollFormatting(false)
    },
    updateFFEmbed: function(url) {
      if (this.isHost) return;
      if (!this.hasHost) this.$store.commit("hasHost", true);

      console.log('received host update: update ffembed')
      
      this.canHide = false
      // if (!this.showVOD)
      //   this.$store.commit("showFFVOD", '')
      
      if (this.iframeURL === url)
        return;

      // this.iframeURL = url;
      // this.contentType = "iframe";
      this.$store.commit('showFFEmbed', url)

      const self = this;
      setTimeout(() => {
        self.$refs.ffEmbed.src = self.iframeURL;
      }, 1000);

      // this.updateFFVOD("");
      // this.tweetID = "";
      removeAllChildNodes(this.$refs.tweet)
      this.hidePollFormatting(false)
    },
    updateFFTweet: function(tweetID) {
      if (this.isHost) return;
      if (!this.hasHost) this.$store.commit("hasHost", true);

      console.log('received host update: update fftweet')
      
      this.canHide = false
      // if (!this.showVOD)
      //   this.$store.commit("showFFVOD", '')
      
      if (this.tweetID === tweetID)
        return;

      this.$store.commit('showFFTweet', tweetID)
      
      this.updateTweet()
      /* removeAllChildNodes(this.$refs.tweet)
      this.hidePollFormatting(true)

      // this.tweetID = tweetID;
      // this.contentType = "tweet";
      

      const self = this;
      setTimeout(() => {
        window.twttr.widgets.createTweet(self.tweetID, self.$refs.tweet, { 
        theme: 'dark',
        align: 'center',
        conversation: 'none',
        // width:200,
        dnt: true
      })
      }, 1000); */

      // this.updateFFVOD("");
    },
    toggleFFVOD: function(hidden) {
      if (this.isHost) return;
      if (!this.hasHost) this.$store.commit("hasHost", true);

      console.log('received host update: toggle content')

      this.canHide = false
      if (!this.showVOD)
        this.$store.commit("showFFVOD", '')

      setTimeout(() => {
        this.hidePollFormatting(false)
        // this.updateFFVOD("");
        // removeAllChildNodes(this.$refs.tweet)
        // this.tweetID = "";
        // this.iframeURL = "";      
        // this.didHostHide = hidden
        
        this.$emit('toggleVideo', hidden) 
        this.$store.commit('toggleContentVisibility', hidden)
        if (this.$refs.ffVOD && hidden) {
          this.$refs.ffVOD.muted = true     
          this.$refs.ffVOD.pause()    
        }
      }, 500);
    },
    hideFFVOD: function() {
      // this.updateFFVOD("");
      // this.iframeURL = "";
      // this.tweetID = "";
      removeAllChildNodes(this.$refs.tweet)
      this.$store.commit("hideFFVOD");
      this.$store.commit("hasHost", false);
      this.hls.destroy()
    },
  },
  methods: {
    ...mapMutations(["updateFFVOD"]),
    /* config: function() {
      const self = this;
      if (self.isHost && self.showVOD) {
        self.canHide = true
        setInterval(() => {
          if (!self.showVOD)
            return;            
          if (self.hasMenu)
            return;

          if (self.didHostHide === true) {
            self.$socket.emit("toggleFFVOD", self.roomForContent, self.didHostHide)
            return;
          }
          
          if (self.$refs.ffVOD && (isURLValid(self.url) || isURLValid(self.customVODURL)))
            self.update();
          else if (self.$refs.ffEmbed && isURLValid(self.iframeURL))
            self.$socket.emit("updateFFEmbed", self.roomForContent, self.$refs.ffEmbed.src);
          else if (self.$refs.tweet)
            self.$socket.emit("updateFFTweet", self.roomForContent, self.tweetID);
        }, 5000);

        var organizerID = self.$store.state.client.organizer.objectId;
        axios
          .get(`${process.env.VUE_APP_CONFIG_ROOT}/${organizerID}/vod/all`)
          .then(response => {
            self.listVOD = response.data;
            self.show();
            // self.showMenu("vod");
          })
          .catch(err => Promise.reject(err.message));
      }
    }, */
    show: function(url) {
      if (!this.showVOD) return;
      if (!this.$refs.ffVOD) {
        setTimeout(() => {
          this.show(url)
        }, 1000);

        return;
      }
      console.log("Showing FFVOD...");

      /* if (!this.isHost && this.platformType === 'mobile')
        this.$store.commit('updateMute', true) */

      if (!url) url = this.url;
      if (url == '') return;

      /* if (this.isHost && url === "") {
        this.showMenu("vod");
        return;
      } */

      //this.$refs.ffVOD.muted = true;

      if (this.$refs.ffVOD.src) {
        this.hls.destroy();
        this.hls = new window.Hls();
      }

      if (window.Hls.isSupported()) {
        this.hls.loadSource(url);
        this.hls.attachMedia(this.$refs.ffVOD);
        this.hls.on(window.Hls.Events.ERROR, function (event, data) {
          console.log(`[vici]: hls error: ${event} - ${JSON.stringify(data)}`)
          if (data.fatal) {
            switch(data.type) {
            case window.Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
              if (!this.showVOD) break;
              console.log("fatal network error encountered, try to recover");
              this.hls.startLoad();
              break;
            case window.Hls.ErrorTypes.MEDIA_ERROR:
              if (!this.showVOD) break;
              console.log("fatal media error encountered, try to recover");
              this.hls.recoverMediaError();
              break;
            default:
            // cannot recover
              this.hls.destroy();
              break;
            }
          }
        });
      } else {
        this.$refs.ffVOD.src = url;
        this.$refs.ffVOD.addEventListener("loadedmetadata", () => {
          this.$refs.ffVOD.play();
        });
      }

      /* if (this.isHost && isURLValid(url)) {
        this.$socket.emit("showFFVOD", this.roomForContent, url);
        this.$refs.ffVOD.controls = true;
      } */

      this.hidePollFormatting(false)
    },
    hide: function() {
      console.log("Hiding FFVOD...");
      if (this.$refs.ffVOD) {
        this.hls.destroy()
        this.$refs.ffVOD.muted = true;
        this.$refs.ffVOD.src = "";
        this.$refs.ffVOD.load();
        this.hasMenu = false;
      }

      if (this.$refs.ffEmbed) {
        // this.iframeURL = ""
        this.hasMenu = false;
      }

      /* if (this.canHide) this.$socket.emit("hideFFVOD", this.roomForContent); */
      // this.tweetID = "";
      removeAllChildNodes(this.$refs.tweet)
    },
    /* update: function() {
      if (!this.$refs.ffVOD) return;
      if (!this.showVOD) return;
      if (!this.isHost) return;
      if (!this.canSendUpdate) return;
      if (!isURLValid(this.url)) return;

      /* console.log("Updating FFVOD...");
      removeAllChildNodes(this.$refs.tweet)
      this.hidePollFormatting(false)

      this.canSendUpdate = false;

      let vod = this.$refs.ffVOD;
      this.$socket.emit(
        "updateFFVOD",
        this.roomForContent,
        this.url,
        vod.muted,
        vod.currentTime,
        vod.paused,
        this.showVOD
      );
      setTimeout(() => {
        this.canSendUpdate = true;
      }, 0);
    }, */
    updateEmbed: function() {
      // this.updateFFVOD("");
      removeAllChildNodes(this.$refs.tweet)
      this.hidePollFormatting(false)
      
      /* let ytURL = function(url) {
        let urlObject = urlparse(url)
        let queryObject = qs.parse(urlObject.query)
        let ytID = queryObject.v
        console.log(ytID)
        return `https://www.youtube.com/embed/${ytID}?controls=1&autoplay=1&mute=1`
      }

      let twitchURL = function(url) {
        let twitchID = url.split('/').pop().trim()
        console.log(twitchID)
        return `https://player.twitch.tv/?channel=${twitchID}&parent=${window.location.hostname}&muted=true`
      }

      if (this.iframeURL.indexOf('youtube') > 0) 
        this.$refs.ffEmbed.src = ytURL(this.iframeURL)
      else if (this.iframeURL.indexOf('twitch') > 0) 
        this.$refs.ffEmbed.src = twitchURL(this.iframeURL)
      else */
        this.$refs.ffEmbed.src = this.iframeURL

      /* if (this.isHost && isURLValid(this.iframeURL))
        this.$socket.emit("updateFFEmbed", this.roomForContent, this.$refs.ffEmbed.src); */
    },
    updateTweet: function() {
      // check valid numerical tweet id
      var re = /^[0-9]*$/
      if (!re.test(this.tweetID)) return

      // this.updateFFVOD("");
      this.hidePollFormatting(true)
      removeAllChildNodes(this.$refs.tweet)

      window.twttr.widgets.createTweet(this.tweetID, this.$refs.tweet, { 
        theme: 'dark',
        align: 'center',
        conversation: 'none',
        // width:200,
        dnt: true
      })
      .then( function(el) {
        console.dir(el);
      });

      /* if (this.isHost)
        this.$socket.emit("updateFFTweet", this.roomForContent, this.tweetID); */
    },
    sendHostMute: function() {
      /* if (!this.isHost) return
      this.$socket.emit("hostMute", this.roomForFans);
      this.hideMenu('hostMute') */
    },
    sendToggleFFVOD: function() {
      /* if (!this.isHost) return
      
      if (this.didHostHide === undefined)
        this.didHostHide = true
      else
        this.didHostHide = !this.didHostHide

      if (this.didHostHide){
        if (this.$refs.ffVOD) this.$refs.ffVOD.pause()
        this.tweetID = "";
        removeAllChildNodes(this.$refs.tweet)
      }

      this.hidePollFormatting(false)

      this.$socket.emit("toggleFFVOD", this.roomForContent, this.didHostHide)
      
      this.hideMenu('toggleFFVOD') */
    },
    showMenu: function(menu) {
      if (menu === "vod") this.contentType = "vod";
      else if (menu === 'iframe') this.contentType = "iframe";
      else if (menu === 'tweet') this.contentType = "tweet";

      this.hasMenu = true;

      if (this.$refs.ffVOD) this.$refs.ffVOD.controls = false;
    },
    hideMenu: function(menu) {
      console.log(menu);
      this.hasMenu = false;
      if (this.$refs.ffVOD) this.$refs.ffVOD.controls = true;
    },
    processMenuSelection: function(selection) {
      if (this.contentType === "vod") {
        // this.iframeURL = "";

        if (selection === "playVOD") this.customVODURL = "";

        var nextURL = "";
        if (this.customVODURL) nextURL = this.customVODURL;
        else
          nextURL = this.$refs.menuFFVODSelect.options[
            this.$refs.menuFFVODSelect.options.selectedIndex
          ].value;

        this.updateFFVOD(nextURL);
      } else if (this.contentType === "iframe") {
        this.updateEmbed();
      }
      else if (this.contentType === "tweet") {
        this.updateTweet();
      }

      this.hideMenu(this.contentType);
    },
    hidePollFormatting: function(shouldHide) {
      if (!this.hasPolls) return

      let wrapper = document.getElementsByClassName('buff-player-wrapper')[0]
      if(wrapper) wrapper.style.overflow = shouldHide ? 'visible' : 'hidden'

      let topOverlay = document.getElementsByClassName('buff-player-overlay-top')[0]
      if (topOverlay) topOverlay.style.display = shouldHide ? 'none' : 'flex'

      let fullscreenButton = document.getElementsByClassName('buff-fullscreen')[0]
      if (fullscreenButton) fullscreenButton.style.display = shouldHide ? 'none' : 'flex'
    },
    /* playVideoSound: function() {
      if (!this.showVOD) return
      this.$store.commit('updateMute', true);
      this.$refs.vodsoundbutton.classList.add('d-none')
    }, */
    handleMobileVideo: async function() {
      if (!this.showVOD) return

      /* console.log('handling mobile video...')
      
      if (!this.shouldMuteVideo) { // watch mode
        if (this.$refs.ffVOD.muted) return
        this.$refs.ffVOD.muted = true;  
        setTimeout(() => {
          this.$refs.ffVOD.muted = false;  
        }, 3000);
      }
      else { // talk mode
        this.$refs.ffVOD.muted = true;
        // await this.$refs.video.pause();
          // setTimeout(() => {        
        //   // this.$refs.video.muted = false;
        //   this.$refs.video.play();
        // }, 3000);  
      }     */  
    }, 
  }
};

/* function isURLValid(url) {
  if (!url) return false
  if (url == '') return false
  return true
} */

function removeAllChildNodes(parent) {
  while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
  }
}
</script>

<style>
.menu-button-vod {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 2px;
  font-size: 1.2vh;
}

.menu-button-stream {
  position: absolute;
  top: 50px;
  left: 20px;
  padding: 2px;
  font-size: 1.2vh;
}

.menu-button-tweet {
  position: absolute;
  top: 80px;
  left: 20px;
  padding: 2px;
  font-size: 1.2vh;
}

.menu-button-mute {
  position: absolute;
  top: 140px;
  left: 20px;
  padding: 2px;
  font-size: 1.2vh;
}

.menu-button-hideVOD {
  position: absolute;
  top: 110px;
  left: 20px;
  padding: 2px;
  font-size: 1.2vh;
}

.menu-button-exit {
  position: absolute;
  top: 5%;
  right: 2%;
}

.live-button {
  position: absolute;
  top: 5%;
  /* left: 10px;
  font-size: 1.5vw;
  padding: 5px; */
  right: 3%;
  font-size: 1vh;
}

.tweet {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20vw;
}

.tweet-mobile {
  position: absolute;
  overflow: visible;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  z-index: 1000;
}

.video-full {
  /* padding-left: 8rem !important;
  padding-right: 8rem !important; */
  max-height: 85vh;
}

.hidden-content {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 10vh;
  left: 0vh;
  height: 60vh;
  width: 50vw;
}

</style>