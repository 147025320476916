<template lang="pug">
b-container.notification-wrapper.animated(v-show='!isChatVisible && show' :class="show ? 'fadeIn faster' : 'fadeOut faster'")
  transition(name="custom-classes-transition" enter-active-class='animated zoomIn faster' leave-active-class='animated zoomOut faster' mode="out-in")
    div(:key='index' @click="tappedNotification('chat')" :class="[(platformType === 'mobile' && windowWidth < 575) ? 'notification-mobile' : 'notification', show ? '' : '']")
      .font-weight-bold.text-uppercase {{ message.author }}
      .w-100 {{ message.text }}
    
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "Notification",
  data() {
    return {
      message: {},
      interval: 5000,
      index: 0,
      show: false,
      windowWidth: 0,
    };
  },
  mounted: function() {
    setInterval(() => {
      this.checkQueue()
    }, this.interval);

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    })
  },
  computed: {
    ...mapState({
      queue: state => state.chat.missedMessages,
      isChatVisible: state => state.chat.visible,
      platformType: state => state.client.platformType,
    }),
  },
  watch: {
    isChatVisible: function() {
      if (this.isChatVisible) {
        this.message = {}
      }
    }
  },
  sockets: {},
  methods: {
    checkQueue: function() {
      if (this.queue.length === 0) {
        this.show = false
        return
      }
      
      this.message = this.queue[0]
      this.index++
      this.show = true
      this.$store.commit('removeMissedMessage')
    },
    tappedNotification: function(target) {
      if (target === 'chat') this.$store.dispatch('updateSidebar', 'chat')
    }
  }
};
</script>


<style scoped>
.notification-wrapper {
    position: absolute;
    bottom: 20vh;
    left: 2vw;
}
.notification {
    background-color: rgba(0, 0, 0, 0.8); 
    color: white;
    position: absolute;
    position: fixed;
    bottom: 20vh;
    left: 1vw;
    left: 2vw;
    width: 20vw;
    border-radius: 1rem;
    z-index: 20000;
    padding: 20px;
    word-wrap: break-word;
    font-size: 2vh;
}
.notification-mobile {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    position: absolute;
    position: fixed;
    bottom: 15vh;
    width: 80vw;
    border-radius: 1rem;
    z-index: 20000;
    padding: 12px;
    margin-left: 10vw;
}
</style>