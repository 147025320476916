<template lang="pug">
  .chat.mt-0.p-2(v-chat-scroll="{always: true, smooth: true}")
    //- .row.chat-audience.m-0(v-if="canHost || isVIP")
      .col.p-0
        b-button.d-block.mx-auto.text-uppercase.font-weight-bold(v-if="!isBackstage && !$route.query.kiosk" @click="changedAudience()" :class="hasUnreadChannel ? 'bg-success animated tada' : 'bg-secondary'") {{ audienceButton }}
    .row.chat-messages.m-0.align-items-end(v-if='didLoad' @mouseover="didHoverChat = true" @mouseleave="didHoverChat = false")(v-chat-scroll="{always: true, smooth: true}")
      .col.mx-0.px-0.py-3(:class="[platformType == 'mobile' ? 'py-1' : 'py-0']" style="overflow-y:none;")
        ChatMessage.w-100(v-for="(m,index) in messages" :message="messages[index]" :color="colors[m.registration]" :shouldShow='didHoverChat' :key='index' :audience='audience')    
    .row.chat-input.m-0
      .col.p-0
        input.text-white.font-weight-bold.px-1(ref='chatInput' v-model.trim="newMessageText" @keyup.enter="send()" :class="[didHoverChat ? 'visible' : 'visible', platformType == 'mobile' ? 'w-75' : 'w-100']" type='text', :placeholder="$t('chat.placeholder')", autofocus='')
        b-button.chat-button.w-25.font-weight-bold(v-if="platformType == 'mobile'" @click="send()" variant='light' :disabled="newMessageText.length == 0") {{ $t('chat.send') }}

    
</template>

<script>
import randomColor from "randomcolor";
import { mapState } from "vuex";
import Filter from "bad-words"
import _ from "underscore"
import ChatMessage from "../components/ChatMessage"
import Parse from "../services/parse";
import Amplitude from "../services/amplitude";
let Registration = Parse.Object.extend("Registration");

const AUDIENCE_ALL = 'all'
const AUDIENCE_VIP = 'vip'
const TOKEN_VIP = '[VIP]'

export default {
  name: "Chat",
  data() {
    return {
      didLoad: false,
      registrations: [],
      messages: [],
      colors: {},
      newMessageText: '',
      audience: AUDIENCE_ALL,
      didHoverChat: false,
      hasUnreadChannel: false,
      windowWidth: window.innerWidth,
      filter: undefined
    }
  },
  components: {
    ChatMessage
  },
  computed: {
    ...mapState({
      chatService: state => state.chat.chatDataSource,
      isChatVisible: state => state.chat.visible,
      platformType: state => state.client.platformType,
      isBackstage: state => state.client.isBackstage,
      registrationID: state => state.fanfest.registration.objectId,      
      canHost: state => state.fanfest.canHost,
      isVIP: state => state.fanfest.isVIP,
      hasSidebar: state => state.sidebar.visible,      
    }),
    audienceButton: function() {
      if (!this.hasUnreadChannel)
        return this.audience === AUDIENCE_ALL ? 'Public Chat' : 'Producer Chat'
      else 
        return this.audience === AUDIENCE_ALL ? 'New Producer Messages' : 'New Fan Messages'
    }
  },
  watch: {
    chatService: function() {
      this.config();
    }
  },
  sockets: {
    banFFUser: function(registration) {
      /* for (const m of this.messages) {
        console.log(m)
        if (m.registration === registration) m.text = 'Removed by moderator'
      } */
      this.messages = this.messages.filter(m => m.registration != registration)
    },
  },
  mounted: function () {
    this.filter = new Filter()

    // if (this.platformType != 'mobile')
    //   this.$store.dispatch('updateSidebar', 'chat') // hide chat by default on mobile, show elsewhere
  },
  methods: {
    config: function() {
      if (!this.chatService) return;
      const self = this;
      this.chatService.start(100);
      this.chatService.getObservableChatEnabled().subscribe(
        function(enabled) {
          if (enabled) console.log("Chat is enabled");
          else console.log("Chat is DISABLED");
        },
        { initial: "notify" }
      );

      

      this.chatService.getObservableChatMessages().subscribe(function(messages) {
          if (!self.didLoad) {
            
            for (var i = 0; i < messages.length - 1; i++) {
              // console.log(JSON.stringify(message))
              var message = messages[i];
              self.processReceivedMessage(message, false);
            }
            self.didLoad = true;
            
            if (self.$route.query.kiosk) return; // keep silent if kiosk

            // self.newMessageText = self.$t('chat.welcomeMessage')
            // self.send()              
          }

          console.log(
            "Current chat history is [" + messages.length + "] messages"
          );
        });

      this.chatService.getObservableLastChatMessage().subscribe((message) => {
        this.processReceivedMessage(message, true);
      });
    },
    processReceivedMessage: async function(message, isNew) {
      let registrationID = message.from.screenName
      let query = new Parse.Query(Registration)
      query.equalTo('objectId', registrationID)
      query.include('fan')

      let registration = await query.first()

      if (registration.get('isBanned')) return // prevent messages from banned users

      let text
      try {
        text = this.filter.clean(message.message)
      }
      catch {
        text = message.message
      }

      let audience = text.indexOf(TOKEN_VIP) >= 0 ? AUDIENCE_VIP : AUDIENCE_ALL
      if (audience === AUDIENCE_VIP) text = text.slice(TOKEN_VIP.length)

      if (audience === AUDIENCE_VIP && !this.isChatVisible && !this.$route.query.kiosk){
        this.audience = AUDIENCE_VIP
        this.$store.dispatch('updateSidebar', 'chat')
      }
      
      if (audience != this.audience) 
        this.hasUnreadChannel = true

      let messageForChat = {
        author: `${registration.get('fan').get('name')}`,
        text: text,
        registration: registration.id,
        audience: audience,
        timestamp: message.timestamp
      }

      // Show messages to hosts / admins first before everyone else
      let delay = (this.canHost || message.from.screenName == this.registrationID || audience === AUDIENCE_VIP || this.isBackstage) ? 0 : 7000

      setTimeout(async () => {
        await registration.fetch()
        if (registration.get('isBanned')) return // prevent messages from banned users
        
        this.messages.push(messageForChat)
        // this.$emit('showChatNotification', messageForChat)  

        this.messages = _.sortBy(this.messages, 'timestamp')
        this.updateColors()

        if (!isNew) return
        if (!this.isChatVisible && message.from.screenName != this.registrationID && audience != AUDIENCE_VIP)
          this.$store.commit('addMissedMessage', messageForChat)

      }, delay);      
    },
    updateColors: function() {
      for (var m of this.messages) {
        this.$store.commit('updateAudienceList', m.registration)
        if (!this.colors[m.registration])
          this.colors[m.registration] = randomColor()
      }
    },
    send: function() {
      if (this.newMessageText === '') return;

      if (this.audience === AUDIENCE_VIP) 
        this.newMessageText = `${TOKEN_VIP}${this.newMessageText}`

      this.chatService.sendMessageToRoom(this.newMessageText, (error, result) => {
        if (error) {
          alert("Failed to send message", error);
          return;
        }

        console.log("Sent message to room with status "+result.status) });
        this.newMessageText = '';
        
        this.$gtag.event('chat', {
          'event_category': 'engagement',
        })
        Amplitude.getInstance().logEvent('Sent Chat Message');
        
        if(this.$refs.chatInput && this.platformType === 'mobile') this.$refs.chatInput.blur()
    },
    changedAudience: function() {
      if (this.audience === AUDIENCE_ALL)
        this.audience = AUDIENCE_VIP
      else 
        this.audience = AUDIENCE_ALL

      this.hasUnreadChannel = false
    }
  }
};
</script>

<style scoped>
  @media only screen and (min-width: 768px) {
    .chat {
      position: absolute;
      /* left: 1.5%; */
      /* bottom: 20% !important; */
      width: 100% !important;
      height: 100% !important;
      /* z-index: 10; */
      font-size: 1vw !important;
      /* padding: 5%; */
      /*height: 15%;*/
    }
  }

  @media only screen and (min-width: 575px) {
    .chat{
      position: absolute;
      /* left: 1.5%; */
      /* bottom: 20%; */
      width: 100% !important;
      height: 100% !important;
      /* z-index: 10; */
      font-size: 1vw !important;
      /* padding: 5%; */
      /*height: 15%;*/
    }
  }

  .chat {
    /* position: fixed; */
    /* bottom: 20%; */
    width: 100%;
    margin: 0px;
    overflow-x: hidden;
    /* max-height: 15vh; */
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
    /* background-color: rgba(0, 0, 0, 0.4); */
    font-size: 3vw;
    /* overflow-y: hidden; */
    /*position: absolute;*/
    /*right: 3%;*/
    /*bottom: 15%;*/
    /*width: 20%;*/
    /*height: 15%;*/
    /* background: linear-gradient(180deg, rgba(50, 50, 50, 0) 0%, rgba(51,51,51,0.05) 20%, rgba(41, 41, 41, 0.9) 100%); */
    /* -ms-overflow-style: none; 
    scrollbar-width: none; */
  }
  /* .chat::-webkit-scrollbar{
    display: none;
  } */
/*   .chat:hover{
    background:  rgba(26, 26, 26, 0.8);
  } */
  input, textarea {
    background-color: rgba(49,49,49,1.0);
    border: none;
    position: sticky;
    top: 90%;
    bottom: 10%;
    height: 40px;
  }
  input:focus, textarea:focus {
    outline: none;
  }
  input::placeholder {
    color: lightgrey;
  }
  .chat-button {
    position: sticky;
    top: 90%;
    bottom: 10%;
    height: 40px;
    padding: 0px;
    border-radius: 0%;
  }
  .chat-audience {
    height: 5%;
  }
  .chat-messages {
    height: 85%;
    overflow: hidden;
    overflow-y: scroll;
  }
  .chat-input {
    height: 5%;
    width: 100%;
    border: white;
    border-width: 5px;
  }
</style>>