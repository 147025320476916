<template lang="pug">
    b-container(fluid).h-100.p-0
        b-row.p-0.m-0.d-sm-none(v-show="platformType == 'mobile'")
          b-col(cols="12")
            Footer(:isFanFestActive="isFanFestActive" :fanfestStreamingEnabled="fanfestStreamingEnabled" :screenName="fanfestScreenname" :fanfestViews="fanfestViews" @toggleVideo="toggleVideo" :showControls="false")

        b-row.p-0.m-0.bg-image(:style="deviceStyleObject" align-v="center")
          // VOD
            //- b-col.p-0(cols="1" :class="[isFanFestActive ? 'd-none' : '']")         
            .col.m-0.p-0(:class="[isFanFestActive ? 'd-none' : '', platformType != 'mobile' ? 'align-self-center' : 'mt-1']")
                .embed-responsive.embed-responsive-16by9
                  video#vici-video-element.embed-responsive-item(ref='video' @playing='isVODReady = true' @timeupdate='checkForBumper()' @ended='onEnd()' :muted="shouldMute" autoplay :class="[isFanFestActive ? 'd-none' : '']" playsinline preload=auto)
                                
            .col-1.p-0(:class="[isFanFestActive ? 'd-none' : '']")          
          
          // Content + Streamers
          b-col(:cols="(hasSidebar && platformType != 'mobile') ? 9 : 12" :align-self="windowWidth > 575 ? 'center' : 'start'").p-0.pt-3.pt-sm-0
            b-row(align-v="center").h-100.mx-2
              
              // VOD
              b-col.pl-3.align-self-center(:cols="(platformType == 'mobile' || totalStreamers == 0) ? 12 : 6" :sm="totalStreamers == 0 ? 12 : 6" :class="[showVideos && !this.$store.state.fanfest.showVOD && this.isVODReady && !toggleForMobile ? '' : 'd-none']")
                .embed-responsive.embed-responsive-16by9.d-flex(:class="(platformType != 'mobile' && totalStreamers == 0) ? 'video-full' : ''")
                  video#vici-video-element.embed-responsive-item(ref='video' @timeupdate='checkForBumper()' @ended='onEnd()' :class="[isVODReady == true ? '' : 'd-none']" muted autoplay controls playsinline preload=auto)                  
                  //- SportBuff(v-show="showVideos && !this.$store.state.fanfest.showVOD && this.isVODReady && !toggleForMobile")


              // Live (FFVOD)
              b-col.align-self-center(:cols="(platformType == 'mobile' || totalStreamers == 0) ? 12 : 6" :sm="totalStreamers == 0 ? 12 : 6" :class="[showVideos && this.$store.state.fanfest.showVOD ? '' : 'd-none']")
                FFVOD(@toggleVideo="toggleVideo")
                //- SportBuff()

              // VIPs
              //- b-col(:cols="platformType == 'mobile' ? 12 : 6" sm="6").align-self-center(:class="[!showVideos ? '' : 'd-none']")
                b-row(align-h="center" align-v="center")
                  template(v-for="data, index in fanfestStreamsVIP")
                    b-col.pt-md-1.pt-sm-2.px-1(cols="auto" :key="data.sessionId")
                      .container-float
                        video(:id='data.sessionId' class="fanfest-video" :class='fanfestStreamVIPClassObject' :createdAt='new Date().getTime()' playsinline autoplay)
                        //- .streamer-name.bg-dark.rounded.text-white.font-weight-bold.p-1 {{ fanfestScreenname }}
                        //- b-button.streamer-mute.border-0.p-1(v-if="data.sessionId != 'my-host-video'" @click="toggleStreamerMute(data.sessionId)" variant="outline-light")
                          i.fa.fa-1x(:id="`button-${data.sessionId}`" :class="isFanFestActive ? 'fa-volume-up' : 'fa-volume-mute' ")
                
              // Streamers
              b-col.p-0(:cols="(platformType == 'mobile' || !showVideos) ? 12 : 6" :sm="showVideos ? 6 : 12" align-self="center" :class='fanfestClassObject' ref='fanfeststreams')
                
                // vip
                b-row(align-h="center" align-v="center")
                  template(v-for="data, index in fanfestStreamsVIP")
                    b-col.p-0(v-show="data.isAudioOnly == false" cols="auto" :key="data.sessionId")
                      .container-float.px-1
                        video.streamer-host(:id='data.sessionId' @click="moderateStreamer(data.registration)" class="fanfest-video" :style='streamVIPClassObject' :createdAt='new Date().getTime()' playsinline autoplay)
                        b-button.streamer-mute.border-0.p-1(v-if="data.sessionId != 'my-host-video'" @click="toggleStreamerMute(data.sessionId)" variant="outline-light")
                          i.fa.fa-1x(:id="`button-${data.sessionId}`" :class="isFanFestActive ? 'fa-volume-up' : 'fa-volume-up' ")
                        .streamer-name.text-uppercase.rounded.animated.delay-3s.fadeIn(v-if="data.screenName != 'me'") {{ data.screenName }}
                
                // audience
                //- b-row(align-h="center" align-v="center" v-show="(this.platformType === 'mobile' && this.windowWidth < 575 && this.fanfestStreamsVIP.length > 0 && this.showVideos) == false")                
                b-row(align-h="center" align-v="center")
                  template(v-for="data, index in fanfestStreams")
                    b-col.px-1(v-show="data.isAudioOnly == false" cols="auto" :key="data.sessionId")
                      video(:id='data.sessionId' @click="moderateStreamer(data.registration)" class="fanfest-video" :style="streamClassObject" :createdAt='new Date().getTime()' playsinline autoplay)
                      b-button.streamer-mute.border-0.p-1(v-if="data.sessionId != 'my-host-video'" @click="toggleStreamerMute(data.sessionId)" variant="outline-light")
                        i.fa.fa-1x(:id="`button-${data.sessionId}`" :class="isFanFestActive ? 'fa-volume-up' : 'fa-volume-up' ")
                      .streamer-name.text-uppercase.rounded.animated.delay-3s.fadeIn(v-if="data.screenName != 'me' && platformType != 'mobile'") {{ data.screenName }}
                      

                          //- b-tooltip(v-if="platformType != 'mobile' && data.sessionId != 'my-host-video'" hover variant='light' :target='data.sessionId') {{ $t('tooltip.streamers' )}}
                      
                  // Debug mode
                  //- template(v-for="data, index in debugModeFanFestStreamers")
                    b-col.pt-md-1.pt-sm-2.px-1(cols="auto" :key="data.sessionId")
                      video.bg-dark(id='my-host-video' ref="streams" class="fanfest-video" :style="streamClassObject" :createdAt='new Date().getTime()' playsinline autoplay)
                        //- b-tooltip(v-if="platformType != 'mobile' && data.sessionId != 'my-host-video'" hover variant='light' :target='data.sessionId') {{ $t('tooltip.streamers' )}}

                  //b-col.pt-4(cols="auto" v-if="platformType != 'desktop'" @click='share()')
                    b-toast(:title="$t('tooltip.invite')" visible static no-auto-hide v-if="fanfestStreams.length == 1")
                      | You are the first one here! Tap here or click blue button to invite fans.
                    b-toast(title="Rotate to see everyone!" visible static no-auto-hide v-if="fanfestStreams.length > 1")
                      | Rotate your phone to chat with everyone else!
          
          // Sidebar          
          b-col.sidebar.h-100.p-0(v-if="platformType != 'mobile'" cols="3" align-self="center" :class="hasSidebar ? '' : 'd-none'")
            Sidebar
          .mobile-sidebar(v-if="platformType == 'mobile'" :class="hasSidebar ? '' : 'd-none'")
            Sidebar

          // Notifications
          //- b-alert.position-fixed.fixed-top.m-0.rounded-0.text-center.font-weight-bold.text-uppercase(v-if='showNotification' :show='5' variant='dark' @dismissed="showNotification=false" fade dismissible style='opacity:0.8; z-index:2000;') {{ notificationText }}
          ChatNotification

          // Polls
          //- Poll.poll

          // Counter
          //- Counter.counter(v-show="platformType != 'mobile'")

          // Clock
          Clock(:totalStreamers='totalStreamers')

          // Mobile Chat
          //- template(v-if="platformType == 'mobile'")
            b-col(cols="12" align-self="end" v-show="windowWidth < 575")
              Chat(@showChatNotification="showChatNotification" @startedTyping="showStreamers = false" @endedTyping="showStreamers = true")
          // Desktop Chat
          //- Chat(v-if="platformType != 'mobile'")

          // Moderation
          ModeratorBan(v-if='canHost && streamerToModerate && !isBackstage' :registrationID="streamerToModerate" @hide="streamerToModerate = undefined")

        // Footer
        b-row(:class="platformType == 'mobile' ? 'fixed-footer' : ''").p-0.m-0
          b-col(cols="12")
            Footer(:isFanFestActive="isFanFestActive" :fanfestStreamingEnabled="fanfestStreamingEnabled" :screenName="fanfestScreenname" :fanfestViews="fanfestViews" @toggleVideo="toggleVideo" :showOffers="platformType != 'mobile'")        
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";

import Sidebar from "../components/Sidebar";
import FFVOD from "../components/FanFestVOD";
import Footer from "../components/Footer";
import Chat from "../components/Chat";
import ModeratorBan from "./ModeratorBan"
import Counter from "../components/Counter";
import Poll from "../components/Poll";
import Clock from "../components/Clock";
import ChatNotification from "../components/ChatNotification";
import SportBuff from "../components/SportBuff";

// const duration = 20 * (1000 * 60);

export default {
  name: "Video",
  components: {
    Sidebar,
    FFVOD,
    Footer,
    Chat,
    ModeratorBan,
    Counter,
    Poll,
    Clock,
    ChatNotification,
    SportBuff
  },
  data() {
    return {
      hls: undefined,
      hlsConfig: {
        // debug: false,
        nudgeOffset: 1.0
        //capLevelToPlayerSize: true,
        //capLevelOnFPSDrop: true,
      },
      url: "",
      videoList: [],
      currentDuration: -1,
      isVODReady: true,
      hasBumper: false,
      shouldReload: false,
      hasVODMenu: false,
      didCheckFanFestActive: false,
      isFanFestActive: false,
      fanfestViews: [],
      didFullscreen: false,
      showVideos: true,
      showStreamers: true,
      toggleForMobile: false,
      showNotification: false,
      notificationText:'',
      streamerToModerate: undefined,

      debugMode: false,
      debugModeFanFest: false,
      debugModeFanFestStreamers: 1,
      windowWidth: window.innerWidth,
      // streamsSound: [],
      // tracks: [],
      // rtc: new RTCPeerConnection()
    };
  },
  computed: {
    ...mapState({
      isEmbed: state => state.client.isEmbed,
      isBackstage: state => state.client.isBackstage,
      groupID: state => state.client.group.objectId,
      groupName: state => state.client.group.name,
      platformType: state => state.client.platformType,
      shouldMute: state => state.client.shouldMute,
      shouldMuteVideo: state => state.client.shouldMuteVideo,
      shouldMuteStreamers: state => state.client.shouldMuteStreamers,
      forceMic: state => state.client.forceMic,
      sessionID: state => state.client.sessionID,      
    }),
    ...mapState({
      fanfestStreams: state => state.fanfest.streams,
      fanfestStreamsVIP: state => state.fanfest.streamsVIP,
      fanfestStreamsAudio: state => state.fanfest.streamsAudio,
      fanfestMemberCount: state => state.fanfest.numMembers,
      fanfestScreenname: state => state.fanfest.screenName,
      fanfestStreamingEnabled: state => state.fanfest.canStream,
      canHost: state => state.fanfest.canHost,
      hasHost: state => state.fanfest.hasHost,
      showVOD: state => state.fanfest.showVOD,
      imageBackground: state => state.fanfest.config.mainBG,
      shareLink: state => state.fanfest.shareLink
    }),
    ...mapState({
      hasSidebar: state => state.sidebar.visible,
      sidebarContent: state => state.sidebar.content,
    }),
    deviceStyleObject: function() {
      console.log('windowWidth', this.windowWidth);

      return {
        backgroundImage: `url('${this.imageBackground}')`,
        height: this.windowWidth < 575 ? "82vh" : "85vh"
      };
    },
    fanfestClassObject: function() {
      return {
        /* "col-8":
          !this.$store.state.fanfest.showVOD && this.fanfestStreams.length > 2,
        "col-10":
          !this.$store.state.fanfest.showVOD && this.fanfestStreams.length <= 2, */
        "col-6":
          !this.$store.state.fanfest.showVOD && this.showVideos,
         "col-12":
          !this.showVideos
      };
    },
    streamClassObject: function() {
      /* // let numStreamers = this.debugModeFanFestStreamers
      let numStreamers = this.fanfestStreams.length
      var width = 0

      let thresholds = [1, 2, 4, 9, 16, 25, 50, 1000]
      let widthsDesktop = [49.5, 35, 22, 16, 12, 9.5, 9.5, 7]
      let widthsMobile = [90, 60, 44, 25, 18, 18, 18, 10]
      let widthsFull = [85, 44, 40, 18, 18, 18, 18, 10]
      var widthForDevice =[]

      if (this.platformType === 'mobile' && this.windowWidth < 575)
        widthForDevice = widthsMobile
      else
        widthForDevice = (!this.showVideos && this.fanfestStreamsVIP.length == 0) ? widthsFull : widthsDesktop

      for (var i = 0; i < thresholds.length; i++) {
        if (numStreamers > thresholds[i]) continue        
        width = widthForDevice[i]
        break;
      }

      if (width === 0 )
        width = widthForDevice[widthForDevice.length-1]

      if (this.fanfestStreamsVIP.length > 0)
        width = width * 0.60

      if (this.hasSidebar && this.platformType != 'mobile')
        width = width * 0.75

      return {
        'width': `${width}vw`,
        'max-height': `${width * 0.5625}vw`
      } */
      return this.formatStreamer(false)
    },
    streamVIPClassObject: function() {
      /* let numStreamers = this.fanfestStreamsVIP.length
      var width = 0

      let thresholds = [1, 2, 4, 9, 16, 25, 50, 1000]
      let widthsDesktop = [49.5, 24, 24, 16, 12, 9.5, 9.5, 7]
      let widthsMobile = [90, 44, 44, 25, 18, 18, 18, 10]
      let widthsFull = [85, 44, 44, 18, 18, 18, 18, 10]
      var widthForDevice =[]

      if (this.platformType === 'mobile' && this.windowWidth < 575)
        widthForDevice = widthsMobile
      else
        widthForDevice = (!this.showVideos && this.fanfestStreams.length == 0) ? widthsFull : widthsDesktop

      for (var i = 0; i < thresholds.length; i++) {
        if (numStreamers > thresholds[i]) continue        
        width = widthForDevice[i]
        break;
      }

      if (width === 0 )
        width = widthForDevice[widthForDevice.length-1]

      // if (this.fanfestStreamsVIP.length > 0)
      //   width = width * 0.60

      if (this.hasSidebar && this.platformType != 'mobile')
        width = width * 0.75

      return {
        'width': `${width}vw`,
        'max-height': `${width * 0.5625}vw`
      } */

      return this.formatStreamer(true)
    },
    totalStreamers: function() {
      return this.fanfestStreams.length + this.fanfestStreamsVIP.length - this.fanfestStreamsAudio.length
    },
    /* fanfestStreamClassObject: function() {
      let numStreamers = this.fanfestStreams.length
      // let numStreamers = this.debugModeFanFestStreamers

      let layoutDefault = {
        "fanfest-video-1": numStreamers == 1,
        "fanfest-video-2": numStreamers == 2,
        "fanfest-video-4":
          numStreamers >= 3 && numStreamers <= 4,
        "fanfest-video-9": numStreamers > 4 && numStreamers <= 9,
        "fanfest-video-16": numStreamers > 9 && numStreamers <= 16,
        "fanfest-video-25": numStreamers > 16
      };

      let layoutMobile = {
        "fanfest-video-1-mobile": numStreamers == 1,
        "fanfest-video-2-mobile": numStreamers == 2,
        "fanfest-video-4-mobile": numStreamers >= 3 && numStreamers <= 4,
        "fanfest-video-9-mobile": numStreamers > 4 && numStreamers <= 9,
        "fanfest-video-16-mobile": numStreamers > 9
        // "fanfest-video-25": numStreamers > 16
      };

      let layoutFull = {
        "fanfest-video-1-full": numStreamers === 1,
        "fanfest-video-4-full":
          numStreamers >= 2 && numStreamers <= 4,
        "fanfest-video-9-full": numStreamers > 4 && numStreamers <= 9,
        "fanfest-video-16-full": numStreamers > 9 && numStreamers <= 16,
        "fanfest-video-25-full": numStreamers > 16
      };

      if (this.platformType === 'mobile' && this.windowWidth < 575)
        return layoutMobile
      else
        return (!this.showVideos && this.fanfestStreamsVIP.length == 0) ? layoutFull : layoutDefault
    },
    fanfestStreamVIPClassObject: function() {
      let numStreamers = this.fanfestStreamsVIP.length

      let layoutDefault = {
        "fanfest-video-1": numStreamers == 1,
        "fanfest-video-2": numStreamers == 2,
        "fanfest-video-4":
          numStreamers >= 3 && numStreamers <= 4,
        "fanfest-video-9": numStreamers > 4 && numStreamers <= 9,
        "fanfest-video-16": numStreamers > 9 && numStreamers <= 16,
        "fanfest-video-25": numStreamers > 16
      };

      let layoutMobile = {
        "fanfest-video-1-mobile": numStreamers == 1,
        "fanfest-video-2-mobile": numStreamers == 2,
        "fanfest-video-4-mobile": numStreamers >= 3 && numStreamers <= 4,
        "fanfest-video-9-mobile": numStreamers > 4 && numStreamers <= 9,
        "fanfest-video-16-mobile": numStreamers > 9
        // "fanfest-video-25": numStreamers > 16
      };

      if (this.platformType === 'mobile' && this.windowWidth < 575)
        return layoutMobile
      else
        return layoutDefault
    }, */
    // @Adam: Can be cleaned ?
    // isStreaming: function() {
    //   for (var id in this.fanfestStreams) {
    //     console.log('stream object', this.fanfestStreams[id]);

    //     if (id === 'my-host-video')
    //       return true;
    //   }

    //   return false
    // }
  },
  props: {
    hasDevice: Boolean
  },
  watch: {
    fanfestMemberCount: function() {
      if (this.fanfestMemberCount > 0 && !this.isVODReady) {        
        this.isVODReady = true
        this.playVideo()
      }

      this.checkFanFestMembers();

      /* if (this.platformType == 'mobile')
        this.handleMobileVideo(); */
    },
    /* shouldMuteVideo: function() {
      if (this.platformType == 'mobile' && !this.shouldMuteVideo)
        this.handleMobileVideo()
    }, */
    showVOD: function() {
      if (this.showVOD) {
        if (!this.$refs.video.paused) this.$refs.video.pause();
        this.showVideos = true;
      }
      /* else if (this.fanfestStreamsVIP.length > 0) {
        this.showVideos = false
        if (!this.$refs.video.paused) this.$refs.video.pause();
      } */
      else {
        this.showVideos = true;
        this.$refs.video.play();
      }
    },
    showVideos: function() {
      if (this.showVideos && this.forceMic)
        this.showVideos = false
    },
    'fanfestStreams.length': function(){
      // This does not work at the moment.
      this.$nextTick(() => {
        // this.listenStreams();
      });
    }
  },
  methods: {
    listenStreams: async function () {
       // console.log('loudVideos', this.$refs.streams);

       // let stream = this.$refs.streams[0];
       let stream = document.getElementById('my-host-video')

       if(stream.srcObject) {
        try {
           // console.log('loudVideos', stream.srcObject);

            // let rtc = new RTCPeerConnection();
            // rtc.addTrack(stream.srcObject.getAudioTracks()[0]);
            // rtc.addTrack(stream.srcObject.get)

            for (let track of stream.srcObject.getTracks()) {
              console.log('loudVideos', track);

              if(track.kind == 'audio') {
                track.enabled = false;
              }

              if(!this.tracks.includes(track.id)) {
                console.log('loudVideos track', track);

                this.tracks.push(track.id);
                this.rtc.addTrack(track);

                track.onunmute = () => {
                  console.log('loudVideos from', track);
                }
              }
            }

           let stats = await this.rtc.getStats();

           // console.log('loudVideos', stream.srcObject.getAudioTracks());

           stats.forEach(report => {
            console.log('loudVideos', report.audioLevel || report.googAudioInputLevel, report.audioInputLevel, report.audioOutputLevel || report.googAudioOutputLevel);
           });

          //  setTimeout(this.listenStreams, 100);          
         } catch (e) {
          console.log('loudVideos error', e);
         }
      } else setTimeout(this.listenStreams, 1000);
    },
    isStreamActive: function (data) {
      // console.log('loudVideos', data, this.streamsSound);
      return this.streamsSound.findIndex(streamSound => {
        return streamSound.id == data.sessionId;
      }) <= (this.platformType == 'mobile' ? 2 : 2);
    },
    configVideo: async function() {
      const self = this;

      if (!self.$store.state.client.organizer) {
        console.log('reloadPage ?');
        self.$router.push({ name: "fanfest-home" });
      }

      let organizerID = self.$store.state.client.organizer.objectId;

      if (self.videoList.length === 0) {
        let response = await axios.get(`${process.env.VUE_APP_CONFIG_ROOT}/${organizerID}/vod/all`)
        self.videoList = response.data
        self.videoList = self.videoList.filter((v) => v.isPrivate != true)
      }
      
      let nextVideo = self.videoList.shift();
      self.url = nextVideo.url;
      self.currentDuration = nextVideo.duration;
      self.playVideo();

      console.log(`duration: ${self.currentDuration}`);
      if (self.currentDuration > 0) self.hasBumper = true;
      // self.playVideo();
    },
    playVideo: function() {
      if (!this.isVODReady) return

      const self = this;
      if (self.debugMode) {
        self.$refs.video.src = "demo/IMG_0606.mp4";
        self.$refs.video.loop = true;
        return;
      }

      // if (self.hasDevice || self.$route.query.m == 1) self.$store.commit('updateMute', false);

      // if (!self.didCheckFanFestActive) return;

      if (self.$refs.video.src) {
        self.hls.destroy();
        self.hls = new window.Hls();
      }

      console.log("Playing " + self.url);
      if (window.Hls.isSupported()) {
        self.hls.loadSource(self.url);
        self.hls.attachMedia(self.$refs.video);
        /* self.hls.on(window.Hls.Events.MEDIA_ATTACHED, function() {
          self.hls.loadSource(self.url);
          console.log('here')
        }) */
        

        self.hls.on(window.Hls.Events.ERROR, function (event, data) {
          // console.log(`[vici]: hls error: ${event} - ${JSON.stringify(data)}`)
          if (data.fatal) {
            switch(data.type) {
            case window.Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
              console.log("fatal network error encountered, try to recover");
              self.hls.startLoad();
              break;
            case window.Hls.ErrorTypes.MEDIA_ERROR:
              console.log("fatal media error encountered, try to recover");
              self.hls.recoverMediaError();
              break;
            default:
            // cannot recover
              self.hls.destroy();
              break;
            }
          }
        });

        
        /* if (self.platformType === 'mobile')
          self.handleMobileVideo()                       */

      } else {
        self.$refs.video.src = self.url;
        self.$refs.video.addEventListener("loadedmetadata", function() {
          self.$refs.video.play();          
        });
      }

      // self.$refs.video.controls = true;
      // if (self.$refs.video.muted) self.$refs.video.muted = self.shouldMute;

      if (self.forceMic == 1 || self.$route.query.staging) // hide VOD 
        self.showVideos = false

      if (self.debugModeFanFest) {
        console.log('activate fanfest');
        setTimeout(() => (self.isFanFestActive = true), 2000);
      }
    },
    /* didChangeVolume: function() {
      const vid = this.$refs.video;
      if (!vid.src || this.isFanFestActive) return;
      // this.$store.commit('updateMute', vid.muted);
    }, */
    /* goBig: function() {
      if(this.didFullscreen)
        return

      const divTV = document.getElementById("app");

      if (!document.fullscreenElement) {
        try {
          // Chrome
          divTV.requestFullscreen();
        } catch (err) {
          // oops, Safari
          divTV.webkitRequestFullscreen();
        }
      }

      this.didFullscreen = true;
    }, */
    /* toggleVODMenu: function() {
      if (!this.didFullscreen || !document.fullscreenElement) return;
      this.hasVODMenu = !this.hasVODMenu;
    }, */
    toggleVideo: function(hidden) {
      // if (this.showVOD) return

      if (hidden != undefined)
        this.showVideos = !hidden
      else  
        this.showVideos = !this.showVideos
      if (this.showVideos && !this.showVOD) this.$refs.video.play()
      else this.$refs.video.pause()
    },
    toggleStreamerMute: function(id) {
      if (id === 'my-host-video') return
      
      let video = document.getElementById(id)
      console.log(video)
      video.muted = !video.muted
      console.log('[vici]: muted = ' + video.muted)
      
      let icon = document.getElementById(`button-${id}`)
      icon.classList.remove('fa-volume-mute', 'fa-volume-up')
      let newIcon = video.muted ? 'fa-volume-mute' : 'fa-volume-up'
      icon.classList.add(newIcon)
    },
    /* playVideoSound: function() {
      if (this.showVOD) return
      this.$store.commit('updateMute', true);
      this.$refs.vodsoundbutton.classList.add('d-none')
    }, */
    processMenuSelection: function(selection) {
      switch (String(selection)) {
        case "fanfest-share":
          this.$refs.menuVOD.classList.add("d-none");
          this.$refs.formRequestFanFest.classList.remove("d-none");
          break;
        case "open-dashboard":
          window.open("https://watch.vici.cloud");
          this.hasVODMenu = false;
          break;
        default:
          console.error("Didn't recognize menu selection");
      }
    },
    processMenuAction: function(action) {
      switch (String(action)) {
        case "request-fanfest-access":
          axios
            .post(`${process.env.VUE_APP_BACKEND}/app/live/request`, {
              name: this.$refs.formRequestFanFestName.value.trim(),
              email: this.$refs.formRequestFanFestEmail.value.trim(),
              code: this.$refs.formRequestFanFestCode.value.trim()
            })
            .then(response => {
              console.log(response);
            })
            .catch(err => Promise.reject(err.message));
          break;

        default:
          console.error("Didn't recognize menu selection");
      }

      this.hasVODMenu = false;
    },
    checkForBumper: function() {
      if (!this.$refs.video) return;

      /* if (this.showVOD) {
        if (!this.$refs.video.paused) this.$refs.video.pause();
        return;
      }  */

      var time = this.$refs.video.currentTime;

      //if (time < 10) return;
      // console.log(`current time: ${this.$refs.video.currentTime}`);

      let timeLeft = this.currentDuration - time;
      if (
        this.hasBumper &&
        this.currentDuration > 0 &&
        time > 5 &&
        timeLeft < 20
      ) {
        this.currentDuration = -1; // Prevents repeat VOD configs when this event fires
        this.configVideo();
      }
    },
    onEnd: function() {
      console.log("video ending...");
      this.currentDuration = -1;
      if (this.shouldReload && !this.isFanFestActive) window.location.reload();
      else this.configVideo();
    },
    resetVideo: function() {
      if (!this.$refs.video) return;
      console.log("Killing VOD references for GC...");
      //if (this.$refs.video)
      this.$refs.video.src = "";
      this.$refs.video.load();
      //if (this.hls)
      this.hls.destroy();
      window.removeEventListener("beforeunload", this.resetVideo);
    },
    handleMobileVideo: async function() {
      /* if (this.showVOD) return // don't run if ffvod (RT video) is live
      
      console.log('handling mobile video...')

      if (!this.shouldMuteVideo) { // watch mode
        if (this.$refs.video.muted) return
        this.$refs.video.muted = true;  
        setTimeout(() => {
          this.$refs.video.muted = false;  
        }, 3000);
      }
      else { // talk mode
        this.$refs.video.muted = true;
        // await this.$refs.video.pause();
          // setTimeout(() => {        
        //   // this.$refs.video.muted = false;
        //   this.$refs.video.play();
        // }, 3000);  
      }       */
    },
    /* showChatNotification: function(message) {
      if (this.platformType === 'mobile' && this.sessionID && message.from.sessionId != this.sessionID) {
        // this.notificationText = `${message.author}: ${message.text}`
        // this.showNotification = true

        this.$bvToast.toast(message.text, {
          title: message.author,
          autoHideDelay: 2000,
          appendToast: false,
          toaster: 'b-toaster-top-full',
          noCloseButton: true,
          variant: 'secondary'
        })

        console.log('here')
      }
      console.log('here2')
    }, */
    startFanFest: function() {
      console.log("Starting Fanfest...");

      if (this.isFanFestActive === false) {
        this.isFanFestActive = true;
      }

      // this.$refs.video.muted = true;
      // this.$refs.video.pause();
      // this.shouldReload = true;
    },
    checkFanFestMembers: function() {
      this.didCheckFanFestActive = true;

      console.log("Checking FanFest # of members...");
      if (!this.isFanFestActive && this.fanfestMemberCount > 0)
        this.startFanFest();
      /* else if (this.isFanFestActive && this.fanfestMemberCount === 0)
        this.stopFanFest(); */
      else if (!this.$refs.video.src) this.playVideo();
    },
    stopFanFest: function() {
      const self = this;
      setTimeout(() => {
        console.log("Ending Fanfest...");
        self.isFanFestActive = false;
        // self.$refs.video.muted = self.shouldMute;
        if (self.$refs.video.src) self.$refs.video.play();
        else self.playVideo();  
      }, 1000);
      

      /* old code
       if (this.fanfestStreamingEnabled) {
        //location.reload()
        this.isFanFestActive = false;
      }
      else { 
        this.isFanFestActive = false;
        this.$refs.video.muted = this.shouldMute;
        if (this.$refs.video.src)
          this.$refs.video.play();
        else 
          this.playVideo();
      } */
    },  
    moderateStreamer: function(registrationID) {
      if (this.platformType != 'desktop') return
      this.streamerToModerate = registrationID
    },
    formatStreamer: function(isVIP) {
      let numStreamers = isVIP ? this.fanfestStreamsVIP.length : this.fanfestStreams.length
      let isMobilePortait = (this.platformType === 'mobile' && this.windowWidth < 575)
      var width = 0

      let thresholds = [1, 2, 4, 10, 16, 25, 50, 1000]
      var widthForDevice = [58, 48, 36, 24, 18, 9.5, 9.5, 7]

      for (var i = 0; i < thresholds.length; i++) {
        if (numStreamers > thresholds[i]) continue        
        width = widthForDevice[i]
        break;
      }

      if (width === 0 )
        width = widthForDevice[widthForDevice.length-1]

      // make everyone smaller if content is present 
      if (this.showVideos)
        width = isMobilePortait ? width : width * 0.65
      
      // make everyone smaller if both vips and guests are present
      if ((isVIP && this.fanfestStreams.length > 0) || (!isVIP && this.fanfestStreamsVIP.length > 0)) {
        width = isMobilePortait ? width : width * (width * 0.5625 * 0.75) / (width * 0.5625)
      }

      // make guests smaller than hosts if hosts are present
      if (!isVIP && this.fanfestStreamsVIP.length > 0) {
        // width = (this.fanfestStreams.length > this.fanfestStreamsVIP.length) || (this.fanfestStreams.length === 1) ? width * 0.5 : width * 0.85
        width = width * 0.70
        if (this.fanfestStreams.length === 1) width = width * 0.66
      }

      if (this.hasSidebar && this.platformType != 'mobile')
        width = width * 0.75
        
      let windowRatio = window.innerWidth / window.innerHeight
      console.log(`window ratio: ${windowRatio}`)
      let targetRatio = 16 / 9
      if (windowRatio > targetRatio) {
        let scalingFactor = (windowRatio - targetRatio) / targetRatio
        console.log(`scaling by: ${scalingFactor}`)
        width = width * (1 - scalingFactor)
      }

      return {
        'width': `${width}vw`,
        'max-height': `${width * 0.5625}vw`
      }
    },
  },
  created: function() {
    /*     this.fanfestCode = this.$store.state.fanfest.config.code;
    let fanfestCodeHost = this.$store.state.fanfest.config.codeHost;

    var queryData = this.$route.query;
    if (queryData.u) {
      // this.fanfestScreenname = queryData.u;
      this.$store.commit('updateScreenName', queryData.u);
      console.log(`screenname: ${queryData.u}`)
    }
    
    if (queryData.c == this.fanfestCode) {
      this.fanfestStreamingEnabled = true;
    }
    else if (queryData.c == fanfestCodeHost) {
      this.$store.commit('enableHost');
      this.fanfestStreamingEnabled = true;
    }  */
  },
  mounted: function() {
    this.hls = new window.Hls(this.hlsConfig);

    this.$refs.video.addEventListener('pause', async () => {
      if (this.showVOD) return // don't autoplay when in bg
      if (this.platformType != 'mobile') return

      // let muteState = this.$refs.video.muted
      let muteState = true
      this.$refs.video.muted = true
      await this.$refs.video.play().catch(() => {
        console.log('not a pause, switching content...')
      })
      setTimeout(() => {
        this.$refs.video.muted = muteState
      }, 1000);
    })

    /* this.$refs.video.addEventListener('click', async () => {
      if (this.showVOD) return // don't react when in bg
      this.$refs.video.muted = false
    }) */
    
    /* if (this.platformType != 'mobile') {
      this.$store.commit('muteVideos', true);
      this.$refs.video.autoplay = true;          
    }
    else {
      this.$store.commit('muteVideos', false); // start off with video enabled but paused on mobile
    } */
    this.configVideo();

    window.addEventListener("orientationchange", () => {
      // console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
      // this.handleMobileVideo();
      console.log("orientation change");
      this.$store.dispatch('hideSidebar')
    });

    window.addEventListener("beforeunload", this.resetVideo);
    //setTimeout(() => (this.shouldReload = true), duration);

    this.fanfestViews = [this.$refs.fanfeststreams];

    loadExternalScript("https://kit.fontawesome.com/2c8f0ec015.js");

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    })
  }
};

function loadExternalScript(url) {
  let script = document.createElement("script");
  script.setAttribute("src", url);
  document.head.appendChild(script);
}
</script>

<style>
 /*
 This should be on each video input to have video on 16/9 instead of their containers.
But their parent must have proper with and height to avoid weird behaviors
 */
.viewer-ratio  {
  height: 56.25%;
}

/* @media only screen and (min-width: 576px) {
  .fanfest-video {
    max-height: 8.4375vw;
    width: 15vw;
  }
}

@media only screen and (min-width: 768px) {
  .fanfest-video {
    max-height: 6.1875vw;
    width: 11vw;
  }
}

@media only screen and (min-width: 992px) {
  .fanfest-video {
    max-height: 5.625vw;
    width: 10vw;
  }
}

@media only screen and (min-width: 1200px) {
  .fanfest-video {
    max-height: 27vw;
    width: 48vw;
  }
} */

.fanfest-video-1-mobile {
  width: 90vw;
  max-height: 50.625vw;
  margin-top: 1%;
}

.fanfest-video-2-mobile {
  width: 60vw;
  max-height: 33.75vw;
  margin-top: 1%;
}

.fanfest-video-4-mobile {
  width: 44vw;
  max-height: 24.75vw;
  margin-top: 1%;
}

.fanfest-video-9-mobile {
  width: 27vw;
  max-height: 15.1875vw;
  margin-top: 1%;
}

.fanfest-video-16-mobile {
  width: 18vw;
  max-height: 10.125vw;
  margin-top: 1%;
}

.fanfest-video {
  /*display: inline-flex;*/
  /* padding: 1%; */
  /*position: relative;*/
  /*max-height: 11.25vw;*/
  /*width: 20vw;*/
  object-fit: cover;
}

.fanfest-video-1 {
  /* width: 100%; */
  max-height: 28.125vw;
  width: 49.5vw;
}

.fanfest-video-2 {
  max-height: 22.5vw;
  width: 40vw;
}

.fanfest-video-4 {
  /* width: 50%; */
  max-height: 13.5vw;
  width: 24vw;
}

.fanfest-video-9 {
  /* width: 33%; */
  max-height: 9vw;
  width: 16vw;
}

.fanfest-video-16 {
  /* width: 25%; */
  max-height: 6.75vw;
  width: 12vw;
}

.fanfest-video-25 {
  /* width: 20%; */
  max-height: 5.34375vw;
  width: 9.5vw;
}

.fanfest-video-1-full {
  max-height: 50.625vw;
  width: 90vw;
  text-align: center;
}

.fanfest-video-4-full {
  max-height: 18.5625;
  width: 33vw;
}

.fanfest-video-9-full {
  max-height: 13.5vw;
  width: 24vw;
}

.fanfest-video-16-full {
  max-height: 10.6875vw;
  width: 19vw;
}

.fanfest-video-25-full {
  max-height: 9vw;
  width: 16vw;
}

#vici-vod {
  position: relative;
}

.vid-square {
  object-fit: cover;
  width: 500px;
  height: 500px;
}

.floating-button {
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 #666;
  transition: all 0.1s ease-in-out;

  background-color: purple;
  text-align: center;
  line-height: 70px;

  position: fixed;
  left: 2%;
  top: 10%;
}

.floating-button:hover {
  box-shadow: 0 6px 14px 0 #666;
  transform: scale(1.05);
}

.fanfest-loading {
  background-image: url("https://vici-resources.s3.amazonaws.com/loading.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.bg-image {
  background-size: cover;
  background-position: center;
}

.fixed-footer {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 100%;
}

.streamer-mute {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  font-size: 1.5vh;
  opacity: 1.0;
  /* z-index: 10000; */
}

button:focus {
  outline: none;
}

.counter {
  position: absolute;
  top: 1%;
  right: 3%;
  height:50px;
  width:50px;
  opacity: 1.0;
}

.streamer-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 1.5vw;
  font-weight: 700;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.7); */
  padding: 3px;
  /* opacity: 0.75; */
  /* z-index: 10000; */
}

@media only screen and (min-width: 575px) {
  .streamer-name {
    position: absolute;
    bottom: 5%;
    left: 5%;
    font-size: 1vw;
    font-weight: 700;
    color: white;
    padding: 3px;
    /* background-color: rgba(0, 0, 0, 0.7); */
    /* opacity: 0.75; */
    /* z-index: 10000; */
  }
}

@media only screen and (max-width: 575px) {
  .streamer-name {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-size: 3vw;
    font-weight: 700;
    color: white;
    padding: 3px;
    /* background-color: rgba(0, 0, 0, 0.7); */
    /* opacity: 0.75; */
    /* z-index: 10000; */
  }
}

/* .streamer-host { */
  /* background-color: gold; */
  /* padding: 0.1%; */
  /* border: gold; */
  /* border-width: 10%; */
/* } */

.video-full {
  /* padding-left: 8rem !important;
  padding-right: 8rem !important; */
  max-height: 85vh;
}

.sidebar {
  background-color: rgba(0, 0, 0, 0.5);
}

.mobile-sidebar {
  width: 100%;
  position: fixed;
  bottom: 10vh;
  left: 0;
  height:40vh;
  z-index:1000;
  background-color: rgba(0, 0, 0, 0.75);
}


</style>
