<template lang="pug">
  .bg.mt-5
    .p-3.bg-light.d-flex.justify-content-between
      b-form-file(v-model="csv" accept=".csv" placeholder='Select CSV')
    .p-3.bg-light.d-flex.justify-content-between
      b-button.text-uppercase.font-weight-bold(variant="outline-success", @click="parse" :disabled="csv === null") Leggo!
</template>


<script>

import axios from "axios";
import CSVParser from "papaparse";
import Parse from "../../services/parse";

export default {
  name: "BulkRegister",
  data() {
    return {
      csv: null,
      json: null
    };
  },
  mounted: function() {},
  computed: {
    
  },
  watch: {},
  sockets: {},
  methods: {
    parse: function() {
      let processCSV = (results) => {
        this.json = results.data
        this.register()
      }

      CSVParser.parse(this.csv, {
        header: true,
        complete: processCSV
      })
    },
    register: async function() {
      for (const f of this.json) {
        let fan = {
          fname: f.fname,
          lname: f.lname,
          email: f.email.toLowerCase(),
          birthday: f.birthday,
          gender: f.gender,
          language: f.language,
          canEmail: true
        };  
        
        let queryEvent = new Parse.Query('FanfestEvent')
        var response = await queryEvent.get(f.event)
        let event = response.toJSON()

        let queryProfile = new Parse.Query('FanfestConfig')
        queryProfile.equalTo('name', event.fanfest)
        queryProfile.include('group')
        response = await queryProfile.first()
        let profile = response.toJSON()

        let data = {
          fan: fan,
          profile: profile,
          event: event, 
          host: null,
          clientURL: `${window.location.protocol}//${window.location.hostname}`
        };

        axios.post(`${process.env.VUE_APP_BACKEND}/app/register/v2`, data)
        // let link = registration.data.link
        console.log(`Invite sent to ${fan.fname}`)
      }
    }
  }
};
</script>


<style scoped>
.bg {
  height: 100vh;
  background-color: white;
  }
</style>