const state = {
  chatDataSource: undefined,
  missedMessages: [],
  audienceList: new Set(),
  visible: false
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  configChat(state, source) {
    state.chatDataSource = source;
  },
  /* banFFUser(state) {
    state.chatDataSource.sendMessageToRoom(`✌️`, (error, result) => {
      if (error) {
        alert("Failed to send message", error);
        return;
      }

      console.log("Sent message to room with status " + result.status)
    });
  }, */
  updateAudienceList(state, screenName) {
    state.audienceList.add(screenName);
  },
  updateChatVisibility(state, visible) {
    state.visible = visible
  },
  addMissedMessage(state, message) {
    state.missedMessages.push(message)
  },
  removeMissedMessage(state) {
    state.missedMessages.splice(0,1)
  },
  logout(state) {
    state.chatDataSource.stop();
    state.chatDataSource = null;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}