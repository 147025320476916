<template lang="pug">
  div(@click="showAudienceList = true")
    .container-flex.counter.text-white.d-flex.align-items-center.justify-content-left.px-3.rounded-pill.font-weight-bold.animated.fadeIn.appear.delay-3s
      span.mr-2
        i.fa.fa-x.fa-eye()
      span {{ viewers }}
    b-modal(v-if="canHost" v-model="showAudienceList" centered)
      AudienceList
  
</template>

<script>
import { mapState } from "vuex";

import AudienceList from "../components/AudienceList"

export default {
  name: "Counter",
  data() {
    return {
      viewers: 0,
      showAudienceList: false
    };
  },
  computed: {
    ...mapState({
      groupID: state => state.client.group.objectId,
      groupName: state => state.client.group.name,
      platformType: state => state.client.platformType
    }),
    ...mapState({
      numMembers: state => state.fanfest.numMembers,
      fanfestScreenname: state => state.fanfest.screenName,
      fanfestStreamingEnabled: state => state.fanfest.canStream,
      uniqueScreenName: state => state.fanfest.registration.objectId,
      canHost: state => state.fanfest.canHost,
      hasHost: state => state.fanfest.hasHost,
      isHost: state => state.fanfest.isHost,
      showVOD: state => state.fanfest.showVOD,
      imageBackground: state => state.fanfest.config.mainBG
    })
  },
  components: {
    AudienceList
  },
  sockets: {
    eventRoomCount: function(count) {
      this.viewers = count;
    },
    banFFUser: function() {
      this.viewers--;
    }
  }
};
</script>

<style scoped>
.counter {
  position: absolute;
  left: 3%;
  top: 5%;
  height: 5%;
  font-size: 1.5vw;
  /* background-color: black; */
  opacity: 0.75;
}
</style>>