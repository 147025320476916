import 'es6-promise/auto'
import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";

import client from './client'
import fanfest from './fanfest'
import sidebar from './sidebar'
import chat from './chat'
import host from './host'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    client,
    fanfest,
    sidebar,
    chat,
    host
  },
  plugins: [
    /* createPersistedState({ 
      storage: window.sessionStorage,
      paths: ['client', 'fanfest.config', 'fanfest.canHost']
    }) */
  ]
})