<template lang="pug">
  .container-flex(:style="profileCustomAppearance")
    .row.no-gutter
      .col-md-4.col-lg-6.d-none.d-md-flex.bg-image(:style="{backgroundImage: 'url('+imageBackground+')'}")
      .col-md-8.col-lg-6.px-0.bg-white
        .login.d-flex.align-items-center.py-1.bg-white.animated.fadeIn(v-if='currentEvent')
          .container
            .row
              .col-md-10.col-lg-10.mx-auto
                .my-2                  
                  // Header
                  img#logo.mx-auto.d-block(v-if='!isEmbed' :src='imageLogo' style="height:30vh")
                  
                  // Current event
                  b-card.shadow.text-center.my-3
                    template(v-slot:header)
                      .d-flex.justify-content-between.align-items-center
                        .text-uppercase(:class="[(currentEvent.date == 'live' || currentEvent.date == 'en direct') ? 'text-white bg-danger px-2 font-weight-bold rounded' : '']") {{ $t('profile.live') }}
                        // .text-uppercase {{ currentEvent.capacity }} {{ $t("profile.seats") }}
                    div(v-if='!showRegister')
                      b-card-title.font-weight-bold {{ currentEvent.title }}
                      b-card-text
                        span(style="white-space: pre-wrap") {{ currentEvent.description }}
                      b-button.register-button.text-uppercase.font-weight-bold(block variant='' @click='showRegister = true') {{ buttonText }}
                    // Register button
                    Register.h-100(:profile='fanfest' :event='currentEvent' :cost='Number(currentEvent.cost)' :host='hostRegistration' :didAcceptCookies='didAcceptCookies' :fanData='fanData' :useSSO='useSSO' v-if='showRegister' @close='showRegister = false')
                  
                  // Upcoming events                  
                  //- .mt-5.mb-3.text-uppercase.text-center.font-weight-bold.text-secondary(v-if='upcomingEvents.length > 0') additional ticket options 

                  b-card.shadow.text-left.text-dark.my-3(v-for="e,i in upcomingEvents" :key="i" border-variant='0' style='border-radius:0')
                    .d-flex.justify-content-between.align-items-center
                      div.mr-1
                        h4.d-block.m-0.font-weight-bold {{ e.title }}
                        .d-flex.justify-content-left.align-items-center
                          //- .d-block.text-mute.text-uppercase {{ e.date }}
                          //- .mx-1 •
                          //- .d-block.text-mute.text-uppercase {{ e.capacity }} seats left
                      b-button.text-uppercase.font-weight-bold(variant='outline-success' @click='updateCurrentEvent(i)' type='submit') {{ $t('profile.chooseButton') }}

                  //- b-carousel.shadow(v-model='upcomingEventIndex' :interval='2000')
                    b-carousel-slide(v-for="e,i in upcomingEvents" :key="i" img-blank)
                      template(v-slot:img)
                        b-card.text-left.text-dark(border-variant='0' style='border-radius:0')
                          .d-flex.justify-content-between.align-items-center
                            div.mr-1
                              h4.d-block.m-0.font-weight-bold {{ e.title }}
                              .d-flex.justify-content-left.align-items-center
                                //- .d-block.text-mute.text-uppercase {{ e.date }}
                                //- .mx-1 •
                                //- .d-block.text-mute.text-uppercase {{ e.capacity }} seats left
                            b-button.text-uppercase.font-weight-bold(variant='outline-success' @click='updateCurrentEvent' type='submit') {{ $t('profile.createButton') }}
                  
                  .my-3.text-uppercase.text-center.font-weight-bold.text-secondary(v-if="fanfest.name != 'nbatopshot'") powered by 
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'mancity'" src='https://vici-resources.s3.us-east-1.amazonaws.com/cisco-logo.jpg' style="height:10vh")
                  //- img#logo.mx-auto.d-block(v-if="fanfest.name == 'icc'" src='https://fanfest-resources.s3.us-east-1.amazonaws.com/coke-logo.jpg' style="height:10vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'bucks'" src='https://fanfest-resources.s3.us-east-1.amazonaws.com/moto-logo.jpeg' style="height:10vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'dolphins'" src='https://fanfest-resources.s3.amazonaws.com/vz-logo.jpg' style="height:10vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'nxtscore'" src='https://fanfest-resources.s3.amazonaws.com/dk-logo.jpg' style="height:10vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'bayern'" src='https://pbs.twimg.com/profile_images/1212497855241281536/JaDQeuXl_400x400.jpg' style="height:10vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'chelsea'" src='https://pbs.twimg.com/profile_images/1333191465611235336/oZkPfptp_400x400.jpg' style="height:12vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'laliga'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/Verizon_2015_logo_-vector.svg/2560px-Verizon_2015_logo_-vector.svg.png" style="height:8vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name == 'fff'" src="https://pbs.twimg.com/profile_images/1520869710190899200/jQUtFOHn_400x400.jpg" style="height:10vh")
                  img#logo.mx-auto.d-block(v-if="fanfest.name != 'nbatopshot'" src='/fanfest-logo.png' style="height:8vh")

    // CookieLaw(theme='blood-orange' @accept='didAcceptCookies = true' position='top' transitionName='slideFromTop')
</template>


<script>
import { mapState } from "vuex";

import axios from "axios";
import bowser from "bowser";
import CookieLaw from 'vue-cookie-law'
import Amplitude from "../services/amplitude";

import Register from "../components/Register";

export default {
  name: "FanFestProfile",
  metaInfo() {
    return {
      title: `${this.pageTitle}`
    };
  },
  components: {
    Register, 
    CookieLaw
  },
  data() {
    return {
      fanfest: {},
      hostRegistration: null,
      inviteRegistration: null,
      imageBackground: "",
      imageLogo: "",
      imageSponsor: "",
      groupName: "",
      buttonText: "",
      currentEvent: null,
      upcomingEvents: [],
      upcomingEventIndex: 0,
      showRegister: false,
      pageTitle: 'Upcoming FanFest Events',
      didAcceptCookies: false,
      fanData: null, 
      useSSO: false
    };
  },
  computed: {
    ...mapState({
      isEmbed: state => state.client.isEmbed,
    }),
    liveLabel: function() {
      return ('live' || 'en direct')
    },
    profileCustomAppearance: function() {
      if (!this.fanfest.name) return {}

      if (this.fanfest.name === '49ers') {
       return {
          'font-family': "factoria, serif",
          // 'font-weight': 500,
          // 'font-style': 'bold',
        }        
      }

      return {}
    }
  },
  created: function() {
    if (this.$route.query.origin) this.$store.commit("enableEmbedMode");
    this.configProfile();
  },
  mounted: function() {
    this.loadExistingFan()

    this.$gtag.event('entered_registration', {
        'event_category': 'growth',
        'non_interaction': true
      })
    Amplitude.getInstance().logEvent('Entered Registration Page')
  },
  watch: {},
  sockets: {},
  methods: {
    loadExistingFan: async function() { 
      // if SSO passed user info, use that
      if (this.$route.query.fname) {
        this.fanData = {
          fname: this.$route.query.fname.trim(),
          lname: this.$route.query.lname ? this.$route.query.lname.trim() : "",
          email: this.emailValid(this.$route.query.email) ? this.$route.query.email.trim() : `${this.$route.query.email.trim()}@${this.$route.params.fanfest}-fanfest-partner.io`,
        }

        this.useSSO = true
      }
      // otherwise, check for an external ID and use that
      else if (this.$route.query.externalID) {
        window.localStorage.setItem(process.env.VUE_APP_COOKIE_ID, this.$route.query.externalID)
        let response = await axios.get(`${process.env.VUE_APP_BACKEND}/app/register/checkCookie/${window.localStorage.getItem(process.env.VUE_APP_COOKIE_ID)}`).catch(() => {
          // window.localStorage.removeItem(process.env.VUE_APP_COOKIE_ID)
          return
        })
        if (response) this.fanData = response.data
      }
      // otherwise, check for a local external ID and use that (hosted scenario)
      else if (window.localStorage.getItem(process.env.VUE_APP_COOKIE_ID)) {
        let response = await axios.get(`${process.env.VUE_APP_BACKEND}/app/register/checkCookie/${window.localStorage.getItem(process.env.VUE_APP_COOKIE_ID)}`).catch(() => {
          // window.localStorage.removeItem(process.env.VUE_APP_COOKIE_ID)
          return
        })
        if (response) this.fanData = response.data
      } 
      // otherwise do nothing - new user!     
      else {
        console.log('[vici]: no cookie - new user detected!')
      }
    },
    configProfile: function() {
      const browser = bowser.getParser(window.navigator.userAgent);

      console.log('client: '+JSON.stringify(bowser.parse(window.navigator.userAgent)))

      let platformType = browser.getPlatformType();
      console.log(`platform: ${platformType}`)

      let browserName = browser.getBrowserName();
      console.log(`browser: ${browserName}`)

      let hasTouchscreen = navigator.maxTouchPoints > 1
      console.log(`touchscreen? ${hasTouchscreen}`)

      let fanfest = this.$route.params.fanfest;
      axios
        .get(`${process.env.VUE_APP_CONFIG_ROOT}/${fanfest}/id`)
        .then(response => {
          let fanfest = response.data.fanfest;

          this.fanfest = fanfest;
          this.imageBackground = fanfest.loginBG;
          this.imageLogo = fanfest.group.image;
          this.imageSponsor = fanfest.sponsor.logo;
          this.groupName = fanfest.group.name;
          this.pageTitle = `FanFest - ${this.groupName}`

          this.configEvents();
        })
        .catch(err => {
          Promise.reject(err.message);
          this.$router.push({ path: "/error" });
        });
    },
    configEvents: async function() {
      if (!this.fanfest) {
        this.configProfile();
        return;
      }

      let fanfest = this.$route.params.fanfest;
      let responseEvent = await axios.get(
        `${process.env.VUE_APP_CONFIG_ROOT}/${fanfest}/events`
      );
      let allEvents = responseEvent.data;

      if (this.$route.query.host) {
        let responseHost = await axios.get(
          `${process.env.VUE_APP_BACKEND}/app/register/${this.$route.query.host}`
        );
        this.hostRegistration = responseHost.data;
        console.log(`host: ${JSON.stringify(this.hostRegistration)}`)

        this.$gtag.event('invite_opened', {
          'event_category': 'growth',
        })
        Amplitude.getInstance().logEvent('Invite Opened')
      }

      if (this.$route.query.invite) {
        let responseInvite = await axios.get(
          `${process.env.VUE_APP_BACKEND}/app/register/${this.$route.query.invite}`
        );
        this.inviteRegistration = responseInvite.data;
        console.log(`host: ${JSON.stringify(this.inviteRegistration)}`)

        this.$gtag.event('invite_opened', {
          'event_category': 'growth',
        })
        Amplitude.getInstance().logEvent('Invite Opened')
      }

      if (this.hostRegistration)
        this.upcomingEvents = allEvents.filter(e => e.objectId === this.hostRegistration.event);
      else if (this.$route.query.e)
        this.upcomingEvents = allEvents.filter(e => e.objectId === this.$route.query.e);
      else
        this.upcomingEvents = allEvents;

      // Placeholder events
      /* if (this.upcomingEvents.length === 0) {
        var eventPlaceholder = [
          {
            title: `${this.groupName} Supporter Prematch Show`,
            description: `Join ${this.groupName} legends and registered members for an interactive prematch show with live chat and score predictions`,
            date: "live",
            capacity: "Unlimited",
            fanfest: this.fanfest.name
          },
          {
            title: `Virtual Stadium + ${this.fanfest.sponsor.name} Halftime Show`,
            description: `Go behind-the-scenes during today's match. Submit videos and get featuring during our fan-generated ${this.fanfest.sponsor.name} halftime show.`,
            date: "in 1 hour",
            capacity: 13304,
            cost: 4.99,
            code: false,
            fanfest: this.fanfest.name
          },
          {
            title: "Members Only Post-Match Conference",
            description:
              "Ask questions during interactive post-match press conference. For Registered Supporters and Seasons Ticket holders only",
            date: "at 5:30p",
            capacity: 430,
            code: true,
            fanfest: this.fanfest.name
          }
        ];

        this.upcomingEvents = eventPlaceholder;
      } */

      this.currentEvent = this.upcomingEvents.shift();
      this.updateButtonText();
    },
    updateCurrentEvent: function(index) {
      this.showRegister = false;
      let nextEvent = this.upcomingEvents.splice(index, 1)[0];
      this.upcomingEvents.unshift(this.currentEvent);
      this.currentEvent = nextEvent;
      this.updateButtonText();
    },
    updateButtonText: function() {
      /* if (this.currentEvent.cost) {
        console.log(navigator.languages);
        let currency =
          navigator.language.toLowerCase().indexOf("us") >= 0 ? "$" : "€";
        this.buttonText = `purchase ticket for ${currency}${this.currentEvent.cost}`;
      } else if (this.currentEvent.code) this.buttonText = "request access";
      else this.buttonText = "register for free"; */
      
      if (!this.hostRegistration) {
        this.buttonText = this.$t('profile.createButton')
        return
      }
      
      this.buttonText = this.$t('profile.inviteButton')
      
      if (this.inviteRegistration)
        this.buttonText += ' ' + this.inviteRegistration.fan.fname + ' ' + this.inviteRegistration.fan.lname
        //this.buttonText += this.inviteRegistration.fan.objectId === this.hostRegistration.fan.objectId ? '' : ` and ${this.inviteRegistration.fan.fname}`
      else
        this.buttonText += ' ' + this.hostRegistration.fan.fname + ' ' + this.hostRegistration.fan.lname

      document.activeElement.blur(); // Keep carousel moving
    },
    emailValid: function(email) {      
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  }
};
</script>

<style scoped>
.register-button {
  /* background-color: rgb(103, 1, 100); */
  background-color: green;
}

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-size: cover;
  background-position: center;
}

.bg-image-center {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: lightgrey;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
  border-radius: 2rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

#logo {
  width: 100%;
  object-fit: contain;
}
</style>