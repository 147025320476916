<template lang="pug">
  .container-fluid.error-page
    .text-white.text-center.py-5
      h1 {{ message }}
</template>

<script>
export default {
  name: "Error",
  metaInfo() {
    return {
      title: "No FanFest Found ¯\\_(ツ)_/¯"
    };
  },
  props: {
    message: String
  },
  created: function(){
    this.$store.commit('logout')
    window.sessionStorage.clear()
  }
}

</script>

<style>
.error-page {
  height: 100vh;
  width: 100vw;
  background-image: url('https://media.giphy.com/media/xWlgutH3ZZF6w/giphy.gif');
  background-repeat: repeat;
}
</style>