<template lang="pug">
  div(v-if="audience === message.audience" @click="showModeration")
    transition(:key="show" name="custom-classes-transition" enter-active-class='animated fadeIn' leave-active-class='animated fadeOut' mode="out-in" appear)
      .text-left.text-white.mx-2.my-1(v-if='show')
        span.font-weight-bolder.text-uppercase.mr-2(:style='{color: color}') {{ message.author }}  
        span.font-weight-bold {{ message.text }}
    ModeratorBan(v-if='isUnderModeration' :registrationID="this.message.registration" @hide="isUnderModeration = false")
</template>

<script>
import { mapState } from "vuex";
import ModeratorBan from "./ModeratorBan"

export default {
  name: "ChatMessage",
  components: {
    ModeratorBan
  },
  data() {
    return {
      // author: this.message.author,
      // text: this.message.text,
      show: true,
      isUnderModeration: false
    }
  },
  props: {
    message: Object,
    shouldShow: Boolean,
    color: String,
    audience: String
  },
  computed: {
    ...mapState({
      platformType: state => state.client.platformType,
      canHost: state => state.fanfest.canHost,
    }),
  },
  watch: {
    shouldShow: function() {
      if (this.shouldShow)
        this.show = this.shouldShow
      else {
        this.hide()
      }

    }
  },
  mounted: function() {
    this.hide()
  },
  
  methods: {
    hide: function() {
      /* if (this.platformType === 'mobile') return
      
      const self = this;
      setTimeout(() => {
        if (!self.shouldShow)
          self.show = false;
      }, 10000); */
    },
    showModeration: function() {
      if (!this.canHost) return
      if (this.platformType != 'desktop') return
      this.isUnderModeration = true
    }
  }
}
</script>

<style scoped>

span {
    word-wrap:break-word;
    width: 100%;
    white-space: normal
}

</style>