<template lang="pug">
    .container-fluid
        .row.no-gutter
            .d-none.d-md-flex.col-md-4.col-lg-6.bg-image
            .col-md-8.col-lg-6.bg-white
                .login.d-flex.align-items-center.py-5
                    .container
                        .row
                            .col-md-9.col-lg-8.mx-auto
                                img#logo.mx-auto.my-3.d-block(src='https://vici-resources.s3.amazonaws.com/other/vici_logo_round_lowrez.jpg' height='150')
                                form
                                    .form-label-group.mt-5
                                        input#inputUsername.form-control(v-model.trim="username" type='text', placeholder='Username', required='', autofocus='' autocomplete='username')
                                        label(for='inputUsername') Enter Username
                                    .form-label-group
                                        input#inputPassword.form-control(v-model.trim="password" type='password', placeholder='Password', required='' autocomplete='current-password')
                                        label(for='inputPassword') Enter Password
                                    // .custom-control.custom-checkbox.mb-3
                                        input#customCheck1.custom-control-input(type='checkbox')
                                        label.custom-control-label(for='customCheck1') Remember password
                                    button#buttonLogin.btn.btn-lg.btn-dark.btn-block.btn-login.text-uppercase.font-weight-bold.mb-2(@click="login" type='submit') Sign in
    
</template>

<script>
import Parse from "../services/parse.js"

export default {
  name: "login",
  metaInfo() {
    return {
      title: "VICI TV Login"
    };
  },
  data() {
    return {
      username: "",
      password: ""
    };
  },
  components: {},
  methods: {
    login: async function(event) {
      event.preventDefault();

      try {
        await Parse.User.logIn(this.username, this.password)
        this.$router.push("/dashboard");
      } catch(err) {
        Parse.User.logOut();
        alert(err.message);
      }
    }
  }
};
</script>

<style scoped>
:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url("https://vici-resources.s3.amazonaws.com/dashboard-login-bg.jpg");
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
  border-radius: 2rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
</style>