<template lang="pug">
  .table-responsive
    table.table.table-striped
      tbody
        tr(v-for="screenName,i in this.audienceList")
          td.align-middle 
            div.font-weight-bold.text-dark.text-underline {{ screenName }}                   
          td.align-middle
            .text-right
              button.btn.btn-danger.btn-sm.m-0.text-uppercase(type='button' :screenName="screenName" @click="banMember(screenName)") Ban
</template>


<script>
import { mapState } from "vuex";

//import axios from "axios";

export default {
  name: "AudienceList",
  data() {
    return {};
  },
  mounted: function() {},
  computed: {
    ...mapState({
      audienceList: state => state.chat.audienceList,
      groupID: state => state.client.group.objectId,
      eventRoom: state => state.fanfest.roomForContent,
    })
  },
  watch: {},
  sockets: {},
  methods: {
    banMember: function(screenName) {
      if (
        confirm(
          `Are you sure you want to remove ${screenName} from streaming?`
        )
      ) {
        this.$socket.emit("banFFUser", this.eventRoom, screenName);
      }
    }
  }
};
</script>


<style scoped>
</style>