<template lang="pug">
  .d-flex.align-items-center
    b-button.d-none.new-banner-button.mr-2.text-white.text-uppercase.font-weight-bold.p-1.pulse.infinite.faster(v-if="canStream && platformType != 'mobile'" @click='tappedStreamButton' :class="[isStreaming ? 'bg-success' : 'bg-danger animated']" v-b-tooltip.hover :title="isStreaming ? 'End Stream' : 'Go Live'")
      i.fa.fa-rss.text-light
    //b-button.new-banner-button.mr-2.text-white.text-uppercase.font-weight-bold.px-1(v-if="isStreaming && platformType != 'mobile'" @click='tappedVideoToggle' ref='toggleVideoButton' :class="[isStreamingVideo ? 'bg-success' : 'bg-danger']" v-b-tooltip.hover title="Camera On/Off")
      i.fa.fa-video-camera.text-light
    //b-button.new-banner-button.mr-2.bg-secondary.text-white.text-uppercase.font-weight-bold.p-1(v-if="isStreaming" @click='tappedAudioToggle' ref='toggleAudioButton' v-b-tooltip.hover :title="$t('tooltip.mic')")
      i.fa.fa-microphone.text-light(:class="[isStreamingAudio ? 'fa-microphone' : 'fa-microphone-slash']")
    
    b-modal(id="modal-welcome" size='lg' centered no-close-on-esc no-close-on-backdrop hide-header-close)
      .container-float
        img.mx-auto.d-block(:src='this.groupLogo' style="height:13vh")
        // .text-uppercase.text-dark.text-center.mt-2(style="font-family: 'Bungee Inline', cursive; font-size:3vh") Fanfest
        //- img.mx-auto.d-block(src='/fanfest-logo.png' style="height:8vh")
        
        .m-3.p-3(v-if='hasInvalidClient')
          .text-center.font-weight-light {{ $t('alert.welcomeErrorClient', {group:groupName}) }}          
        .m-2.p-2(v-else)
          .text-center.font-weight-light(v-if='stagingLink') {{ $t('alert.welcomeGoLive', {group : groupName}) }}
          .text-center.font-weight-bold.mt-3(v-if="platformType == 'mobile'") {{ $t('alert.welcomeMobile') }}
          //- a.text-center.font-weight-bold.d-block.mx-auto.mt-3(v-if="(canStream || canHost) && stagingLink" :href="stagingLink" target='_blank') {{ $t('alert.welcomeTestConnection') }}
      template(v-slot:modal-footer)
        //- b-button.bg-success.mx-auto.d-block.w-25(v-if='$route.query.kiosk' ref='startPublishButton' @click='tappedStreamButton' size='lg' :disabled='hasInvalidClient') 
          i.fa.text-light.fa-2x(class='fa-binoculars')

        b-button.bg-success.mx-auto.d-block.w-25(v-if="!canStream && stagingLink" ref='startPublishButton' @click="tappedStreamButton('user')" size='lg' :disabled='hasInvalidClient') 
          i.fa.text-light.fa-2x(:class="[!canStream ? 'fa-comment' : 'fa-comment']")
        
        b-button.bg-success.mx-auto.d-block.w-25(v-if="canStream || !stagingLink" ref='startPublishButton' @click="tappedStreamButton('user')" size='lg' :disabled='hasInvalidClient') 
          i.fa.text-light.fa-2x(:class="[canStream ? 'fa-video-camera' : 'fa-video-camera']")
        
        //- b-button.bg-success.mx-auto.d-block.w-25(v-if="canStream && (isVIP || canHost) && stagingLink" ref='startPublishButton' @click="tappedStreamButton('mic')" size='lg' :disabled='hasInvalidClient') 
          i.fa.text-light.fa-2x(:class="[canStream ? 'fa-microphone' : 'fa-microphone']")
          
        //- b-button.bg-success.mx-auto.d-block.w-25(v-if="canStream && isVIP && platformType != 'desktop'" ref='startPublishButton' @click="tappedStreamButton('environment')" size='lg' :disabled='hasInvalidClient')          
          i.fa.text-light.fa-2x(:class="[canStream ? 'fa-image' : 'fa-image']")
        
    //b-alert.position-fixed.fixed-top.m-0.rounded-0.text-center(v-if='showConnectionError' :show='5' @dismissed="showConnectionError=false" variant='danger' dismissible style='z-index:2000') Poor Connection Detected

      
</template>

<script>
import { mapState } from "vuex";

import sdk from "phenix-web-sdk";
import axios from "axios";
import Amplitude from "../../services/amplitude";
import UserLeap from "../../services/userleap";
// import bowser from "bowser";

const maxStreamers = 100;

var publisher = null;
// var screenPublisher = null;
// var lowQualityPublisher = null;
var roomService = null;
var roomExpress = null;
//var screenName = "ScreenName" + Math.floor(Math.random() * 10000) + 1; // Helpful if unique but we don't enforce this. You might set this to be an email or a nickname, or both. Then parse it when joining the room.
//var memberRole = "Participant";
var membersStore = [];
var memberSubscriptions = {};
var videoSources = [];
var audioSources = [];
var sendMessageIntervalId;
var isMobileAppleDevice =
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
var isOtherMobile = /Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
  navigator.userAgent
);

export default {
  name: "FanFestService",
  data() {
    return {
      shouldStreamAudio: false,
      isStreaming: false,
      isStreamingVideo: false,
      isStreamingAudio: false,
      didSelectUserCam: true,
      didSelectMicOnly: false,
      showConnectionError: false,
      isSubscribing: false,
      isPublishing: false
    };
  },
  computed: {
    ...mapState({
      isEmbed: state => state.client.isEmbed,
      groupID: state => state.client.group.objectId,
      groupLogo: state => state.client.group.image,
      groupName: state => state.client.group.name,
      platformType: state => state.client.platformType,
      shouldMute: state => state.client.shouldMute,
      shouldMuteVideo: state => state.client.shouldMuteVideo,
      shouldMuteStreamers: state => state.client.shouldMuteStreamers,
      forceMic: state => state.client.forceMic,
      hasInvalidClient: state => state.client.isInvalid,
    }),
    ...mapState({
      roomForTeam: state => state.fanfest.roomForFans,
      numMembers: state => state.fanfest.numMembers,
      displayedStreams: state => state.fanfest.streams,
      fanfestScreenname: state => state.fanfest.screenName,
      fanfestStreamingEnabled: state => state.fanfest.canStream,
      registration: state => state.fanfest.registration,
      uniqueScreenName: state => state.fanfest.registration.objectId,
      canStream: state => state.fanfest.canStream,
      canHost: state => state.fanfest.canHost,
      hasHost: state => state.fanfest.hasHost,
      isHost: state => state.fanfest.isHost,
      isVIP: state => state.fanfest.isVIP,
      showVOD: state => state.fanfest.showVOD,
      imageBackground: state => state.fanfest.config.mainBG,
      shareLink: state => state.fanfest.shareLink
    }),
    stagingLink: function() {
      if (this.$route.query.staging) return undefined
      return `${window.location.href}&staging=1`
    }
  },
  props: {
    videoList: Array, // other streamers
    selfVideoList: Object, // my stream
    // screenName: String,
    // canStream: Boolean
    // streamButton: Object,
  },
  watch: {
    shouldMute: function() {
      if (!publisher) return;

      if (this.shouldMuteStreamers) {
        this.isStreamingAudio = false;
        // this.shouldStreamAudio = false;
        
        /* if (this.platformType === 'mobile') 
          this.stream();        
        else  */
          publisher.publisher.disableAudio();        
      }
      else {
        this.isStreamingAudio = true;
        // this.shouldStreamAudio = true;
        
        /* if (this.platformType === 'mobile') 
          this.stream();
        else  */
          publisher.publisher.enableAudio();      
      }
    },
    registration: function() {
      // prevent logic on first-run
      if (!this.isStreaming) return

      // if registration changed and now canStream, show modal to go live
      if (this.canStream) {
        this.isStreaming = false
        this.$bvModal.show('modal-welcome')
      }
      // otherwise, demoted to chat so reload 
      else window.location.reload()
    }
    /* isStreaming: function() {
      if (this.isStreaming) {
        const self = this
        setInterval(() => {
          self.checkStreams()
        }, 1000);
      }
    } */
  },
  created: function() {
    /* roomForTeam = this.$store.state.fanfest.config.name;
    
    if (this.$store.state.fanfest.registration.event)
      roomForTeam += `-${this.$store.state.fanfest.registration.event}`

    if (process.env.NODE_ENV != "production")
      roomForTeam += '-dev' */
  },
  mounted: function() {
    this.init();

    // Plugin might load with delay
    if (sdk.utils.rtc.phenixSupported && !sdk.utils.rtc.isPhenixEnabled()) {
      sdk.utils.rtc.onload = function() {
        this.init();
      };
    }

    let self = this;
    window.addEventListener("beforeunload", function() {
      self.leaveRoomAndStopPublisher()      
    });
  },
  beforeDestroy: function() {
    this.leaveRoomAndStopPublisher();
  },
  methods: {
    init: function() {
      const self = this;

      setUserMessage(
        "device is mobile: " + (isMobileAppleDevice || isOtherMobile)
      );

      var createRoomExpress = function createPCastExpress() {
        var adminApiProxyClient = new sdk.net.AdminApiProxyClient();

        adminApiProxyClient.setBackendUri(
          "https://demo-integration.phenixrts.com/pcast"
        );
        adminApiProxyClient.setAuthenticationData({
          userId: "phenix-test-1",
          password: "phenix-test-2"
        });

        roomExpress = new sdk.express.RoomExpress({
          treatBackgroundAsOffline: false,
          adminApiProxyClient: adminApiProxyClient
          /* uri: app.getUri(),
          shaka: shaka */
        });

        /* if (app.getUrlParameter('debug') === 'true') {
          app.addDebugAppender(roomExpress.getPCastExpress().getPCast());
        } */

        createRoom();
      };

      // console.log('SDK', sdk.utils, sdk.utils.rtc);
      try {
        sdk.utils.rtc.getSources(function(sources) {
          videoSources = sources.filter(function(source) {
            return source.kind === "video";
          });
          audioSources = sources.filter(function(source) {
            return source.kind === "audio";
          });
        });
      } catch (err) {
        console.log('Error getting sources on deprecated getSources');
      }

      function createRoom() {
        var roomAlias = self.roomForTeam;
        var options = {
          room: {
            name: roomAlias,
            type: "MultiPartyChat",
            alias: roomAlias // Not required but if its provided we will use this as the alias instead of pre-generating one for you.
          }
        };

        roomExpress.createRoom(options, function callback(error, response) {
          if (error) {
            // Handle error
            setUserMessage("Unable to create room: " + error.message);
            self.leaveRoomAndStopPublisher();
            window.location.reload();
            throw error;
          }

          if (response.status === "already-exists") {
            // Room already exists
            // Do something with roomService
            joinRoom();
          } else if (response.status !== "ok") {
            // Handle error
          }

          // Successfully created room
          if (response.status === "ok" && response.room) {
            // Do something with room
            joinRoom();
          }
        });
      }

      function joinRoom() {
        var roomAlias = self.roomForTeam;
        var role = "Audience";
        var screenName = `${self.uniqueScreenName}`;
        // setUserMessage(screenName);

        roomExpress.joinRoom(
          {
            screenName: screenName,
            alias: roomAlias,
            role: role // Set your role for yourself. Participant will view and interact with other members (must have streams). Audience just watches.
          },
          function joinRoomCallback(error, response) {
            if (error) {
              setUserMessage("Unable to join room: " + error.message);
              self.leaveRoomAndStopPublisher();

              throw error;
            }

            if (response.status !== "ok" && response.status !== "ended") {
              setUserMessage("New Status: " + response.status);

              throw new Error(response.status);
            }

            setUserMessage("Joined room");

            if (!roomService) {
              roomService = response.roomService;
            }

          self.$bvModal.show('modal-welcome')

            /* displayElement(publishScreenShareButton); */

            let chatService = response.roomService.getChatService();
            self.$store.commit("configChat", chatService);

            /* chatService.start(50);

            chatService.getObservableChatEnabled().subscribe(
              function(enabled) {
                if (enabled) {
                  setUserMessage("Chat is enabled");
                } else {
                  setUserMessage("Chat is DISABLED");
                }
              },
              { initial: "notify" }
            );

            chatService
              .getObservableLastChatMessage()
              .subscribe(function(message) {
                setUserMessage(
                  "Received message [" +
                    message.messageId +
                    "]/[" +
                    new Date(message.timestamp) +
                    "]: [" +
                    message.message +
                    "]"
                );
              });

            chatService
              .getObservableChatMessages()
              .subscribe(function(messages) {
                setUserMessage(
                  "Current chat history is [" + messages.length + "] messages"
                );
              }); */

            /* var messageIdx = 0;

            sendMessageIntervalId = setInterval(function sendMessage() {
              if (!chatService.getObservableChatEnabled().getValue()) {
                return;
              }

              if (!chatService.canSendMessage()) {
                setUserMessage("Can NOT send messages right now");

                return;
              }

              var message = "Test message " + messageIdx++ + ":" + _.now();

              chatService.sendMessageToRoom(message, function(error, result) {
                if (error) {
                  console.error("Failed to send message", error);

                  return;
                }

                setUserMessage(
                  "Sent message to room with status ",
                  _.get(result, ["status"]),
                  ": ",
                  message
                );
              });
            }, 5000); */
          },
          function membersChangedCallback(members) {
            // This is triggered every time a member joins or leaves
            setUserMessage("Members updated, count=[" + members.length + "]");
            removeOldMembers(members);
            addNewMembers(members);

            self.$store.commit("updateActiveMembers", members.length);
            self.checkStreams();
          }
        );
      }

      function removeOldMembers(members) {
        var membersThatLeft = membersStore.filter(function(member) {
          return !members.includes(member);
        });

        membersThatLeft.forEach(function(memberThatLeft) {
          var memberSubscription =
            memberSubscriptions[memberThatLeft.getSessionId()];

          if (memberSubscription) {
            memberSubscription.forEach(function(subscription) {
              removeMemberStream(
                subscription.memberStream,
                memberThatLeft.getSessionId()
              );

              setUserMessage('removing old member...')
              self.$store.commit("removeFanFestVideo", memberThatLeft.getSessionId());
            });
          }

          delete memberSubscriptions[memberThatLeft.getSessionId()];
        });

        membersStore = membersStore.filter(function(member) {
          return !membersThatLeft.includes(member);
        });
      }

      function removeMemberStream(memberStream, memberSessionId) {
        /*var streamInfo = memberStream.getInfo();

         let isHost = streamInfo.isHost;
        if (isHost == true) self.$store.commit("hasHost", false); */

        var memberSubscriptionToRemove = memberSubscriptions[
          memberSessionId
        ].find(function(memberSubscription) {
          return (
            memberStream.getPCastStreamId() ===
            memberSubscription.memberStream.getPCastStreamId()
          );
        });

        memberSubscriptions[memberSessionId] = memberSubscriptions[
          memberSessionId
        ].filter(function(memberSubscription) {
          return (
            memberStream.getPCastStreamId() !==
            memberSubscription.memberStream.getPCastStreamId()
          );
        });

        if (memberSubscriptionToRemove) {
          memberSubscriptionToRemove.mediaStream.stop();
          // memberSubscriptionToRemove.videoElement.remove();      
          return true;
        }

        return false;
      }

      function addNewMembers(members) {
        var membersThatJoined = members.filter(function(member) {
          return !membersStore.includes(member);
        });

        membersThatJoined.forEach(function(newMember) {
          var memberSessionId = newMember.getSessionId();

          memberSubscriptions[memberSessionId] = [];

          // Listen for changes to member streams. This will happen when the publisher fails and it recovers, or when adding or removing a screen share
          var memberStreamSubscription = newMember
            .getObservableStreams()
            .subscribe(function(memberStreams) {
              if (!memberSubscriptions[memberSessionId]) {
                return memberStreamSubscription.dispose();
              }

              // Remove old streams
              memberSubscriptions[memberSessionId].forEach(function(
                memberSubscription
              ) {
                var shouldRemoveMember = !memberStreams.find(function(stream) {
                  return (
                    stream.getPCastStreamId() ===
                    memberSubscription.memberStream.getPCastStreamId()
                  );
                });

                if (shouldRemoveMember) {
                  removeMemberStream(
                    memberSubscription.memberStream,
                    memberSessionId
                  );
                }
              });

              // Subscribe to new streams
              memberStreams
                .filter(function(memberStream) {
                  return !memberSubscriptions[memberSessionId].find(function(
                    memberSubscription
                  ) {
                    return (
                      memberStream.getPCastStreamId() ===
                      memberSubscription.memberStream.getPCastStreamId()
                    );
                  });
                })
                .forEach(function(memberStream) {
                  subscribeToMemberStream(memberStream, memberSessionId);
                });
            });

          newMember
            .getObservableStreams()
            .getValue()
            .forEach(function(memberStream) {
              subscribeToMemberStream(memberStream, memberSessionId);
            });
        });

        membersStore = members;
      }

      function subscribeToMemberStream(memberStream, sessionId) {
        var streamInfo = memberStream.getInfo(); // Access the custom stream info params that you passed when publishing
        // var isSelf = sessionId === roomService.getSelf().getSessionId(); // Check if is yourself!
        var isSelf = `${self.uniqueScreenName}` === streamInfo.uniqueName;
        if (isSelf) {
          self.$store.commit("updateSessionID", sessionId);  
          return;
        }

        self.isSubscribing = true;
        // setUserMessage('subscribe start')

        self.$store.commit("createFanFestVideo", {
          sessionId: sessionId,
          registration: streamInfo.uniqueName,
          isVIP: streamInfo.isVIP,
          screenName: streamInfo.screenName,
          isAudioOnly: streamInfo.isAudioOnly
        });

        setTimeout(() => {
          var videoElement = document.getElementById(sessionId);

          if (!videoElement) {
            setUserMessage(`error subscribing to member stream - no vid element: ${JSON.stringify(streamInfo)}`)
            return
          }

          videoElement.setAttribute("u", streamInfo.screenName);

          self.$store.commit("updateAudienceList", streamInfo.screenName);

          videoElement.addEventListener("click", function() {
            // unmute all streams and hide tooltips 
            /* videoElement.muted = false
            self.$store.commit("updateMute", false)
            self.checkMute()
            self.$root.$emit('bv::hide::tooltip') */
          });

          videoElement.addEventListener("pause", function() {
            setTimeout(function() {
              videoElement.play();
            }, 10);
          });
    
          /* if (self.canHost) {
            videoElement.addEventListener("click", function() {
              if (
                confirm(
                  `Are you sure you want to remove ${streamInfo.screenName} from streaming?`
                )
              ) {
                self.$socket.emit(
                  "banFFUser",
                  self.groupID,
                  streamInfo.screenName
                );
              }
            });
          } */

          if (!self.isStreaming) {
            videoElement.muted = true;
          }

          if (self.$route.query.kiosk) {
            setTimeout(() => {
              videoElement.muted = false
            }, 3000);
          }

          /* if (self.platformType === "mobile") {
            // videoElement.muted = self.shouldMute;
            /* self.$store.commit("updateMute", true);

            videoElement.muted = true; */
            /* videoElement.onclick = function() {
              videoElement.muted = self.shouldMute;
            }
          } else if (!self.isStreaming) {
            videoElement.muted = true;
          }
          else {
            // videoElement.muted = self.shouldMute;
          } */

          /* if (app.getUrlParameter("multipleQualities")) {
            videoElement.classList.add(streamInfo.quality);
          } */

          var subscribeOptions = {
            videoElement: videoElement,
            monitor: { 
              monitorFrameRate: false,
              monitorBitRate: false,
              // monitorState: false,
              // monitoringInterval: 10000,
              frameRateThreshold: 1,
              conditionCountForNotificationThreshold: 30,
              callback: onMonitorEvent 
            }
          };
          var handleSubscribe = function(error, response) {
            if (!response || !response.mediaStream) {
              setUserMessage('Stream subscription failed! Removing empty video div...'+JSON.stringify(response))
              self.$store.commit("removeFanFestVideo", sessionId);
              return;
            }

            // Make sure we don't end up with 2 streams due to auto recovery
            var removed = removeMemberStream(memberStream, sessionId);

            memberSubscriptions[sessionId].push({
              mediaStream: response.mediaStream,
              videoElement: videoElement,
              isSelf: isSelf,
              memberStream: memberStream,
              screenName: streamInfo.screenName
            });

            // setUserMessage("streamInfo" + JSON.stringify(streamInfo));
            //var divToUpdate;

            // let isHost = streamInfo.isHost;
            // if (isHost == true) {
            //   /* divToUpdate = document.getElementById("fanfestHost");
            //   videoElement.classList.add("embed-responsive-item"); */

            //   self.$store.commit("hasHost", true);
            // }

            //divToUpdate = self.$props.videoList[0]; // All streamers now go in same place
            // videoElement.classList.add("d-none"); // Hide initially until video can be properly formatted

            //divToUpdate.append(videoElement);
            
             if (self.isStreaming) {
              // self.$root.$emit('bv::show::tooltip', sessionId)
              setTimeout(() => {
                // self.$root.$emit('bv::hide::tooltip', sessionId)
              }, 10000);
             }
               
             /* setTimeout(() => {
              if (self.isStreaming)
                self.checkMute()  
            }, 5000); */
            

            if (removed) {
              setUserMessage(
                "Replaced member subscription for session ID [" +
                  sessionId +
                  "]"
              );
            }
            //else {
              self.checkStreams()
            //}

            self.isSubscribing = false;

            
            //setUserMessage('subscribe end')
          };

          roomExpress.subscribeToMemberStream(
            memberStream,
            subscribeOptions,
            handleSubscribe
          );
        }, 1000);
      }

      function setUserMessage(message) {
        self.log(message);
      }

      // This will get called when the stream fails to recover from an issue
      function onMonitorEvent(error, response) {
        setUserMessage(`onMonitor response: ${JSON.stringify(response)}`);

        for (const memberKey in memberSubscriptions) {
          for (const memberData of memberSubscriptions[memberKey]) {
            setUserMessage(memberData);
          }
        }
        if (error) {
          self.$gtag.event('viewer_error', {
            'event_category': 'error',
          })
          Amplitude.getInstance().logEvent('Viewer Error')

          setUserMessage(`onMonitor error ${error.message}`); // May want to display something indicating failure for member
          // self.showConnectionError = true
          return; // May want to display something indicating failure for member
        }

        if (response.status !== "ok") {
          self.$gtag.event('viewer_error', {
            'event_category': 'error',
          })
          Amplitude.getInstance().logEvent('Viewer Error')

          setUserMessage(
            `Member stream subscription failed ${response.status}`
          ); // May want to display something indicating failure for member
          // self.showConnectionError = true
        }

        // You may have the option to automatically retry
        if (response.retry) {
          self.$gtag.event('viewer_error', {
            'event_category': 'error',
          })
          Amplitude.getInstance().logEvent('Viewer Error')

          setUserMessage(
            "Attempting to redo member stream subscription after failure"
          ); // May want to display something indicating failure for member
          // self.showConnectionError = true

          response.retry();
        }
        else if (response.status == "stream-ended") {
          setUserMessage(
            `Member stream subscription ended ${response.status}`
          ); // May want to display something indicating failure for member
          // self.showConnectionError = true
          
          if (!self.isSubscribing)
            self.checkStreams()
        }
      }

      /* function publishScreen() {
        hideElement(publishScreenShareButton);
        displayElement(stopScreenShareButton);

        var roomAlias = $('#alias').val();
        var videoElement = createVideo();
        var publishOptions = {
          capabilities: ['fhd', 'prefer-h264'], // Add other capabilities if you like. Prefer-h264 allows publishing/viewing on Safari/IOS 11
          room: {
            alias: roomAlias,
            name: roomAlias,
            type: 'MultiPartyChat'
          }, // Set alias so that you can always uniquely identify room without accessing the return value
          screenName: screenName,
          streamType: 'Presentation', // Distinguish from normal publisher
          memberRole: memberRole,
          videoElement: videoElement,
          monitor: { callback: onMonitorEvent }
        };

        videoElement.setAttribute('muted', true); // Don't want to hear yourself

        return roomExpress.publishScreenToRoom(publishOptions, function (error, response) {
          if (error) {
            setUserMessage('Unable to publish to Room: ' + error.message);

            throw error;
          }

          if (response.status !== 'ok' && response.status !== 'ended') {
            setUserMessage('New Status: ' + response.status);

            throw new Error(response.status);
          }

          if (response.status === 'ok') {
            screenPublisher = {
              publisher: response.publisher,
              videoElement: videoElement
            };

            selfVideoList.append(videoElement);
          }
        });
      } */

      /* function stopPublishScreen() {
        displayElement(publishScreenShareButton);
        hideElement(stopScreenShareButton);

        if (screenPublisher) {
          screenPublisher.publisher.stop();
          screenPublisher.videoElement.remove();

          screenPublisher = null;
        }
      } */

      if (self.canStream) {
        // self.$refs.startPublishButton.onclick = self.stream;
        // self.$refs.stopPublishButton.onclick = self.leaveRoomAndStopPublisher;
      }

      /* publishAndJoinRoomButton.onclick = publishVideoAndCameraAtTwoQualitiesAndJoinRoom;
      stopButton.onclick = leaveRoomAndStopPublisher;
      publishScreenShareButton.onclick = publishScreen;
      stopScreenShareButton.onclick = stopPublishScreen; */

      /* app.setOnReset(function () {
        createRoomExpress();
      }); */
      if (!roomExpress) createRoomExpress();
      else createRoom();
    },
    stream: function() {
      const self = this;

      if (self.isPublishing) return; // already streaming

      if (self.$route.query.kiosk) {
        self.checkMute();
        self.isStreaming = true;
        return; // kiosk mode
      } 

      if (!self.canStream && self.stagingLink) { // chat only / townhall mode
        self.checkMute();
        self.isStreaming = true;
        self.$gtag.event('entered_chat', {
          'event_category': 'growth',
        })
        Amplitude.getInstance().logEvent('Entered Chat')
        return; 
      }

      if (self.displayedStreams.length >= maxStreamers) {
        alert(`Only ${maxStreamers} people can stream at the same time!\nBut you can still chat :)`);
        console.error(`Error: Up to ${maxStreamers} people can stream at the same time!`);
        return;
      }

      self.isPublishing = true;
      self.log("Attempting to start publishing...");
      
      if (self.$refs.startPublishButton) self.$refs.startPublishButton.disabled = true;


      if (videoSources.length === 0 || audioSources.length === 0) {
        // If you remoove this return it should work perfectly on chrome.
        // return self.log("Sources not available yet");
        self.log("Sources not available yet");
      }

      /* hideElement(publishAndJoinRoomButton);
        displayElement(stopButton); */

      // let videoConfig =
      //   isMobileAppleDevice || isOtherMobile
      //     ? { facingMode: { exact: "user" } }
      //     : true;

      var roomAlias = self.roomForTeam;

      if (publisher) {
        publisher.publisher.stop()
      }
      else {
        self.$store.commit("createFanFestVideo", {
          sessionId: "my-host-video",
          registration: self.uniqueScreenName,
          isVIP: self.isVIP,
          screenName: 'me',
          isAudioOnly: self.didSelectMicOnly
        });
      }

      setTimeout(() => {
        self.shouldStreamAudio = self.platformType === 'mobile' ? false : true;
        if (self.forceMic == 1) self.shouldStreamAudio = true        
        self.log(`streaming mic? ${self.shouldStreamAudio}`)
        
        var videoElement = document.getElementById("my-host-video");
        videoElement.muted = true; // Don't want to hear yourself
        videoElement.setAttribute("u", "me");
        // videoElement.classList.add("d-none");

        //videoElement.classList.add("embed-responsive-item");
        var cameraSelection = self.didSelectUserCam ? "user" : "environment";
        self.log(`desired facing mode: ${cameraSelection}`)

        var publishOptions = {
          capabilities: [
            // "prefer-h264",
            // 'multi-bitrate',
            "vld",
            // "low-latency",
            // "streaming",
            "on-demand"
          ], // Add other capabilities if you like. Prefer-h264 allows publishing/viewing on Safari/IOS 11
          room: {
            alias: roomAlias,
            name: roomAlias,
            type: "MultiPartyChat"
          }, // Set alias so that you can always uniquely identify room without accessing the return value
          mediaConstraints: {
            //audio: { deviceId: audioSources[0].id },
            //video: { deviceId: videoSources[0].id }
            video: self.didSelectMicOnly ? false : { facingMode: { ideal: cameraSelection }}, // Camera request setup

            // [TODO] This needs to be tested live again.
            // video: videoConfig,
            // video: true,
            audio: true // Mic request setup
          }, // Use the same deviceIds for both
          screenName: `${self.uniqueScreenName}`,
          streamType: "User",
          memberRole: "Participant",
          frameRate: 25,
          // aspectRatio: "16x9",
          resolution: 480,
          streamInfo: {
            screenName: `${self.fanfestScreenname}`,
            uniqueName: `${self.uniqueScreenName}`,
            isHost: self.canHost ? 1 : 0,
            isVIP: self.isVIP ? 1 : 0,
            isAudioOnly: self.didSelectMicOnly ? 1 : 0
          },
          videoElement: videoElement,
          monitor: {
            options: {
              monitorBitRate: false,
              monitorFrameRate: false
            },
            callback: function () {}
          }
        };

        /* var highQualityOptions = Object.assign({}, publishOptions, {
            videoElement: videoElement, // Bind local user media to this element (no delay)
            resolution: 720,
            frameRate: 15,
            streamInfo: { quality: "high" } // Pass custom info here
          });
          var lowQualityOptions = Object.assign({}, publishOptions, {
            resolution: 180,
            frameRate: 15,
            streamInfo: { quality: "low" } // Pass custom info here
          }); */

        var publishAndHandleErrors = function(options, callback) {
          // log device info
          navigator.mediaDevices.enumerateDevices().then(function(devices) {
            devices.forEach(function(device) {
              self.log(
                device.kind + ": " + device.label + " id = " + device.deviceId
              );
            });
          });
          //let supported = navigator.mediaDevices.getSupportedConstraints();
          //self.log(JSON.stringify(supported));

          // publish
          return roomExpress.publishToRoom(options, function(error, response) {
            if (error) {
              self.log("Unable to publish to Room: " + error.message);
              self.log("Time to reload?");
              alert(
                `Error going live in FanFest: ${error.message}. Reloading...`
              );
              window.location.reload();
              throw error;
            }

            if (response.status !== "ok" && response.status !== "ended") {
              self.log("New Status: " + response.status);

              self.$gtag.event('publisher_error', {
                'event_category': 'error',
              })
              Amplitude.getInstance().logEvent('Publisher Error')

              throw new Error(response.status);              
            }

            if (response.status === "ok") {
              self.log("Online & Publishing");

              self.isStreamingVideo = true;
              self.isStreamingAudio = self.platformType != 'mobile' ;

              self.$gtag.event('entered_stage', {
                'event_category': 'growth',
              })
              Amplitude.getInstance().logEvent('Entered Stage')

              axios
                .post(`${process.env.VUE_APP_BACKEND}/app/register/login`, {
                  registration: self.$store.state.fanfest.registration,
                  streamers: self.displayedStreams.length,
                  shareLink: self.shareLink
                })
                .catch((err) => Promise.reject(err.message));
                    callback(response);
            }
          });
        };

        return publishAndHandleErrors(publishOptions, function(response) {
          publisher = {
            publisher: response.publisher,
            videoElement: videoElement
          };

          //var divToUpdate;

          let isHost = self.canHost;
          if (isHost == true) {
            /* divToUpdate = document.getElementById("fanfestHost");
            videoElement.classList.add("embed-responsive-item"); */

            // self.$store.commit("hasHost", true);
          }

          //divToUpdate = self.$props.videoList[0]; // All streamers now go in same place
          // videoElement.classList.add("d-none");// Hide initially until video can be properly formatted

          //divToUpdate.append(videoElement);
          // setTimeout(() => {}, 1000);

          self.checkMute();

          if (!roomService) {
            roomService = response.roomService;
          }
          // self.$refs.startPublishButton.onclick = self.leaveRoomAndStopPublisher;
          
          /* if (self.platformType != 'mobile' && !self.isStreaming) publisher.publisher.disableAudio() // default mute for first run on non-mobile (where video and audio are requested) */
          
          self.isStreaming = true;
          self.$emit('isStreaming', self.isStreaming)
          self.isPublishing = false;

          setTimeout(() => {
            // videoElement.classList.remove("d-none");
            //self.$refs.startPublishButton.disabled = false;
            // self.checkStreams();
          }, 1000);

          // joinRoom();

          /* if (app.getUrlParameter("multipleQualities")) {
              publishAndHandleErrors(lowQualityOptions, function(response) {
                lowQualityPublisher = { publisher: response.publisher };
              });
            } */
        });
      }, 100);
    },
    leaveRoomAndStopPublisher: function leaveRoomAndStopPublisher() {
      const self = this;

      if (publisher && self.$store.state.fanfest.banned) {
        // kill banned hosts immediately
        publisher.publisher.stop();
        publisher.videoElement.remove();
        publisher = null;
      } else if (publisher) {
        // give normal hosts chance to exit exit flow
        /* if (
          !confirm(
            "Are you sure you want to stop FanFest streaming? This page will reload."
          )
        ) {
          self.$refs.startPublishButton.disabled = false;
          return;
        } */
        publisher.publisher.stop();
        publisher.videoElement.remove();
        publisher = null;
      }

      if (self.$refs.startPublishButton)
        self.$refs.startPublishButton.disabled = true;

      /* if (lowQualityPublisher) {
        lowQualityPublisher.publisher.stop();

        lowQualityPublisher = null;
      } */

      /* if (screenPublisher) {
        screenPublisher.publisher.stop();
        screenPublisher.videoElement.remove();

        screenPublisher = null;
      } */

      if (roomService) {
        roomService.leaveRoom(function(error, response) {
          roomService = null;

          self.$store.commit("logout");

          if (error) {
            throw error;
          }

          if (response.status !== "ok") {
            throw new Error(response.status);
          }

          self.isStreaming = false;

          //self.init();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }

      if (sendMessageIntervalId) {
        clearInterval(sendMessageIntervalId);

        sendMessageIntervalId = null;
      }

      setTimeout(() => {
        if (self.$refs.startPublishButton)
          self.$refs.startPublishButton.disabled = false;
      }, 2500);

      /* hideElement(stopButton);
      displayElement(publishAndJoinRoomButton); */
    },
    /* createVideo: function createVideo() {
      const self = this;
      var videoElement = document.createElement("video");

      videoElement.setAttribute("playsinline", ""); // For Safari and IOS
      videoElement.setAttribute("autoplay", ""); // For Safari and IOS + Mobile
      videoElement.muted = self.shouldMute;

      // To resolve unintended pauses
      videoElement.onpause = function() {
        setTimeout(function() {
          videoElement.play();
        }, 10);
      };

      return videoElement;
    }, */
    checkMute: function() {
      // now is only run once, after we start streaming

      const self = this;
        // self.log(`subscriptions: ${(JSON.stringify(memberSubscriptions))}`)
        self.log(`checking mute!`)
      for (const memberKey in memberSubscriptions) {
        
        self.log(memberKey)
        for (const memberData of memberSubscriptions[memberKey]) {
          let video = memberData.videoElement;
          let isSelf = memberData.isSelf;
          // video.muted = self.shouldMute;
          // video.volume = self.shouldMute ? 0.5 : 1.0
          // if (isSelf) video.muted = true; // always mute self
          
          video.muted = isSelf ? true : false;
          
          //self.log(memberData)
          //self.log(video)
        }
      }

      // self.checkStreams();
    },
    checkStreams: function() {
      const self = this;
      const recentlyCreatedVideo = 15 // in seconds
      
      if (!self.isStreaming)
        return

      //setTimeout(() => {
        if (self.isSubscribing || self.isPublishing)
          return

        self.log('checking for dead streams...')        
        for (const stream of self.displayedStreams) {
          // self.log(`stream: ${memberKey}`)
          // check for dead video objects
            let video = document.getElementById(stream.sessionId);
            
            if (!video) continue
            if (video.getAttribute('id') === 'my-host-video') continue
            
            self.log(`checking video for stream ${JSON.stringify(stream)}`)

            if (video.srcObject == null) {
              let creationTime = parseInt(video.getAttribute('createdat'))

              // if video has no src, but is new, could be retrying, so wait again and if still empty, kill
              if (new Date().getTime() - creationTime < 1000 * recentlyCreatedVideo) {
                setTimeout(() => {
                  let video = document.getElementById(stream.sessionId);
                  if (!video) return;                  
                  self.log(`checking video box ${stream.sessionId} after delay...`)
                  if (video.srcObject == null) {
                    self.log(`...deleting video box ${stream.sessionId} after delay`)
                    self.$store.commit("removeFanFestVideo", stream.sessionId);    
                    
                    self.$gtag.event('removed_dead_streamer', {
                      'event_category': 'error',
                    })
                    Amplitude.getInstance().logEvent('Removed Dead Streamer')
                  }
                }, recentlyCreatedVideo * 1000);
                
                continue;
              }

              self.log(`killing dead stream ${stream.sessionId}`)
              self.$store.commit("removeFanFestVideo", stream.sessionId);
              
              self.$gtag.event('removed_dead_streamer', {
                'event_category': 'error',
              })
              Amplitude.getInstance().logEvent('Removed Dead Streamer')
            }
        }   
      // }, 0);
    },
    tappedStreamButton: function(camera) {
      const self = this;
      
      if (!self.canHost && !self.isVIP && !this.$route.query.kiosk)
        UserLeap('track', 'Entered-FanFest-Code-Trigger');
      
      self.$refs.startPublishButton.disabled = true;
      self.$bvModal.hide('modal-welcome')
      // self.$store.commit("updateMute", false);

      /* View in fullscreen */
      // if (self.isEmbed){
      //   var elem = document.documentElement;
      //   if (elem.requestFullscreen) {
      //     elem.requestFullscreen();
      //   } else if (elem.webkitRequestFullscreen) { /* Safari */
      //     elem.webkitRequestFullscreen();
      //   } else if (elem.msRequestFullscreen) { /* IE11 */
      //     elem.msRequestFullscreen();
      //   }        
      // }
      
      if (camera === 'environment') self.didSelectUserCam = false
      if (camera === 'mic') self.didSelectMicOnly = true


      if (!this.isStreaming) this.stream();
      else this.leaveRoomAndStopPublisher();
    },
    /* tappedVideoToggle: function() {
      this.isStreamingVideo = !this.isStreamingVideo;
      if (this.isStreamingVideo) publisher.publisher.enableVideo();
      else publisher.publisher.disableVideo();
    }, */
    /* tappedAudioToggle: function() {
      this.isStreamingAudio = !this.isStreamingAudio;
      if (this.isStreamingAudio) publisher.publisher.enableAudio();
      else publisher.publisher.disableAudio();
    }, */
    log: function(message) {
      console.log("[vici]: " + message);
    }
  },
  render() {
    // Render the default scoped slot and
    // provide data and method properties
    // via the slot scope.
    return this.$scopedSlots;
  }
};
</script>