<template lang="pug">
.container-float.p-3
  b-form
    b-form-input.my-3.w-100(v-model="tweetURL", trim, autocomplete="none" placeholder='Enter URL of tweet with video')
  
  .text-danger(v-if='error') error loading tweet video
  .text-center(v-else)
    video.w-75.h-75(ref="video" playsinline :muted="muted" autoplay loop controls)
  
</template>


<script>
import { mapState } from "vuex";

import axios from "axios"
import urlparse from "url-parse"

export default {
  name: "Twitter",
  data() {
    return {
      tweetURL: undefined,
      hls: undefined,
      muted: true,
      error: false
    };
  },
  mounted: function() {},
  computed: {
    ...mapState({
      //groupID: state => state.client.group.objectId
    }),
    ...mapState({
      //canHost: state => state.fanfest.canHost,
    })
  },
  watch: {
    tweetURL: function() {
      if (this.tweetURL != undefined) {
        this.fetch()
      }
    }
  },
  sockets: {},
  methods: {
    fetch: async function() {
      if (!this.tweetURL) return

      let urlObject = urlparse(this.tweetURL)
      let tweetID = urlObject.pathname.split('/').pop().trim()
      
      let request = `${process.env.VUE_APP_BACKEND}/app/demo/twitter/${tweetID}`
      let response = await axios.get(request)
      let tweetData = response.data
      this.process(tweetData)
    },
    process: function(tweetData) {
      console.log(tweetData.extended_entities.media[0].video_info.variants)
      if (!tweetData.extended_entities) {
        this.error = true
        return
      }

      let mediaData = tweetData.extended_entities.media[0].video_info
      if (!mediaData) return

      let variants = mediaData.variants
      for(var v of variants) {
        console.log(v)
        if (v.content_type == 'application/x-mpegURL') {
          console.log(v.url)
          this.play(v.url)
          return
        }
      }
    },
    play: function(url) {
      if (window.Hls.isSupported()) {
        if (this.hls)  {
          this.hls.destroy()
          this.$refs.video.src = ''
        }

        this.hls = new window.Hls
        this.hls.loadSource(url);
        this.hls.attachMedia(this.$refs.video);        

        this.hls.on(window.Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            switch(data.type) {
            case window.Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
              console.log("fatal network error encountered, try to recover");
              this.hls.startLoad();
              break;
            case window.Hls.ErrorTypes.MEDIA_ERROR:
              console.log("fatal media error encountered, try to recover");
              this.hls.recoverMediaError();
              break;
            default:
            // cannot recover
              this.hls.destroy();
              break;
            }
          }
        });
      } else {
        this.$refs.video.src = url;
        this.$refs.video.addEventListener("loadedmetadata", function() {
          this.$refs.video.play();          
        });
      }
    }
  }
};
</script>


<style scoped>
</style>