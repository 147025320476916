<template lang="pug">
b-modal(visible centered @hide="hide")
    .container-float
        .m-3.p-3(v-if='isLoading')
          .text-center.font-weight-light Loading...
        .m-3.p-3(v-else)
          .text-center.font-weight-bold.text-uppercase Moderate
          .text-center.font-weight-bold {{ registration.fan.name }}
    template(v-if="!isLoading" v-slot:modal-footer)
        b-button.bg-secondary.mx-auto.d-block.text-uppercase.font-weight-bold(v-if="registration.canStream" ref='buttonBan' @click='demoteMember' size='lg' :disabled='isLoading' v-b-tooltip.hover title="Return To Chat")
          i.fa.fa-2x.text-light.fa-comment
        b-button.bg-secondary.mx-auto.d-block.text-uppercase.font-weight-bold.px-3(v-else ref='buttonBan' @click='promoteMember' size='lg' :disabled='isLoading' v-b-tooltip.hover title="Bring Onstage")
          i.fa.fa-2x.text-light.fa-video
        //- b-button.bg-secondary.mx-auto.d-block.text-uppercase.font-weight-bold.px-3(v-if="!registration.canStream" ref='buttonBan' @click='promoteVIP' size='lg' :disabled='isLoading' v-b-tooltip.hover title="Bring Onstage As VIP") 
          i.fa.fa-2x.text-light.fa-grin-stars
        //- b-button.bg-secondary.mx-auto.d-block.text-uppercase.font-weight-bold.px-3(ref='buttonBan' @click='goBackstage' size='lg' :disabled='isLoading' v-b-tooltip.hover title="Invite Backstage")
          i.fa.fa-2x.text-light.fa-person-booth
        b-button.bg-primary.mx-auto.d-block.text-uppercase.font-weight-bold.px-3(ref='buttonBan' @click='bootMember' size='lg' :disabled='isLoading' v-b-tooltip.hover title="Remove User")
          i.fa.fa-2x.text-light.fa-pause
        b-button.bg-danger.mx-auto.d-block.text-uppercase.font-weight-bold.px-3(ref='buttonBan' @click='banMember' size='lg' :disabled='isLoading' v-b-tooltip.hover title="Ban User")
          i.fa.fa-2x.text-light.fa-ban
</template>


<script>
import { mapState } from "vuex";

import axios from "axios";
import Parse from "../services/parse";
import Amplitude from "../services/amplitude";

export default {
  name: "ModeratorBanUser",
  data() {
    return {
        isLoading: true,
        registration: undefined        
    };
  },
  props: {
    registrationID: String,
  },
  mounted: async function() {
    let query = new Parse.Query("Registration")
    query.equalTo('objectId', this.registrationID)
    query.include('fan')

    let result = await query.first()
    this.registration = result.toJSON()
    this.isLoading = false
  },
  computed: {
    ...mapState({
      eventRoom: state => state.fanfest.roomForContent,
      hostID: state => state.fanfest.registration.objectId,
    }),
  },
  sockets: {},
  methods: {
    banMember: function() {
      this.$gtag.event('moderate_fan', {
          'event_category': 'moderation',
        })
      Amplitude.getInstance().logEvent('Fan Moderated')

      this.$socket.emit("banFFUser", this.eventRoom, this.registrationID);
      this.hide()
    },
    bootMember: function() {
      this.$socket.emit("bootFFUser", this.eventRoom, this.registrationID);
      this.hide()
    },
    hideMember: function() {
      this.$socket.emit("hideFFUser", this.eventRoom, this.registrationID);
      this.hide()
    },
    goBackstage: async function() {
      this.$socket.emit("goBackstage", this.eventRoom, this.registrationID);
      await this.hide()

      let confirmation = await this.$bvModal.msgBoxConfirm(`Join user backstage?`, {
        title: 'Go Backstage',
        size: 'sm',
        centered: true
      })

      if (!confirmation) return

      // window.location.assign(`${window.location.href}&staging=1`)
      window.open(`${window.location.href}&staging=1`, '_blank')
    },
    promoteMember: async function() {
      let privileges = {
        canStream: true
      }
      let data = {
        host: this.hostID,
        registration: this.registrationID,
        privileges: privileges
      }
      console.log(data)
      let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/register/update`, data).catch(err => Promise.reject(err.message));
      console.log(response)

      this.$gtag.event('invited_on_stage', {
          'event_category': 'moderation',
        })
      Amplitude.getInstance().logEvent('Invited On Stage')

      this.$socket.emit("hideFFUser", this.eventRoom, this.registrationID);

      this.hide()
    },
    promoteVIP: async function() {
      let privileges = {
        canStream: true,
        isVIP: true,
      }
      let data = {
        host: this.hostID,
        registration: this.registrationID,
        privileges: privileges
      }
      console.log(data)
      let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/register/update`, data).catch(err => Promise.reject(err.message));
      console.log(response)

      this.$gtag.event('invited_on_stage_VIP', {
          'event_category': 'moderation',
        })
      Amplitude.getInstance().logEvent('Invited On VIP Stage')

      this.$socket.emit("hideFFUser", this.eventRoom, this.registrationID);

      this.hide()
    },
    demoteMember: async function() {
      let privileges = {
        canStream: false,
        isVIP: false,
      }
      let data = {
        host: this.hostID,
        registration: this.registrationID,
        privileges: privileges
      }

      let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/register/update`, data).catch(err => Promise.reject(err.message));
      console.log(response) // show user feedback
        
      this.$socket.emit("hideFFUser", this.eventRoom, this.registrationID);

      this.hide()
    },
    hide: function() {
        this.$emit('hide')
        console.log('hiding...')
    }
  }
};
</script>


<style scoped>
</style>