<template lang="pug">
  .d-flex.justify-content-center
    b-button.clock.border-0.text-uppercase.text-center.animated.fadeIn.delay-2s(v-show="!shouldHide && !isExpired" disabled) Next Show In {{ timer }}
</template>


<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";

//import axios from "axios";

export default {
  name: "Clock",
  data() {
    return {
      timer: undefined,
      shouldHide: false,
      isExpired: false
    };
  },
  mounted: function() {
    this.config()
  },
  props: {
    totalStreamers: Number
  },
  computed: {
    ...mapState({
      isBackstage: state => state.client.isBackstage,
      event: state => state.fanfest.event,
    })
    
  },
  watch: {
    totalStreamers: function() {
      this.shouldHide = this.totalStreamers > 0 ? true : false
    }
  },
  sockets: {},
  methods: {
    config: function() {       
      if (this.event.startsAt === undefined) {
        this.shouldHide = true
        return
      }

      // Set the date we're counting down to
      var countDownDate = DateTime.fromISO(this.event.startsAt.iso).toJSDate()

      // Update the count down every 1 second
      var x = setInterval(() => {
        // Get today's date and time
        var now = new Date().getTime();
          
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
          
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          
        // Output the result in an element with id="demo"
        this.timer = days + "d " + hours + "h "
        + minutes + "m " + seconds + "s ";
        this.timer.trim()

        // if (this.shouldHide) this.shouldHide = false
          
        // If the count down is over, write some text 
        if (distance < 0) {
          clearInterval(x);
          this.timer = "";
          this.shouldHide = true;
          this.isExpired = true;
        }
      }, 1000);
    }
  }
};
</script>


<style scoped>
.clock {
  height: 50px;
  position: absolute;
  top: 10%;
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 110%;
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 325px; /* Need a specific value to work */
}
</style>