<template lang="pug">
.buff-overlay
</template>


<script>
import { mapState, mapMutations } from "vuex";

import axios from "axios";
// import { v4 as uuidv4 } from 'uuid'

export default {
  name: "SportBuff",
  data() {
    return {};
  },
  mounted: function () {
    // if (this.canHost) return
    setTimeout(() => {
      this.config()
    }, 1000);
  },
  computed: {
    ...mapState({
      profile: state => state.fanfest.config.name,
      canHost: state => state.fanfest.canHost,
      screenName: state => state.fanfest.screenName,
      sessionID: state => state.client.sessionID,
    }),
  },
  watch: {},
  sockets: {},
  methods: {
    ...mapMutations([""]),
    config: async function() {
      const stringToUuid = (str) => {
        str = str.replace('-', '');
        return 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function(c, p) {
          return str[p % str.length];
        });
      }
      var input  = this.$route.query.r;
      var output = stringToUuid(input);
      console.log('SportBuff Input ID: ' + input);
      console.log('SportBuff Output UUID: ' + output);

      let response = await axios.post('https://sdk.prod.buffup.net/api/v1/auth/client', {"key":"97dc9a46-1ab6-4034-ae45-757dc62b9e8f", "secret":"*Z#7hRGW%Gc68mrYV7zu3MnB"})
      let sdkToken = response.data.token
      // console.log(response)
      // console.log(uuidv4())
      let response2 = await axios.post('https://sdk.prod.buffup.net/api/v1/auth/uuid', {"uuid": output, "username": this.screenName}, { headers: {
          'Authorization': `Bearer ${sdkToken}`
      }})
      let userToken = response2.data.token

      var streamId = 0
      if (this.profile === 'realvalladolid') streamId = 138
      else if (this.profile === 'sefutbol') streamId = 433
      else if (this.profile === 'finetwork') streamId = 161
      else if (this.profile === 'rfef') streamId = 179
      else if (this.profile === 'rfef21') streamId = 194
      else if (this.profile === 'psg') streamId = 1134
      else if (this.profile === 'ol') streamId = 305
      else if (this.profile === 'realbetisbalompie') streamId = 156
      else if (this.profile === 'rcdespanyol') streamId = 309
      else if (this.profile === 'rccelta') streamId = 468
      else if (this.profile === 'bancosantander') streamId = 512
      else if (this.profile === 'villareal') streamId = 585
      else if (this.profile === 'mancity') streamId = 783
      else if (this.profile === 'jjpptokio2020') streamId = 682
      else if (this.profile === 'lavuelta') streamId = 681
      else if (this.profile === 'seur') streamId = 433
      else if (this.profile === 'nacional') streamId = 1081
      else if (this.profile === 'supercopa') streamId = 1156
      else if (this.profile === 'marcabstadium') streamId = 1229
      else if (this.profile === 'ucatolica') streamId = 1232
      else if (this.profile === 'msf') streamId = 1233

      if (streamId === 0) return

      this.$store.commit('enablePolls')

      // To avoid duplications, any previous instance created is destroyed
      if (window.SBwidget) {
        window.SBwidget.destroy();
        window.SBwidget = null;
      }

      window.SBwidget = await window.SportBuff.init({
        videoContainerQuerySelector: '#buffoverlaywrapper',  // !Required if player is not jwplayer: string
        token: userToken,        // !Required: string
        // player: 'default',              // !Optional: string 'default' | 'jwplayer' | 'youtube'
        streamId: streamId,                 // !Required if streamTitle or providerId are not present: number
        // streamTitle: 'Title'           // !Required if streamId is not present: string
        providerId: 102938485748392,           // !Required if streamTitle is not present: number
        addFullScreenButton: false      // !Optional
      })


      /* window.BuffVideo({
        overlayQuerySelector: "#buffoverlaywrapper",
        token: userToken,
        streamId: streamId,
        force: true
        // pluginType: 'twitch',
      //   customization: {
      //     webController: true,
      //     autoHideMenu: true,
      // }
      }) */
    }
  },
};
</script>


<style scoped>
</style>