<template lang="pug">
  //.container-float
    form(ref='payment-form')
      .form-row.h-100.w-100    
  .container-float
    img#logo.mx-auto.d-block(:src='this.groupLogo'  style="height:10vh")
    .text-uppercase.text-dark.text-center.mt-2(style="font-family: 'Bungee Inline', cursive; font-size:3vh") Fanfest Premium
    .m-3.p-3
      .text-center.text-muted.mb-4 Access premium live events for {{ this.groupName  }} fans
      div(ref='card')
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OfferCheckout",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      groupLogo: state => state.client.group.image,
      groupName: state => state.client.group.name
    })
  },
  props: {},
  watch: {},
  mounted: async function() {
     // Create a Stripe client.
    //var stripe = Stripe("pk_test_FVwhVpLF5dZO1R1z1ICw6YR6");
    const self = this;
    const stripe = window.Stripe('pk_test_FVwhVpLF5dZO1R1z1ICw6YR6');

    // Create an instance of Elements.
    var elements = stripe.elements();
    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    // Create an instance of the card Element.
    var card = elements.create("card", { style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount(self.$refs.card);

    // Handle real-time validation errors from the card Element.
    /* card.addEventListener("change", function(event) {
      /* var displayError = self.$refs.carderrors;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      } 
    }); */
  },
  methods: {
  }
};
</script>

<style scoped>
.StripeElement {

  height: 100%;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>