const state = {
  contentType: "vod",
  hls: undefined,
  iframeURL: "",
  customVODURL: "",
  tweetID: "",
  // listVOD: [],
  didHostHide: undefined,
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  /* configHostLibrary(state, listVOD) {
    state.listVOD = listVOD
  }, */
  updateHostContent(state, contentType) {
    state.contentType = contentType
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}