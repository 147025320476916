<template lang="pug">
  .container-float.bg
    .row
    .row.align-items-center.h-100
      .col.align-self-center.h-100
        #vici_video_container.embed-responsive.embed-responsive-16by9.w-100
          video.embed-responsive-item(ref='video' autoplay preload=auto controls loop)
    .row
</template>

<script>
import axios from "axios";
// import Parse from "@/services/parse.js";
import Hls from "hls.js";

export default {
  name: "dashboard",
  metaInfo() {
    return {
      title: "VICI TV Dashboard"
    };
  },
  methods: {
    previewVOD: function() {
      var video = this.$refs.video;

      axios
        .get(`${process.env.VUE_APP_BACKEND}/app/dashboard/video/${this.$route.params.vod}`)
        .then(response => {
          const url = response.data;

          if (Hls.isSupported()) {
            var hls = new Hls();
            hls.loadSource(url);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, function() {
              // video.play();
            });
          }
          // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
          // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
          // This is using the built-in support of the plain video element, without using hls.js.
          // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
          // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
          else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = url;
            video.addEventListener("loadedmetadata", function() {
              // video.play();
            });
          }
          
        })
        .catch((err) => {
          alert(err)
          /*
          Parse.User.logOut().then(() => {
            this.$router.push({ name: "login" });
          });*/
        });
    }
  },
  mounted: function() {
    this.previewVOD();
  }
}
  
</script>

<style>
.bg {
  height: 100vh;
  background-color: black;
}
</style>
 