<template lang="pug">
  .bg
    .container-fluid.bg-white(style='max-width:800px')
        .container-fluid(height=100)
            .row.h-100
                .col-3.align-self-center.p-0                                      
                      i.fa.fa-video-camera.fa-x.text-light
                .col-6
                    a(href='https://watch.vici.cloud/caur1UpODM' target='_blank')
                      img#logo.mx-auto.my-3.d-block(:src='profile.teamLogo' height='100')
                .col-3.align-self-center.p-0
                    //b-button.bg-dark.border-0.text-white.text-uppercase.font-weight-bold.mx-auto.d-block(block pill)
                      i.fa.fa-times-circle.fa-1x.text-light
                    .text-left
                        button#buttonLogout.btn.btn-outline-dark.btn-sm.border-0(@click='logout' type='button') Logout
                
        form.m-4
            .form-group
                .custom-file
                    input#inputVOD.custom-file-input(@change="updateForFileSelection" ref='inputVOD' type='file', accept="video/*, .mp4, .mov" multiple)
                    label#labelVOD.custom-file-label(ref='labelVOD' for='inputVOD') Choose Video(s) To Upload
            //.form-group
                input#inputCaption.form-control(type='text' placeholder='Video Title (Optional)')
            .form-group.invisible
                select#selectAudience.custom-select(ref='selectAudience')
                  option(v-for="(item, index) in profile.audience" value=index) {{ item }}                    
            
            button#uploadVOD.btn.mx-auto.d-block.animated.infinite(@click='startUpload' ref='uploadVOD' disabled :class="{ pulse: isReadyForUpload, visible: isReadyForUpload }") 
                i.fas.fa-cloud-upload-alt.fa-4x.text-secondary(:class="[isReadyForUpload ? 'text-success' : 'text-secondary']")
            p.text-muted.text-center.text-uppercase.font-weight-light.small {{ isReadyForUpload ? 'Tap to upload' : 'no video selected'}}
            .progress.my-5
                #progressVOD.progress-bar.progress-bar-striped.progress-bar-animated.bg-success(ref='progressVOD' role="progressbar" :style="{width:`${uploadProgress}%`}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="1")
            //.mt-5.text-white spacer
            //b-button.bg-danger.border-0.text-white.text-uppercase.font-weight-bold.mt-5.mx-auto.d-block(block pill) Go Live
        
        p.text-muted.text-center.text-uppercase.font-weight-light.small.mt-5 Recent Uploads        
        #vodList.table-responsive
            table.table.table-striped
                tbody
                    tr(v-for="v,i in this.vodList")
                        td.align-middle 
                            // a(v-if="v.active && v.approved" :href="`${v.url}`" target='_blank').font-weight-bold.text-dark.text-underline.text-uppercase {{ v.name }}
                            router-link.font-weight-bold.text-dark.text-underline.text-uppercase(v-if="v.active && v.approved" :to="`/dashboard/preview/${v.id}`" target='_blank') {{ v.name }}
                            .text-secondary(v-else) {{ v.name }}
                            br
                            a(v-if="v.active && v.approved" :href="`${v.url}`" target='_blank').text-muted.m-0.small Uploaded {{ v.createdAt }}
                            .text-muted.m-0.small(v-else) {{ v.createdAt }}                        
                        td.align-middle
                            .text-right
                                button.btn.btn-success.btn-sm.m-0.text-uppercase(v-if="v.approved" :id="`${v.id}`" @click="toggleVOD" type='button') Active
                                button.btn.btn-sm.m-0.text-uppercase.btn-outline-success(v-else-if="v.active" :id="`${v.id}`" @click="toggleVOD" type='button') Inactive
                                button.btn.btn-sm.m-0.text-uppercase.btn-outline-secondary(v-else :id="`${v.id}`" @click="toggleVOD" type='button') Processing
    
</template>

<script>
import axios from "axios";
import Parse from "@/services/parse.js";
import _ from "underscore";
//import AWS from "aws-sdk";

const AWS = window.AWS

export default {
  name: "dashboard",
  metaInfo() {
    return {
      title: "VICI TV Dashboard"
    };
  },
  data() {
    return {
      profile: {}, // teamLogo, teamName, authorName, teamID, organizerID, userID, audience[], vod[]
      uploadedVOD: 0,
      totalVOD: 0,
      uploadProgress: 0,
      didUploadSucceed: false,
      didUploadFail: false,
      vodList: []
    };
  },
  computed: {
    isReadyForUpload: function() {
      return this.totalVOD > 0;
    }
  },
  components: {},
  methods: {
    configDashboard: function() {
      var userID = Parse.User.current().id;
      axios
        .get(`${process.env.VUE_APP_BACKEND}/app/dashboard/${userID}`)
        .then(response => {
          this.profile = response.data;
          this.vodList = this.profile.vod;
        })
        .catch(() => {
          Parse.User.logOut().then(() => {
            this.$router.push({ name: "login" });
          });
        });
    },
    updateForFileSelection: function(event) {
      this.totalVOD = event.target.files.length;
      if (this.isReadyForUpload) {
        this.$refs.uploadVOD.disabled = false;
        const self = this;
        setTimeout(() => {
          self.$refs.labelVOD.innerHTML = "Video Ready For Upload";
        }, 10);
      }
    },
    startUpload: function(event) {
      event.preventDefault();
      this.$refs.uploadVOD.disabled = true;
      this.uploadVOD();
    },
    uploadVOD: function() {
      // var totalUploadSize = 0;
      var keyTeamName = this.profile.teamName
        .replace(/ /g, "")
        .replace("-", "")
        .toLowerCase();
      const self = this;

      _.each(this.$refs.inputVOD.files, function(f) {
        var selectedMovie = f;
        if (selectedMovie === undefined) return;

        // totalUploadSize += f.size;

        const contentType = selectedMovie.type;
        const s3 = new AWS.S3({
          accessKeyId: "AKIARKWJHIZ2SZ7XN3AJ",
          secretAccessKey: "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",
          useAccelerateEndpoint: true
        });

        var selectedMovieName = selectedMovie.name;
        var selectedMovieExtension = selectedMovieName.split(".").pop();

        const params = {
          Bucket: "vici-tv-content-091690059381-us-east-1",
          Key:
            "raw/" +
            keyTeamName +
            "/team/" +
            Parse.User.current().id +
            Date.now() +
            "." +
            selectedMovieExtension,
          Body: selectedMovie,
          ContentType: contentType
        };

        s3.upload(params)
          .on("httpUploadProgress", evt => {
            console.log(evt.loaded + " / " + evt.total + " Bytes");
            self.uploadProgress = (evt.loaded / evt.total) * 100;
          })
          .send((err, data) => {
            if (err) {
              console.log(err);
              console.log(data);
              // showError();
              alert("Error uploading");
              return false;
            }

            self.notifyServer(data);
            // showSuccess();
          });
      });
    },
    notifyServer: async function(data) {
      const dataForServer = {
        vodAuthor: Parse.User.current().id,
        vodName: `Video from ${this.profile.authorName}`,
        vodAudience: this.$refs.selectAudience.options[
          this.$refs.selectAudience.options.selectedIndex
        ].text,
        vodData: data.Key
      };

      try {
        await axios.post(
          `${process.env.VUE_APP_BACKEND}/app/dashboard/video/new`,
          dataForServer
        );

        this.uploadedVOD++;
        if (this.uploadedVOD === this.totalVOD) {
          // now this happens on the backend for group/org
          // await axios.get(`${process.env.VUE_APP_BACKEND}/app/dashboard/refreshVODQueue/${this.profile.teamID}`, dataForServer);
          location.reload();
        }
      } catch (err) {
        alert(err.message);
      }
    },
    toggleVOD: async function(event) {
      let selectedVOD = event.target.id;
      try {
        let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/dashboard/video/toggle`,{vod:selectedVOD})
        let newStatus = response.data;
        this.vodList.forEach(v => {
          if (v.id === selectedVOD) {
            v.approved = newStatus }
        })
      } catch (err) {
        alert(err.message);
      }
    },
    goFanfest: function() {
      this.$router.push({ name: "fanfest" });
    },
    logout: function() {
      Parse.User.logOut().then(() => {
        this.$router.push({ name: "login" });
      });
    }
  },
  mounted() {
    loadExternalScript("https://kit.fontawesome.com/2c8f0ec015.js");

    this.configDashboard();
  }
};

function loadExternalScript(url) {
  let script = document.createElement("script");
  script.setAttribute("src", url);
  document.head.appendChild(script);
}
</script>

<style scoped>
.bg {
  height: 100vh;
  background-color: white;
}

body, html {
  background-color: white;
  min-height: 100%;
  overflow-x: hidden;
}
</style>>