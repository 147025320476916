const state = {
    visible: false,
    content: ''
  }
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    updateSidebar({ state, commit }, content) {
      if (state.content === content) {
          state.visible = false
          state.content = ''

          if (content === 'chat') 
            commit('updateChatVisibility', false)

          return
      }

      state.visible = true
      state.content = content

      if (content === 'chat') 
        commit('updateChatVisibility', true)
    },
    hideSidebar({ state, commit }) {
      state.visible = false
      state.content = ''

      commit('updateChatVisibility', false)
    }
  }
  
  // mutations
  const mutations = {
    /* updateSidebar(state, content) {
      if (state.content === content) {
          state.visible = false
          state.content = ''
          return
      }

      state.visible = true
      state.content = content
    },
    hideSidebar(state) {
      state.visible = false
      state.content = ''
    } */
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  } 