<template lang="pug">
div
  b-form.p-3
    label.font-weight-bold {{ $t('admin.offertexttext') }} 
    b-form-textarea.mt-2(v-model="title" trim autocomplete="none" :placeholder="$t('admin.enterheadlinetext')")
    b-form-textarea.mt-2(v-for="text, i in this.subtitles" v-model="subtitles[i]" :key='i' trim :placeholder="$t('admin.enterofferdetailtext')") autocomplete="none")
    b-form-textarea.mt-2(v-for="text, i in (subtitleLimit - subtitles.length) " v-model="newSubtitles[i]" :key='i+100' trim :placeholder="$t('admin.enterofferdetailtext')" autocomplete="none")

  b-form.p-3
    label.font-weight-bold  {{ $t('admin.offerlogotext') }} 
    //- b-form-input.mt-2(v-model="logo" trim autocomplete="none" placeholder='Enter Image URL')
    img.d-block.my-1(:src='logo' style="height:10vw")
    b-form-file(v-model="logoFile" accept="image/*" :placeholder="$t('admin.replacelogotext')")

  b-form.p-3
    label.font-weight-bold {{ $t('admin.offerurltext') }} 
    b-form-input.mt-2(v-model="url" trim autocomplete="none" :placeholder="$t('admin.enterofferdestinationurltext')")

  // Save
  .p-3.bg-light.d-flex.justify-content-between
    b-button.text-uppercase.font-weight-bold(variant="outline-success", @click="update") {{ saveButton }}
    b-button.text-uppercase.font-weight-bold(v-if="!isNew" variant="outline-danger", @click="disable") Delete
</template>


<script>
import Parse from "../../services/parse";
let Profile = Parse.Object.extend("FanfestConfig");
let Offer = Parse.Object.extend("Offer");

export default {
  name: "OfferEditor",
  data() {
    return {
      offer: null,
      title: '',
      subtitles: [],
      newSubtitles: [],
      subtitleLimit: 4,
      logo:'',
      logoFile: null,
      url:'',
      saveButton: 'save',
    };
  },
  props: {
    offerID: String
  },
  computed: {
    isNew: function() {
      return this.$props.offerID === "new"
    }
  },
  watch: {
    logoFile: function() {
      if (this.logoFile) this.logo = URL.createObjectURL(this.logoFile)
    }
  },
  sockets: {},
  mounted: function () {
    this.loadOffer();
  },
  methods: {
    loadOffer: async function () {
      if (this.isNew) {
        return;
      }

      let query = new Parse.Query(Offer);
      this.offer = await query.get(this.$props.offerID).catch((error) => alert(error))

      this.title = this.offer.get('title')
      let subtitleString = this.offer.get('subtitle')
      this.subtitles = subtitleString.split(', ')
      this.logo = this.offer.get('logo')
      this.url = this.offer.get('url')
    },
    update: async function () {
      this.saveButton = 'saving...'

    if (this.isNew) {
        let query = new Parse.Query(Profile);
        query.equalTo('name', this.$route.params.fanfest)
        query.include('group')
        let profile = await query.first().catch((error) => alert(error))

        this.offer = new Offer()
        this.offer.set('audience', 'Group')
        this.offer.set('audienceID', profile.get('group').id)
        this.offer.set('isActive', true)
      }

      this.offer.set('title', this.title)
      // this.offer.set('logo', this.logo)
      this.offer.set('url', this.url)

      var subtitleString = ''

      if (this.subtitles.length > 0) {        
        subtitleString = this.subtitles[0]
        for (var i = 1; i < this.subtitles.length; i++) {
          if (this.subtitles.length > 1 && this.subtitles[i] != "") {
            subtitleString += ', '
            subtitleString += this.subtitles[i]
          }
        }
      }
      if (this.newSubtitles.length > 0) {
        for (var k = 0; k < this.newSubtitles.length; k++) {
          if (this.newSubtitles[k] != "") {
            if (subtitleString != '') subtitleString += ', '
            subtitleString += this.newSubtitles[k]
          }
        }
      }
      this.offer.set('subtitle', subtitleString)
      
      if (this.logoFile) {
        let fileToSave = new Parse.File('arsenal', this.logoFile, this.logoFile.type)
        await fileToSave.save()
        console.log(fileToSave.toJSON())
        this.offer.set('logo', fileToSave.url())
      }

      await this.offer.save().catch((error) => alert(error))
      this.$emit('update')

      setTimeout(() => {
        this.saveButton = 'saved!';
        this.$bvModal.hide('modal-offer-editor')
      }, 1000);
    },
    disable: async function () {
      if (!this.offer) return
      this.offer.set('isActive', false)

      await this.offer.save().catch((error) => alert(error))
      this.$emit('update')

      setTimeout(() => {
        this.$bvModal.hide('modal-offer-editor')
      }, 500);
    }
  },
};
</script>


<style scoped>
</style>