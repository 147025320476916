<template lang="pug">
  .container.p-0(v-if="canHost")
    //- h3.pt-3.text-light.text-center.text-uppercase.font-weight-bold Live Content

    b-row.h-100.m-0(align-v="center")
      b-col
        b-row.h-100.mb-5(align-v="center")
          b-col.p-0.text-uppercase(align-self='center')
            .text-center
              // scroll back
              b-button.content-arrows.text-uppercase.bg-transparent.border-0(@click="changedContentType(-1)")
                i.fa.fa-2x.fa-arrow-circle-left
              
              // content sources
              b-button.content-button.text-uppercase.my-3.border-0(v-if="selection === 'library'" @click="clickedContentType('library')" :class="selection === 'library' ? '' : '' ")
                i.fa.fa-2x.fa-film
              
              //- b-button.content-button.text-uppercase.my-3(v-if="selection === 'hls'" @click="clickedContentType('hls')" :class="selection === 'hls' ? '' : '' ")
                i.fa.fa-2x.text-light.fa-satellite-dish
        
              b-img.content-button-image(v-if="selection === 'youtube'" src='https://fanfest-resources.s3.us-east-1.amazonaws.com/yt-logo-sq.jpeg' @click="clickedContentType('youtube')" :class="selection === 'youtube' ? 'content-selected' : '' ")
              
              b-img.content-button-image(v-if="selection === 'twitch'" src='https://fanfest-resources.s3.us-east-1.amazonaws.com/twitch%20logo%20sq.png' @click="clickedContentType('twitch')" :class="selection === 'twitch' ? 'content-selected' : '' ")
              
              b-img.content-button-image(v-if="selection === 'tweet'" src='https://fanfest-resources.s3.us-east-1.amazonaws.com/twitter%20logo%20sq.jpeg' @click="clickedContentType('tweet')" :class="selection === 'tweet' ? 'content-selected' : '' ")

              // toggle content
              //- b-button.content-button.text-uppercase.my-3(v-if="selection === 'none'" @click="clickedContentType('none')")
                i.fa.fa-2x.text-light.fa-eye-slash

              // scroll forward
              b-button.content-arrows.text-uppercase.bg-transparent.border-0(@click="changedContentType(1)")
                i.fa.fa-2x.fa-arrow-circle-right

        b-row.my-5(v-show="showInput" align-v="center")
          b-col
            .container.w-75
              select.custom-select(ref='libraryInput' v-show="selection === 'library'")
                option(value='none') Select Video
                option(v-for="(v, index) in libraryVOD" :value="v.url") {{ v.name }}
              b-form-input(v-model='selectedHLS' ref='hlsInput' v-show="selection === 'hls'" placeholder='Enter .m3u8' autocomplete='off' trim) 
              b-form-input(v-model='selectedYoutube' ref='youtubeInput' v-show="selection === 'youtube'" placeholder='Enter Youtube URL' autocomplete='off' trim)
              b-form-input(v-model='selectedTwitch' ref='twitchInput' v-show="selection === 'twitch'" placeholder='Enter Twitch URL' autocomplete='off' trim)
              b-form-input(v-model='selectedTweet' ref='tweetInput' v-show="selection === 'tweet'" placeholder='Enter Twitter URL' autocomplete='off' trim)
              
              b-button.my-4(block pill variant='success' @click="selectedContent()" :disabled="!selectionButtonEnabled") Show
              

        b-row.mt-5(align-v="center")
          b-col.text-center        
            b-button.moderator-button.bg-secondary.text-uppercase.my-3.border-0.rounded-circle(@click="muteGuests()")
              i.fa.fa-x.fa-microphone-alt-slash
            .text-light Toggle Mics Off
          b-col.text-center(v-if='!didHostHide')        
            b-button.moderator-button.text-uppercase.my-3.border-0.rounded-circle(@click="toggleContentVisibility()" :class="!didHostHide ? 'bg-danger' : 'bg-success' ")
              i.fa.fa-x.text-light.fa-eye-slash(v-if='!didHostHide')
              i.fa.fa-x.text-light.fa-eye(v-else)
            .text-light {{ !didHostHide ? 'Hide Content' : 'Show Content' }}
          b-col.text-center        
            b-button.moderator-button.bg-secondary.text-uppercase.my-3.border-0.rounded-circle(@click="openDashboard()")
              i.fa.fa-x.fa-tv
            .text-light Admin Area
          //- b-col.text-center        
            b-button.moderator-button.bg-danger.text-uppercase.my-3.border-0.rounded-circle(@click="toggleContentVisibility()" :class="didHostHide ? 'bg-danger' : '' ")
              i.fa.fa-2x.text-light.fa-stop
            .text-light Stop Hosting

        b-row.mt-5(align-v="center")
          b-col
            .container.w-75.mt-5
              b-button(v-if='isHost' block pill variant='danger' @click="endHost()" :disabled="!selectionButtonEnabled") Stop Producer Mode     

</template>


<script>
import { mapState } from "vuex";
import axios from "axios"
import urlparse from "url-parse"
import qs from "query-string"
import Amplitude from "../services/amplitude";

const VOD_CONTENT = 'library'
// const HLS_CONTENT = 'hls'
const YOUTUBE_CONTENT = 'youtube'
// const TWITCH_CONTENT = 'twitch'
const TWEET_CONTENT = 'tweet'
// const NO_CONTENT = 'none'

export default {
  name: "SidebarHost",
  data() {
    return {
      sources: [
        VOD_CONTENT,
        // HLS_CONTENT,
        TWEET_CONTENT,
        YOUTUBE_CONTENT,
        // TWITCH_CONTENT,
        // NO_CONTENT
      ],      
      selection: VOD_CONTENT,
      showInput: true,
      libraryVOD: [],
      selectedHLS: '',
      selectedYoutube: '',
      selectedTwitch: '',
      selectedTweet: '',
      selectionButtonEnabled: true,
      didHostHide: undefined,
    };
  },
  mounted: function() {
    console.log('mounting host controls...')

    let self = this
    setInterval(() => {
      if (!self.isHost) return
      if (self.didHostHide === true) {
        self.$socket.emit("toggleFFVOD", self.roomForContent, self.didHostHide)
        return;
      }
      
      if (self.contentType === 'vod' && isURLValid(self.urlVOD))
        self.pushVideoContent(self.urlVOD);
      else if (self.contentType === 'iframe' && isURLValid(self.iframeURL))
        self.$socket.emit("updateFFEmbed", self.roomForContent, self.iframeURL);
      else if (self.contentType === 'tweet' && self.tweetID)
        self.$socket.emit("updateFFTweet", self.roomForContent, self.tweetID);
    }, 5000);
    this.configLibrary()
  },
  computed: {
    ...mapState({
      // library: state => state.host.listVOD,
      liveContent: state => state.host.contentType,
    }),
    ...mapState({
      roomForContent: state => state.fanfest.roomForContent,
      roomForFans: state => state.fanfest.roomForFans,
      canHost: state => state.fanfest.canHost,
      hasHost: state => state.fanfest.hasHost,
      isHost: state => state.fanfest.isHost,
      showVOD: state => state.fanfest.showVOD,
      urlVOD: state => state.fanfest.urlVOD,
      iframeURL: state => state.fanfest.urlVideoEmbed,
      tweetID: state => state.fanfest.urlTweet,
      contentType: state => state.fanfest.contentType,
    }),
  },
  watch: {},
  sockets: {},
  methods: {
    configLibrary: function() {
      let organizerID = this.$store.state.client.organizer.objectId;
        axios
          .get(`${process.env.VUE_APP_CONFIG_ROOT}/${organizerID}/vod/all`)
          // .then(response => this.$store.commit("configHostLibrary", response.data))
          .then(response => this.libraryVOD = response.data)
          .catch(err => Promise.reject(err.message));
    },
    changedContentType: function(change) {
      var newIndex = this.sources.indexOf(this.selection) + change
      if (newIndex < 0) newIndex = this.sources.length - 1
      else if (newIndex >= this.sources.length) newIndex = 0

      this.selection = this.sources[newIndex]
    },
    clickedContentType: function(newSelection) {
      this.selection = newSelection
      this.showInput = true
    },
    selectedContent: function() {
      // config proper value in store
      // update host content type in store to notify rest of app
      // send out a socket update

      if (this.hasHost && !this.isHost) {
        alert(this.$t('alert.errorMultipleHost'))
        return
      }

      // manually show player since host doesn't get socket updates
      this.selectionButtonEnabled = false

      // toggle (enable) FFVOD if it's not showing already
      if (!this.isHost) this.$store.commit('toggleFFVOD');

      if (!this.showVOD) this.$store.commit('showFFVOD', '')

      if (this.selection === 'library') {
        let url = this.$refs.libraryInput.options[this.$refs.libraryInput.options.selectedIndex].value
        this.pushVideoContent(url)
      }
      else if (this.selection === 'hls') {
        this.pushVideoContent(this.selectedHLS)
      }
      else if (this.selection === 'youtube') {
        let ytURL = function(url) {
          let urlObject = urlparse(url)
          let queryObject = qs.parse(urlObject.query)
          let ytID = queryObject.v
          return `https://www.youtube.com/embed/${ytID}?controls=1&autoplay=1&mute=1`
        }
        
        this.pushVideoEmbed(ytURL(this.selectedYoutube))
      }
      else if (this.selection === 'twitch') {
        let twitchURL = function(url) {
          let twitchID = url.split('/').pop().trim()
          return `https://player.twitch.tv/?channel=${twitchID}&parent=${window.location.hostname}&muted=true`
        }
        
        this.pushVideoEmbed(twitchURL(this.selectedTwitch))
      }
      else if (this.selection === 'tweet') {
        let twitterURL = function(url) {
          let urlObject = urlparse(url)
          let tweetID = urlObject.pathname.split('/').pop().trim()
          return tweetID
        }
        
        this.pushTweet(twitterURL(this.selectedTweet))
      }
      else if (this.selection === 'none') {     
        this.toggleContentVisibility()
      }
      
      this.selectionButtonEnabled = true
    },
    pushVideoContent: function(url) {
      console.log(`showing video content: ${url}`)

      // automatically show if content hidden
      if (this.didHostHide) this.toggleContentVisibility()

      // show content to host
      // if (url != this.urlVOD)
      this.$store.commit('showFFVOD', url)

      // show content to fans
      this.$socket.emit (
        "updateFFVOD",
        this.roomForContent,
        this.urlVOD,
        false, // muted
        0, // currenttime - potentially add fn parameter to pass this for non-live streams
        false, // paused
        this.showVOD
      );

      this.$gtag.event('producer_showed_vod', {
          'event_category': 'production',
        })
      Amplitude.getInstance().logEvent('Producer Showed VOD')
    },
    pushVideoEmbed: function(url) {
      console.log(`showing embed: ${url}`)
      // automatically show if content hidden
      if (this.didHostHide) this.toggleContentVisibility()
      
      // show content to host
      this.$store.commit('showFFEmbed', url)

      // show content to fans
      this.$socket.emit("updateFFEmbed", this.roomForContent, url)

      this.$gtag.event('producer_showed_video_embed', {
          'event_category': 'production',
        })
      Amplitude.getInstance().logEvent('Producer Showed Video Embed')
    },
    pushTweet: function(tweetID) {
      console.log(`showing tweet: ${tweetID}`)

      // automatically show if content hidden
      if (this.didHostHide) this.toggleContentVisibility()
      
      // show content to host
      this.$store.commit('showFFTweet', tweetID)

      // show content to fans
      this.$socket.emit("updateFFTweet", this.roomForContent, tweetID)

      this.$gtag.event('producer_showed_vod', {
          'event_category': 'production',
        })
      Amplitude.getInstance().logEvent('Producer Showed VOD')
    },
    toggleContentVisibility: function() {     
      // toggle (enable) FFVOD if it's not showing already
      if (!this.isHost) this.$store.commit('toggleFFVOD');
      if (!this.showVOD) this.$store.commit('showFFVOD', '')
      
      if (this.didHostHide === undefined)
        this.didHostHide = true
      else
        this.didHostHide = !this.didHostHide

      this.$socket.emit("toggleFFVOD", this.roomForContent, this.didHostHide)
      this.$store.commit('toggleContentVisibility', this.didHostHide)

      this.$gtag.event('producer_toggled_content', {
          'event_category': 'production',
        })
      Amplitude.getInstance().logEvent('Producer Toggled Content')
    },
    muteGuests: function() {
      this.$socket.emit("hostMute", this.roomForFans);

      this.$gtag.event('mute_stage', {
          'event_category': 'moderation',
        })
      Amplitude.getInstance().logEvent('Mute Stage')
    },
    openDashboard: function() {
      window.open(`https://watch.fanfest.io/admin/${this.$store.state.fanfest.config.name}?r=${this.$store.state.fanfest.registration.objectId}`, '_blank')
      // window.open(`https://localhost:8080/admin/${this.$store.state.fanfest.config.name}?r=${this.$store.state.fanfest.registration.objectId}`, '_blank')
      // console.log(`https://localhost:8080/admin/${this.$store.state.fanfest.config.name}?r=${this.$store.state.fanfest.registration.objectId}`)
    },
    endHost: function() {
      this.$socket.emit("hideFFVOD", this.roomForContent);
      this.$store.commit('hideFFVOD')
    }
  }
};

function isURLValid(url) {
  if (!url) return false
  if (url == '') return false
  return true
}
</script>

<style scoped>
.content-button {
  border: none;
  /* border-radius: 50%; */
  padding: none;
  height: 7vw;
  width: 7vw;
  font-size: 2vw;
  font-weight: 700;
  background-color: rgb(57, 57, 57);
}

.content-button-image {
  border: none;
  /* border-radius: 50%; */
  padding: none;
  /* height: 3vw; */
  width: 5vw;
}

.content-selected {
  height: 7vw;
  width: 7vw;
  font-size: 1vw;
}

.content-arrows {
  font-size: 1.5vw;
  padding: none;
  margin: 1vw;
}

.moderator-button {
  border: none;
  /* border-radius: 50%; */
  padding: none;
  height: 3vw;
  width: 3vw;
  font-size: 1vw;
  font-weight: 800;
  /* background-color: rgb(57, 57, 57);  */
}

.selector-only {
  height: 100%;
}
.selector-with-input {
  height: 30%;
}

button:focus, i:focus {
    outline: none;
  }
</style>