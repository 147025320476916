const state = {
  // capabilities
  config: null,
  event: null,
  canStream: false,
  canHost: false,
  hasHost: false,
  isVIP: false,
  // user
  screenName: '',
  registration: null,
  banned: false,
  shareLink: '',
  // streamers
  numMembers: -1, // when 0 or positive we know FanFest check on load complete
  streams: [],
  streamsVIP: [],
  streamsAudio: [],
  roomForFans:'',
  // host
  isHost: false,
  // content
  contentType: 'vod',
  showVOD: false,
  urlVOD:'',
  urlVideoEmbed: "",
  urlTweet: "",
  roomForContent:'',
  didHostHide: undefined,
  // polls
  hasPolls: false,
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  configFanfest(state, config) {
    state.config = config
  },
  configEvent(state, event) {
    state.event = event
  },
  configShareLink(state, link) {
    state.shareLink = link
  },
  configSockets(state, rooms) {
    state.roomForContent = rooms.content
    state.roomForFans = rooms.fans
  },
  updateScreenName(state, name) {
    state.screenName = name
  },
  updateRegistration(state, registration) {
    state.registration = registration
  },
  enablePolls(state) {
    state.hasPolls = true;
  },
  enableStreaming(state) {
    state.canStream = true;
  },
  disableStreaming(state) {
    state.canStream = false;
  },
  enableHost(state) {
    state.canHost = true;
  },
  enableVIP(state) {
    state.isVIP = true;
  },
  hasHost(state, value) {
    state.hasHost = value;
  },
  updateActiveMembers(state, value) {
    let totalStreams = value
    state.numMembers = totalStreams - state.streamsVIP.length;

    /* if (state.numMembers === 0) {
      // reset FFVOD 
      state.hasHost = false;
      state.showVOD = false;
      state.urlVOD = '';
    } */
  },
  createFanFestVideo(state, data) {
    if (data.isVIP == true) {
      state.streamsVIP = state.streamsVIP.filter(d => d.sessionId != data.sessionId)
      state.streamsVIP.push(data)
    }
    else {
      state.streams = state.streams.filter(d => d.sessionId != data.sessionId)
      state.streams.push(data)
    }

    if (data.isAudioOnly == true) {
      state.streamsAudio = state.streamsAudio.filter(d => d.sessionId != data.sessionId)
      state.streamsAudio.push(data)
    }
  },
  removeFanFestVideo(state, sessionId) {
    state.streams = state.streams.filter(d => d.sessionId != sessionId)
    state.streamsVIP = state.streamsVIP.filter(d => d.sessionId != sessionId)
    state.streamsAudio = state.streamsAudio.filter(d => d.sessionId != sessionId)
  },
  toggleFFVOD(state) {
    state.showVOD = !state.showVOD;
    state.urlVOD = '';
    state.urlVideoEmbed = '';
    state.urlTweet = '';
    state.isHost = !state.isHost; // this stays local, only gets called from button
  },
  /* hostStartedFFVOD(state) {
    state.isHost = false; // this stays local
  }, */
  showFFVOD(state, url) {
    state.contentType = 'vod'
    state.showVOD = true;
    state.urlVOD = url;
    state.urlTweet = '';
    state.urlVideoEmbed = '';
  },
  hideFFVOD(state) {
    state.showVOD = false;
    state.urlVOD = '';
    state.urlTweet = '';
    state.urlVideoEmbed = '';
    state.isHost = false;
  },
  /* updateFFVOD(state, url) {
    state.contentType = 'vod'
    state.urlVOD = url;

    // state.urlTweet = '';
    // state.urlVideoEmbed = '';
  }, */
  showFFEmbed(state, url) {
    state.contentType = 'iframe'
    state.showVOD = true;
    state.urlVideoEmbed = url;
    state.urlTweet = '';
    state.urlVOD = '';
  },
  showFFTweet(state, tweetID) {
    state.contentType = 'tweet'
    state.showVOD = true;
    state.urlTweet = tweetID;
    state.urlVOD = '';
    state.urlVideoEmbed = '';
  },
  toggleContentVisibility(state, didHostHide) {
    state.didHostHide = didHostHide;
    if (didHostHide) {
      state.urlTweet = '';
      state.urlVOD = '';
      state.urlVideoEmbed = '';
    }
  },
  banFFUser(state) {
    state.banned = true;
  },
  logout(state) {
    state.config = null;
    state.canHost = null;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}