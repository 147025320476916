<template lang="pug">
div
  // Basics
  b-form.p-3
    label.font-weight-bold {{ $t('admin.basicseventinfotext') }}
    b-form-input.mt-2(v-model="event.title", trim, autocomplete="none")
    b-form-textarea.mt-2(
      v-model="event.description",
      trim,
      autocomplete="none"
    )
    .my-3
      b-form-datepicker(v-model='startDate' placeholder='Show Start Date')
      b-form-timepicker(v-model='startTime' placeholder='Show Start Time')
    
    //- b-form-group.mt-2
      b-form-radio(v-model="cost", value="free") {{ $t('admin.freetoggle') }}
      b-form-radio(v-model="cost", value="paid") {{ $t('admin.paidtoggle') }}
      b-form-spinbutton.mt-2(v-if="cost === 'paid'", v-model="event.cost" min=1, max=1000 :formatter-fn="costFormatter" inline)
    
    b-form-checkbox.mt-2(v-if="!isNew" v-model="event.isActive") {{ $t('admin.activecheckbox') }}
    

  // Access
  b-form.p-3
    //- b-form-group
      label.font-weight-bold {{ $t('admin.eventtypetext') }}
      b-form-radio(v-model="type", value="townhall") {{ $t('admin.fansjoinsametext') }}
      b-form-radio(v-model="type", value="watchparty") {{ $t('admin.fanscreatemanytext') }}
      //- b-form-tags(v-if="type === 'townhall'" v-model='event.hosts' remove-on-delete)
    b-form-group(v-if="event.fanfest === 'mancity'")
      label {{ $t('admin.vipstext') }}
      b-form-tags(v-model='event.vips' remove-on-delete :placeholder="$t('admin.vipsemailstext')" separator="[',',', ',' ']" :tag-validator='emailValid' @input='emailFormatter')
    //- b-form-group()
      label {{ $t('admin.visiblefanstext') }}
      b-form-tags(v-model='event.hosts' remove-on-delete :placeholder="$t('admin.visiblefanemailstext')" separator="[',',', ',' ']" :tag-validator='emailValid' @input='emailFormatter')
      //- b-form-radio(v-model="type", value="watchparty") Everyone (Watch Party mode)
      //- b-form-radio(v-model="type", value="townhall") VIPs Only (Town Hall mode)

  // Offers
  // b-form.p-3
    label.mt-2.font-weight-bold Offers: What to merchandise?

  // Save
  .p-3.bg-light.d-flex.justify-content-between
    b-button.text-uppercase.font-weight-bold(variant="outline-success", @click="update") {{ saveButton }}
    b-button.text-uppercase.font-weight-bold(variant='outline-secondary' @click="cancel") {{ $t('admin.backbutton') }}

</template>


<script>
import { DateTime } from "luxon";
import Parse from "../../services/parse";
let Event = Parse.Object.extend("FanfestEvent");

export default {
  name: "",
  data() {
    return {
      event: {},
      cost: "",
      type: "townhall",
      startDate: undefined,
      startTime: undefined,
      hosts: [],
      saveButton: 'save',
    };
  },
  props: {
    eventID: String
  },
  computed: {
    isNew: function() {
      return this.$props.eventID === 'new'
    },
  },
  watch: {
    cost: function() {
      if (this.event && this.cost === 'paid' && this.event.cost === null) this.event.cost = 1
    },
    type: function() {
      if (this.event && this.type != 'townhall') this.event.hosts = null
    }
  },
  sockets: {},
  mounted: function () {
    this.load();
  },
  methods: {
    load: async function () {
      if (this.isNew) return;

      try {
        let query = new Parse.Query(Event);
        let event = await query.get(this.$props.eventID);
        this.event = event.toJSON();
        this.cost = this.event.cost ? "paid" : "free";
        this.type = (this.event.hosts || this.event.vips) ? "townhall" : "townhall";

        let date = DateTime.fromISO(event.get('startsAt').toISOString())
        this.startDate = date.toFormat('yyyy-MM-dd')
        this.startTime = date.toFormat('HH:mm:ss')
      } catch (error) {
        if (!this.event) alert(error);
      }
    },
    update: async function () {
      this.saveButton = 'saving...'
      try {
        if (this.cost === 'free') this.event.cost = null

        var event;

        if (this.isNew) {
          event = new Event()

          this.event.fanfest = this.$route.params.fanfest
          this.event.date = 'live'
          this.event.capacity = 25
          this.event.isActive = true
        }
        else {
          let query = new Parse.Query(Event);
          event = await query.get(this.$props.eventID);
        }

        let dateString = `${this.startDate}T${this.startTime}`
        let date = new Date(dateString).toISOString()
        this.event.startsAt = new Date(date)

        await event.save(this.event);
        this.$emit('update')
        setTimeout(() => {
          this.saveButton = 'saved!';
          this.$bvModal.hide('modal-event-editor')    
        }, 500);
      } catch (error) {
        alert(error);
      }
    },
    cancel: function() {
        this.$bvModal.hide('modal-event-editor')
    },
    costFormatter: function () {      
      var costString = `$${this.event.cost}`
      if (String(this.event.cost).indexOf(".") < 0 ) costString += `.00`
      return costString
    },
    emailFormatter: function (listOfEmails) {
      for (var email of listOfEmails) {
        email = email.toLowerCase()
      }
    },
    emailValid: function(email) {      
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 100vh;
  overflow-x: hidden;
}
</style>