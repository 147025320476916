<template lang="pug">
div
  // Basics
  b-form.p-3
    label.font-weight-bold {{ $t('admin.adminsbutton') }}
    b-form-tags(v-model='admins' remove-on-delete :placeholder="$t('admin.adminemailstext')" separator="[',',', ',' ']" :tag-validator='emailValid' @input='emailFormatter')
    //- b-form-input.mt-2(v-model="admin", :state='emailValid' trim, autocomplete="none" placeholder='Enter admin email')
    //- b-form-invalid-feedback#input-live-feedback
  b-form.p-3
    label.font-weight-bold {{ $t('admin.logotext') }}
    //- b-form-input.mt-2(v-model="image", trim, autocomplete="none" placeholder='Enter URL')
    img.d-block.my-1(:src='image' style="height:10vw")
    b-form-file(v-model="imageFiles.logo" accept="image/*" :placeholder="$t('admin.replaceimagetext')")
  b-form.p-3
    label.font-weight-bold {{ $t('admin.landingpageimagetext') }}
    //- b-form-input.mt-2(v-model="loginBG", trim, autocomplete="none" placeholder='Enter URL')
    img.d-block.my-1(:src='loginBG' style="height:10vw")
    b-form-file(v-model="imageFiles.login" accept="image/*" :placeholder="$t('admin.replaceimagetext')")
  b-form.p-3
    label.font-weight-bold {{ $t('admin.eventbackgroundtext') }}
    //- b-form-input.mt-2(v-model="mainBG", trim, autocomplete="none" placeholder='Enter URL')
    img.d-block.my-1(:src='mainBG' style="height:10vw")
    b-form-file(v-model="imageFiles.main" accept="image/*" :placeholder="$t('admin.replaceimagetext')")

  // Save
  .p-3.bg-light.d-flex.justify-content-between
    b-button.text-uppercase.font-weight-bold(variant="outline-success", @click="update") {{ saveButton }}
    b-button.text-uppercase.font-weight-bold(variant='outline-secondary' @click="cancel") {{ $t('admin.backbutton') }}
</template>


<script>
import Parse from "../../services/parse";
let Profile = Parse.Object.extend("FanfestConfig");

export default {
  name: "ProfileEditor",
  data() {
    return {
      profile: null,
      loginBG:'',
      mainBG:'',
      image:'',
      imageFiles: {},
      admin:'',
      admins: [],
      saveButton: 'save',
    };
  },
  computed: {},
  watch: {
    imageFiles: function() {
      if (this.imageFiles.logo) this.image = URL.createObjectURL(this.imageFiles.logo)
      if (this.imageFiles.login) this.loginBG = URL.createObjectURL(this.imageFiles.login)
      if (this.imageFiles.main) this.mainBG = URL.createObjectURL(this.imageFiles.main)
    }
  },
  sockets: {},
  mounted: function () {
    this.load();
  },
  methods: {
    load: async function () {
      let query = new Parse.Query(Profile);
      query.equalTo('name', this.$route.params.fanfest)
      query.include('group')
      this.profile = await query.first().catch((error) => {
        if (!this.profile) alert(error);
      })

      this.loginBG = this.profile.get('loginBG')
      this.mainBG = this.profile.get('mainBG')
      this.image = this.profile.get('group').get('image')
      this.admin = this.profile.get('admin')
      this.admins = this.profile.get('admins')
      
      if (!this.admins) {
        this.admins = [this.profile.get('admin')]
      }
    },
    update: async function () {
      this.saveButton = 'saving...'

      if (this.imageFiles.logo) {
        let url = await(this.saveImage(this.imageFiles.logo))
        var group = this.profile.get('group')
        group.set('image', url)
        await group.save().catch((error) => alert(error))
      }

      if (this.imageFiles.login) {
        let url = await(this.saveImage(this.imageFiles.login))
        this.profile.set('loginBG', url)
      }

      if (this.imageFiles.main) {
        let url = await(this.saveImage(this.imageFiles.main))
        this.profile.set('mainBG', url)
      }

      this.profile.set('admin', this.admin.toLowerCase())
      this.profile.set('admins', this.admins)

      await this.profile.save().catch((error) => alert(error))

      setTimeout(() => {
        this.saveButton = 'saved!';
        location.reload()
      }, 1000);
    },
    saveImage: async function(imageData) {
      let fileToSave = new Parse.File(this.profile.get('name'), imageData, imageData.type)
      await fileToSave.save()
      console.log(fileToSave.toJSON())
      return fileToSave.url()
    },
    cancel: function() {
      this.$bvModal.hide('modal-profile-editor')
    },
    emailValid: function(email) {      
      // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      // return re.test(email)
      return email.length > 0
    },
    emailFormatter: function (listOfEmails) {
      for (var email of listOfEmails) {
        email = email.toLowerCase().trim()
      }
    },
  },
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 100vh;
  overflow-x: hidden;
}
</style>