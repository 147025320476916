<template lang="pug">
div
  b-button.text-uppercase.font-weight-bold.d-block.mx-auto.my-5.w-75(variant='success' @click="showEditor('new')") {{ $t('admin.createeventbutton') }}
  //.contaier.admin-footer.w-100
    b-button.text-uppercase.font-weight-bold.d-block.mx-auto.my-3.w-75(variant='success' @click="showEditor('new')") Create Event 

  // List
  
  table.table.table-hover
    tbody
      tr(v-for="e, i in this.events" @click="showEditor(e.id)" style="height: 30px")
        td.align-middle.d-flex
          b-button.py-0.mr-2.font-weight-bold(v-if="e.get('isActive')" variant='danger') LIVE
          // router-link.text-dark.text-underline( :to="`${fanfest}/event/${e.id}`" target="_self" :class="[e.get('isActive') ? 'font-weight-bold' : '']") {{ e.get('title') }}
          .text-dark.text-underline(:class="[e.get('isActive') ? 'font-weight-bold' : '']") {{ e.get('title') }}
        //- td
          b-button.py-0.ml-3(variant='secondary' :class="[e.get('isActive') ? 'font-weight-bold' : '']") {{ registrationCounts[e.id] }}

  b-modal(id="modal-event-editor" size='lg' centered hide-header hide-footer)
    EventEditor(:eventID='selectedEvent' @update="loadEvents()")
    //template(v-slot:modal-footer)
      b-button.bg-success.mx-auto.d-block(ref='startPublishButton' @click='tappedStreamButton' size='lg' :disabled='!canStream') 
        i.fa.text-light.fa-2x(:class="[$route.query.kiosk ? 'fa-binoculars' : 'fa-video-camera']")
  
  // Save
  //.p-3.fixed-bottom.bg-light
    b-button.mr-3.text-uppercase.font-weight-bold(variant='outline-success' to="events/new") Create Event 
    b-button.mr-3.text-uppercase.font-weight-bold(variant='outline-secondary' :to="`/${fanfest}`") View Profile
</template>


<script>
import EventEditor from '@/components/admin/EventEditor.vue'

import Parse from "../../services/parse";
let Event = Parse.Object.extend("FanfestEvent");
let Registration = Parse.Object.extend("Registration");

export default {
  name: "",
  data() {
    return {
      events: null,
      selectedEvent: '',
      fanfest: this.$route.params.fanfest,
      registrationCounts: {},
      isEditing: false
    };
  },
  components: {
    EventEditor
  },
  mounted: function () {
    this.loadEvents();
  },
  computed: {},
  watch: {},
  sockets: {},
  methods: {
    loadEvents: async function () {
      try {
        let query = new Parse.Query(Event);
        query.equalTo("fanfest", this.$route.params.fanfest);
        query.descending("createdAt");
        query.limit = 1000;
        this.events = await query.find();
      } catch (error) {
        if (!this.events) alert(error);
      }

      this.loadFans()
    },
    loadFans: async function () {
      let query = new Parse.Query(Registration);
      query.equalTo('profile', this.$route.params.fanfest)
      query.descending('createdAt')
      query.include('fan')
      query.limit(100000)
      let registrations = await query.find().catch((error) => alert(error))

      for (var r of registrations) {
        let eventID = r.get('event')
        if (!this.registrationCounts[eventID]) {
          this.registrationCounts[eventID] = 1
        }
        else {
          this.registrationCounts[eventID] = this.registrationCounts[eventID] + 1
        }
      }




      /* var fanIDs = new Set()
      for (var r of this.registrations) {
        let fan = r.get('fan')
        if (!this.shouldAddFan(fan)) continue
        if (fanIDs.has(fan.id)) continue

        let fanData = {
          name: `${fan.get('fname')} ${fan.get('lname').substring(0,1)}.`,
          email: `${fan.get('email').substring(0,2)}*******`,
          last_event: r.get('eventTitle'),
          city: r.get('location').city,
          country: r.get('location').country,
          language: fan.get('language'),
        }

        this.fans.push(fanData)

        fanIDs.add(fan.id)
      } */
    },
    showEditor: function(eventID) {
      this.selectedEvent = eventID
      this.$bvModal.show('modal-event-editor')
    }
  },
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 90vh;
}

.admin-footer {
  position: absolute;
  bottom:0%;
  left:0%;
}

.table-text {
  font-size: 15px;
}

.table-row {
  height: 30px
}
</style>