<template lang="pug">
  #app
    router-view
</template>

<script>
export default {
  name: 'app',
  metaInfo: {
    title: 'VICI FanFest',
    titleTemplate: '%s'
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black; */
  font-family: 'Work Sans', sans-serif;
}

body, html {
  /* background-color: black; */
  min-height: 100%;
  overflow-x: hidden;
}
</style>