const state = {
  isEmbed: false,
  isBackstage: false,
  organizer: null,
  business: null,
  group: null,
  hasDevice: null,
  sessionID: null,
  platformType: null,
  shouldMute: false,
  shouldMuteStreamers: false,
  shouldMuteVideo: true,
  forceMic: false,
  isInvalid: false,
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  enableEmbedMode(state) {
    state.isEmbed = true
  },
  enableBackstageMode(state) {
    state.isBackstage = true
  },
  configClient(state, organizer) {
    state.organizer = organizer;
    state.business = organizer.business;
    state.group = organizer.group;
    state.hasDevice = organizer.hasDevice;
  },
  configPlatform(state, platformType) {
    state.platformType = platformType;
  },
  updateSessionID(state, sessionID) {
    state.sessionID = sessionID;
  },
  updateMute(state, shouldMute) {
    state.shouldMute = shouldMute;

    if (state.shouldMuteStreamers) {
      state.shouldMuteStreamers = false
      state.shouldMute = false
      state.shouldMuteVideo = true
    }
    else {
      state.shouldMuteStreamers = true
      state.shouldMute = true
      state.shouldMuteVideo = false
    }
  },
  toggleMute(state) {
    if (state.shouldMuteStreamers) { // watch mode > talk mode
      state.shouldMuteStreamers = false
      state.shouldMute = false
      state.shouldMuteVideo = true
    }
    else { // talk mode > watch mode
      state.shouldMuteStreamers = true
      state.shouldMute = true
      state.shouldMuteVideo = false
    }
  },
  muteStreamers(state, shouldMute) {
    state.shouldMuteStreamers = shouldMute;
    state.shouldMute = shouldMute;
  },
  muteVideos(state, shouldMute) {
    state.shouldMuteVideo = shouldMute;
  },
  forceMic(state) {
    state.forceMic = true;
  },
  flagInvalidClient(state) {
    state.isInvalid = true;
  },
  logout(state) {
    state.organizer = null;
    state.business = null;
    state.group = null;
    state.hasDevice = null;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}