<template lang="pug">
.sidebar.h-100(:class="platformType === 'mobile' ? 'p-4' : 'p-0'")
  Chat.h-100(v-show="sidebarContent === 'chat'")
  HostContent.h-100(v-show="sidebarContent === 'host'")

  //- button.close-button(v-show="platformType === 'mobile'" @click='close()')
    i.fa.fa-2x.text-light.fa-times-circle
</template>


<script>
import { mapState } from "vuex";

import Chat from "../components/Chat";
import HostContent from "../components/HostContent";

export default {
  name: 'Sidebar',
  components: {
    Chat,
    HostContent
  },
  data() {
    return {};
  },
  mounted: function() {},
  computed: {
    ...mapState({
      platformType: state => state.client.platformType,
      hasSidebar: state => state.sidebar.visible,
      sidebarContent: state => state.sidebar.content,
    }),
  },
  watch: {},
  sockets: {},
  methods: {
    close: function() {
      this.$store.dispatch('hideSidebar')
    }
  }
};
</script>


<style scoped>

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  height: 2vh;
  width: 2vh;
  border: none;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
</style>