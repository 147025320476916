<template lang="pug">
  b-overlay(:show='hasFooterMenu' variant='dark' v-if='currentHeadline')
    b-row.new-banner.align-items-center.px-2
      
      // Image
      b-col.p-0(v-show="showOffers || windowWidth > 575" cols="1")
        transition(name="custom-classes-transition" enter-active-class='animated slideInLeft faster' leave-active-class='animated slideOutLeft faster' mode="out-in" appear)
          img.img-fluid.d-block.mx-auto.p-1.new-banner-image(:src="isFanFestActive ? currentOffer.image : currentOffer.image" :key="index" :class="[isFanFestActive ? 'visible' : 'visible']")
      
      // Offer
      b-col#offer.p-0.h-100.text-white(style="overflow:hidden;" @click="showCheckout = showCheckout" v-show="showOffers || windowWidth > 575")
        transition(:name="transitionForHeadline.name" :enter-active-class='transitionForHeadline.enter' :leave-active-class='transitionForHeadline.exit' mode="out-in" appear)
          a.text-decoration-none.text-white.text-uppercase.d-flex.align-items-center.justify-content-left.h-100.px-3(:key='currentHeadline' :href='currentOffer.url' :target="isBackstage ? '_self' : '_blank'" v-if='currentOffer.url' @click="clickedOffer") {{ isFanFestActive ? currentHeadline : currentHeadline }}
          p.text-decoration-none.text-white.text-uppercase.d-flex.align-items-center.justify-content-left.h-100.px-3(:key='currentHeadline' v-else) {{ isFanFestActive ? currentHeadline : currentHeadline }}
        //b-modal(v-model="showCheckout" centered ok-title="Pay")
          OfferCheckout
      
      b-col(v-show="!showOffers")#spacer.d-none

      // Buttons
      b-col.px-4.appear(v-if="hasCTA === false && showControls" :class="[isKiosk ? 'visible' : 'visible', windowWidth < 575 ? '' : 'col-4', windowWidth > 575 && platformType === 'mobile' ? 'd-none' : '']")
        .d-flex.align-items-center(:class="[windowWidth < 575 ? 'justify-content-center' : 'justify-content-end']")
          // Share
          b-button#sharebutton.bg-primary.text-white.text-uppercase.font-weight-bold.new-banner-button.mr-2.p-1(@click='share()' size="lg" :class="isHost ? '' : ''")
            i.fa.fa-x.text-light.fa-share-square
            //- b-tooltip(v-if="platformType != 'mobile'" variant='light' hover target='sharebutton') {{ $t('tooltip.invite' )}}      
          // Walkie-Talkie
          b-button#walkietalkie.text-white.text-uppercase.font-weight-bold.new-banner-button.mr-2.p-1(v-show="!isKiosk && fanfestStreamingEnabled && isStreaming" ref='walkietalkie' @click='toggleAudio' size="lg" :class="[!shouldMuteStreamers ? 'bg-success' : 'bg-danger']")
            i.fa.fa-x.text-light(:class="[!shouldMuteStreamers ? 'fa-microphone' : 'fa-microphone-slash']")
            //- b-tooltip(v-if="platformType != 'mobile'" variant='light' target='walkietalkie') {{ $t('tooltip.walkietalkie' )}}           
          // Chat
          b-button#chatbutton.text-white.text-uppercase.font-weight-bold.new-banner-button.mr-2.p-1(@click="toggleSidebar('chat')" size="lg" :class="sidebarContent === 'chat' ? 'bg-success' : 'bg-secondary'")
            i.fa.fa-x.text-light.fa-comment    
          // Queue
          b-button#queuebutton.text-white.text-uppercase.font-weight-bold.new-banner-button.mr-2.p-1(v-if="isQueueButtonEnabled && !isBackstage" @click="toggleSidebar('queue')" size="lg" :class="sidebarContent === 'queue' ? 'bg-success' : 'bg-secondary'")
            i.fa.fa-x.text-light.fa-hand-paper
          // Toggle Video
          //- b-button#togglevideobutton.bg-gray.text-white.text-uppercase.font-weight-bold.new-banner-button.mr-2.p-1(v-show="!showVOD && !forceMic && (isStreaming || !canStream)" @click='toggleVideo')
            i.fa.fa-youtube.text-light           
            //- b-tooltip(v-if="platformType != 'mobile'" variant='light' hover target='togglevideobutton') {{ $t('tooltip.content' )}} 
          // Live
          FanFestViewService(:screenName="screenName" :videoList="fanfestViews" @isStreaming="isStreaming = true")
          // Host
          b-button#hostbutton.bg-secondary.text-uppercase.font-weight-bold.new-banner-button.p-1(v-if="(isFFVODButtonEnabled && platformType != 'mobile' && !isBackstage)" @click="toggleSidebar('host')" size="lg" :class="sidebarContent === 'host' ? 'bg-success' : 'bg-secondary'")
            i.fa.fa-x.text-light.fa-gear
            //- b-tooltip(v-if="platformType != 'mobile'" variant='light' hover target='hostbutton') {{ $t('tooltip.host' )}}
          // HMC
          //.embed-responsive.embed-responsive-1by1.new-banner-button.mr-2.px-1.animated.pulse.infinite(v-if="profile === 'rbfa'")
            iframe#hmc.embed-responsive-item(:src="`https://hln.hearmecheer.com/games/2020-nations-league-belgium/${profile}/button`" allow="camera;microphone" allowusermedia)
            b-tooltip(hover target='hmc') Cheer Live 
          // Mute
          //b-button#muteButton.bg-gray.text-white.text-uppercase.font-weight-bold.new-banner-button.mr-2.p-1(@click='mute' v-b-tooltip.hover :title="$t('tooltip.mute')" size="lg")
            i.fa.fa-x.text-light(:class="[shouldMute ? 'fa-volume-mute' : 'fa-volume-up']")
          
      
      //CTA
      b-col.p-0.h-100(v-if='hasCTA === true' cols="3")
        transition(name="custom-classes-transition" enter-active-class='animated slideInRight faster' leave-active-class='animated slideOutRight faster' mode="out-in" appear)
          a#cta.d-flex.align-items-center.justify-content-left.text-white.text-uppercase.text-decoration-none.h-100.px-3(:key='index' :class="[isFanFestActive ? 'visible' : 'visible']" :href='currentOffer.url' target='_blank') {{ isFanFestActive ? currentOffer.footer :currentOffer.footer }}

      //Image
      b-col.p-0(v-show='hasCTA === false && (showOffers || windowWidth > 575)' cols="1")
        img.img-fluid.d-block.mx-auto.p-0.new-banner-image(src='/fanfest-logo-sq-light@3x.png' :key="index" :class="[isFanFestActive ? 'visible' : 'visible']")
      
      // Banner
      b-alert.position-fixed.fixed-top.m-0.rounded-0.text-center(v-if='showShare' :show='10' @dismissed="showShare=false" variant='success' dismissible style='z-index:2000') {{ $t('alert.invite') }}

      b-alert.position-fixed.fixed-top.m-0.rounded-0.text-center(v-if='showWalkieTalkieError' :show='5' @dismissed="showWalkieTalkieError=false" variant='danger' dismissible style='z-index:2000') {{ $t('alert.errorMobileVoiceChat') }}

      b-alert.position-fixed.fixed-bottom.m-0.py-4.rounded-0.text-center(v-model='showShareMobile' variant='success' style='z-index:2000') 
        div.h-100.w-100(@click='share()') {{ $t('alert.inviteMobile') }}
    
    //template(v-slot:overlay)
      .container-float.h-100
        #menuBanner(ref='menuVOD')
          button.btn.btn-outline-light.mx-auto.text-uppercase.font-weight-bold.align-self-center(type='button' @click="hasFooterMenu = false") Update Offers
          // button.btn.btn-outline-danger.mx-auto.text-uppercase.font-weight-bold.align-self-end(type='button' @click='hasVODMenu = false') Back    
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import bowser from "bowser";
import FanFestViewService from "../components/services/FanFestService.vue";
import OfferCheckout from "../components/OfferCheckout"
import Amplitude from "../services/amplitude";

/* import {loadStripe} from '@stripe/stripe-js';
 */
const transitionsForHeadline = [
  {
    name: "custom-classes-transition",
    enter: "animated slideInLeft faster",
    exit: "animated slideOutLeft faster"
  },
  {
    name: "headline-scroll",
    enter: "animated fadeInUp",
    exit: "animated fadeOutDown"
  }
];

const headlineDuration = 5000;

export default {
  name: "Footer",
  data() {
    return {
      offers: [],
      index: -1,
      currentOffer: undefined,
      currentHeadline: undefined,
      transitionForHeadline: transitionsForHeadline[0],
      headlineTimer: undefined,
      hasFooterMenu: false,
      hasCTA: false,
      showCheckout: false,
      showShare: false,
      showShareMobile: false,
      showWalkieTalkieError: false,
      numShares: 0,
      isKiosk: this.$route.query.kiosk == 1,
      windowWidth: window.innerWidth || 0,
      isStreaming: false
    };
  },
  components: {
    FanFestViewService,
    OfferCheckout
  },
  props: {
    isFanFestActive: Boolean,
    screenName: String,
    fanfestViews: Array,
    showControls: {
      type: Boolean,
      default: true
    },
    showOffers: {
      type: Boolean,
      default: true
    }

    //fanfestStreamingEnabled: Boolean,
  },
  watch: {
    isFanFestActive: function() {
      if (this.$props.isFanFestActive && this.platformType == 'mobile' && this.numShares < 1)
        this.showShareMobile = true
      
        // this.$root.$emit('bv::show::tooltip', 'hmc')
    },
    showShareMobile : function() {
      if (this.showShareMobile)
        setTimeout(() => {
          this.showShareMobile = false
        }, 10000);
    }
  },
  computed: {
    ...mapState({
      platformType: state => state.client.platformType,
      shouldMute: state => state.client.shouldMute,
      shouldMuteVideo: state => state.client.shouldMuteVideo,
      shouldMuteStreamers: state => state.client.shouldMuteStreamers,      
      forceMic: state => state.client.forceMic,      
      groupName: state => state.client.group.name,
      isBackstage: state => state.client.isBackstage,
    }),
    ...mapState({
      profile: state => state.fanfest.config.name,
      fanfestStreams: state => state.fanfest.streams,
      fanfestMemberCount: state => state.fanfest.numMembers,
      fanfestScreenname: state => state.fanfest.screenName,
      fanfestStreamingEnabled: state => state.fanfest.canStream,
      isHost: state => state.fanfest.isHost,
      hasHost: state => state.fanfest.hasHost,
      canHost: state => state.fanfest.canHost,
      canStream: state => state.fanfest.canStream,
      showVOD: state => state.fanfest.showVOD,
      imageBackground: state => state.fanfest.config.mainBG,
      shareLink: state => state.fanfest.shareLink
    }),
    ...mapState({
      sidebarContent: state => state.sidebar.content,
      isChatVisible: state => state.chat.visible,
      chatService: state => state.chat.chatDataSource,
    }),
    deviceClassObject: function() {
      return {
        'fixed-bottom': this.platformType === 'mobile'
      }
    },
    isFFVODButtonEnabled: function() {
      if (this.canHost)
        return true;

      if (this.showVOD && !this.isHost) // don't show FFVOD if already shown, someone else controlling
        return false;
    
      if (!this.isFanFestActive || !this.hasHost) // don't show FFVOD if someone isn't streaming or viewer doesn't have privileges
        return false;
      
      if (!this.canHost)
        return false;
      
      return true; // otherwise show it!
    },
    isQueueButtonEnabled : function() {
      const browser = bowser.getParser(window.navigator.userAgent);
      let osName = browser.getOS().name
      let browserName = browser.getBrowser().name
      let browserVersion = browser.getBrowser().version

      if (osName === 'iOS' && browserName === 'Safari' && browserVersion === '15.1') return false

      return true
    }
  },
  methods: {
    configOffers: function() {
      var organizerID = this.$store.state.client.organizer.objectId;
      axios
        .get(`${process.env.VUE_APP_CONFIG_ROOT}/${organizerID}/offers`)
        .then(response => {
          // console.log(`${response.data.length} offers loaded`)
          this.offers = response.data;
          this.currentOffer = response.data[0];

          this.updateOffer();
        })
        .catch(err => Promise.reject(err.message));
    },
    updateHeadline: function() {
      if (this.offers.length === 0) return;

      if (this.showCheckout) return;

      var possibleHeadlines = this.currentOffer.headline.split(", ");
      possibleHeadlines.unshift(this.currentOffer.topic);

      if (this.currentHeadline)
        this.transitionForHeadline = transitionsForHeadline[1];

      for (var i = 0; i < possibleHeadlines.length; i++) {
        if (possibleHeadlines[i] == this.currentHeadline) {
          if (i == possibleHeadlines.length-1) {
            this.currentHeadline = possibleHeadlines[0];
            break;
          }
          else {
            this.currentHeadline = possibleHeadlines[i+1];
            break;
          }
        }
      }

      if (!this.currentHeadline)
        this.currentHeadline = possibleHeadlines[0];

      if (possibleHeadlines.length > 1) {
        const self = this;
        self.headlineTimer = setTimeout(function() {
          self.updateHeadline();
        }, headlineDuration);
      }

      return possibleHeadlines.length;
    },
    updateOffer: function() {
      if (this.showCheckout) return;

      if (this.isBackstage) {
        this.currentOffer = {
          topic: 'FanFest Backstage • Click to return to show',
          headline: '',
          footer: '',
          image: 'https://pbs.twimg.com/profile_images/1392004804524875779/gZjR6o_X_400x400.jpg',
          url: `${window.location.href.replace('&staging=1', '')}`
        }

        this.updateHeadline();
        return;
      }

      if (this.$props.isFanFestActive && this.fanfestOffer) {
        this.currentOffer = this.fanfestOffer;
        this.currentHeadline = this.currentOffer.headline;
        return;
      } 

      if (this.offers.length === 0) return; // skip if nil
      if (this.currentHeadline && this.offers.length === 1 && this.offers[0].topic === this.currentOffer.topic) return; // skip if there's only 1 offer

      this.transitionForHeadline = transitionsForHeadline[0];
      this.currentHeadline = undefined;
      clearInterval(this.headlineTimer);

      this.index = this.index + 1 >= this.offers.length ? 0 : this.index + 1;
      this.currentOffer = this.offers[this.index];
      var numHeadlines = this.updateHeadline();

      this.$gtag.event('watched_offer', {
        'event_category': 'engagement',
        'non_interaction': true
      })
      // Amplitude.getInstance().logEvent('Watched Offer')

      const self = this;
      setTimeout(() => {
        if (self.index + 1 >= self.offers.length) 
          self.configOffers()
        else 
          self.updateOffer();
      }, headlineDuration * numHeadlines);
    },
    clickedOffer: function() {
      this.$gtag.event('clicked_offer', {
        'event_category': 'engagement',
        'event_label': this.currentHeadline,
      })
      Amplitude.getInstance().logEvent('Clicked Offer', {
        'Offer': this.currentHeadline
      })
    },
    /* mute: function() {
      this.$store.commit('updateMute', !this.shouldMute);
    }, */
    toggleAudio: function() {
      /* if (this.platformType === 'mobile' && !this.forceMic == 1) {
        this.showWalkieTalkieError = true;
        return;
      } */
      
      this.$refs.walkietalkie.disabled = true; // prevent doubletaps
      this.$store.commit('toggleMute');
      setTimeout(() => {
        this.$refs.walkietalkie.disabled = false;
      }, 1000);
    },
    toggleVideo: function() { 
      this.$emit('toggleVideo')
    },
    share: function() {
      // Skip auto invite on mobile
      /* if (this.platformType != 'desktop' && this.numShares == 0) {
        this.numShares++
        this.showShareMobile = true
        return
      } */

      const copyShareLinkToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
          document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
          document.getSelection().removeAllRanges();
          document.getSelection().addRange(selected);
        } 
      };

      /* let data = {
        hostID: this.$store.state.fanfest.registration.host.objectId,
        eventID: this.$store.state.fanfest.registration.event,
        fanRegistrationID: this.$store.state.fanfest.registration.objectId,
      };

      let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/register/invite`, data)
      let url = response.data.link */

      if (this.platformType == 'desktop'){
        this.showShare = true
        copyShareLinkToClipboard(this.shareLink)
      }      
      else if (navigator.canShare) {
        const shareData = {
          title: `${this.groupName} FanFest`,
          //text: '',
          url: this.shareLink,
        }
        
        navigator.share(shareData).catch(() => {
          copyShareLinkToClipboard(this.shareLink)
          this.showShare = true
        })
        
        this.showShareMobile = false
      }      
      else {
        console.log('share dev mode: '+this.shareLink)
        this.showShareMobile = false
      }

      if (this.numShares > 0) {
        this.$gtag.event('invite_created', {
          'event_category': 'growth',
        })
        Amplitude.getInstance().logEvent('Invite Created')
      }
      
      this.numShares++
    },   
    toggleSidebar: function(content) {
      // interim queue implementation
      if (content === 'queue') {
        this.requestPromotion()
        return
      }

      this.$store.dispatch('updateSidebar', content)
    },
    requestPromotion: function() {
      // interim queue implementation
      let confirmation = confirm(this.$t('alert.joinQueue'))
      if (!confirmation) return

      let message = '✋'
      this.chatService.sendMessageToRoom(message, (error, result) => {
        if (error) {
          alert("Failed to request promotion", error);
          return;
        }

        console.log("Sent promotion request to room with status "+result.status) });
        
        this.$gtag.event('joined stage queue', {
          'event_category': 'engagement',
        })
        Amplitude.getInstance().logEvent('Joined Stage Queue');
        
        if (!this.isChatVisible)
          this.$store.dispatch('updateSidebar', 'chat')
    },
    /* hostClickedFFVOD: function() {
      if (this.hasHost && !this.isHost) {
        alert(this.$t('alert.errorMultipleHost'))
        return
       }

      this.$store.commit('toggleFFVOD');
    }, */
  },
  created: function() {
    this.configOffers();
  },
  mounted: function() {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    })
  }
};

</script>

<style>
/* .banner-fixed {
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 5%;
}

.banner-text-sm {
  font-size: 1.5vw;
  font-weight: 800;
}

.banner-text-lg {
  font-size: 2vw;
  font-weight: 800;
}

.banner-image {
  width: 75%;
} */

/* .topic {
  background-color: #9e00ff;
}

.topic-live {
  background-color: red;
} */

#cta {
  background-color: #7900c9;
}

#cta:hover {
  background-color: #a51cff;
}

/* #offer:hover {
  background-color:#424242;
} */

@media only screen and (min-width: 575px) {
  .new-banner {
    font-size: 1.5vw;
    height: 8vw;
  }

  .new-banner-button {
    font-size: 2vw;
    width: 4vw;
    height: 4vw;
  }
}

@media only screen and (max-width: 575px) {
  .new-banner {
    font-size: 3vw;
    height: 20vw;
  }

  .new-banner-button {
    font-size: 6vw;
    width: 15vw;
    height: 13vw;
    padding: none;
    /* display: none; */
  }
}

.new-banner {
  /*height: 6vw;*/
  /*font-size: 1.5vw;*/
  font-weight: 700;
  background-color: #191919;
  overflow: hidden;
}


.new-banner-image {
  height: 6vw;
  /* background-color: black; */
  padding: 5%;
  margin-right: 5px;
}

.new-banner-button {
  border: none;
  border-radius: 50%;
  padding: none;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-1000px);
  opacity: 0;
}
</style>