import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    profile: {
      live: 'live',
      seats: 'seats left',
      createButton: 'enter',
      chooseButton: 'choose',
      inviteButton: 'watch with'
    },
    register: {
      firstNamePlaceholder: 'First Name',
      lastNamePlaceholder: 'Last Name',
      agreement: 'I agree to the',
      agreementLink: 'Privacy Policy',
      cancelButton: 'cancel',
      purchaseButton: 'pay',
      registerButton: 'register',
      success: 'You are registered.',
      error: 'An error occurred.',
      registrationLink: 'Click here to enter event now.',
      checkEmail: 'Open your email to enter event now.',
      checkEmailLater: 'You will receive an email with access instructions 48 hours before the virtual event.',
      disclaimer: 'By joining this video room you hereby consent to being taped or filmed for the program. You agree that your participation authorizes the {channel}, distributors and assignees, the right, but not the obligation, to broadcast, re-broadcast, distribute, transmit, perform, reproduce, and make derivative works containing, your image and/or statements you make during the program, in any manner and in any medium now known or developed in the future throughout the universe in perpetuity, and that you will not be entitled to any compensation whatsoever.',
      newsletter: "I want to receive {group} communications",
    },
    chat: {
      placeholder: 'say hello',
      send: 'send',
      welcomeMessage: 'Hi!'
    },
    alert: {
      welcomeGoLive: `Click below to go live and join this {group} show!`,
      welcomeTestConnection: `Test my connection`,
      welcomeMobile: "Use text chat to communicate!",
      welcomeErrorClient: `Oops! FanFest is incompatible with this browser on this device. Please try another browser or device to join this {group} show.`,
      invite: 'Share this room with friend! Invite link copied to clipboard.',
      inviteMobile: 'Welcome! Tap to invite family and friends!',
      joinQueue: `Request to join the show with your camera?`,
      errorMobileVoiceChat: 'Voice chat is only available on computers and tablets',
      errorMultipleHost: 'Error: Another moderator already controlling content!',
    },
    tooltip: {
      invite: 'Invite Friends',
      mute: 'Mute/Unmute Streamers',
      walkietalkie: 'Talk Mode <> Watch Mode',
      mic: "Mic On/Off",
      content: 'Show/Hide Video',
      host: 'Control Live Content',
      streamers: 'Click Streamer For Audio',
    },
    admin: {
      editprofilebutton: "Edit Profile",
      adminsbutton: "Admins",
      adminemailstext: "Admin Emails/Usernames",
      logotext: "Logo",
      replaceimagetext: "Replace Image",
      browsebutton: "Browse",
      landingpageimagetext: "Landing Page Image",
      eventbackgroundtext: "Event Background Image",
      savebutton: "Save",
      eventsbutton: "Events",
      createeventbutton: "Create Event",
      basicseventinfotext: "Basics: Event Info",
      freetoggle: "Free",
      paidtoggle: "Paid",
      activecheckbox: "Active",
      eventtypetext: "Event Type",
      fansjoinsametext: "Fans Join Same Public Room",
      fanscreatemanytext: "Fans Create Many Private Rooms",
      vipstext: "Host & VIPs",
      vipsemailstext: "Host & VIPs Emails",
      visiblefanstext: "Visible Guests",
      visiblefanemailstext: "Visible Guest Emails",
      savebutton1: "Save",
      backbutton: "Back",
      offersbutton: "Offers",
      createofferbutton: "Create Offer",
      offertexttext: "Offer Text",
      enterheadlinetext: "Enter Headline",
      enterofferdetailtext: "Enter Offer Detail",
      offerlogotext: "Offer Logo",
      replacelogotext: "Replace Logo",
      offerurltext: "Offer URL",
      enterofferdestinationurltext: "Enter Offer Destination URL",
      videosbutton: "Videos",
      addvideofromyoutubetext: "Add Video From YouTube",
      enteryoutubelinktext: "Enter Youtube Link",
      uploadbutton: "Upload",
      uploadvideofromdevicetext: "Upload Video From Device",
      choosevideotouploadtext: "Choose Video To Upload",
      choosevideostext: "Choose Videos",
    }
  },

  fr: {
    profile: {
      live: 'en direct',
      seats: 'places restantes',
      createButton: "Je M'inscris",
      chooseButton: 'choisir',
      inviteButton: "Profitez avec",
    },
    register: {
      firstNamePlaceholder: 'Prénom',
      lastNamePlaceholder: 'Nom',
      agreement: "J’accepte",
      agreementLink: "la charte de bonne utilisation du service FanFest et les règles de modération de contenu",
      cancelButton: 'annuler',
      purchaseButton: 'acheter pour',
      registerButton: 'entrer',
      success: "Votre inscription a bien été prise en compte. Un mail de confirmation a été envoyé sur votre adresse email avec le lien de connexion pour l’évènement.",
      error: 'Error.',
      checkEmail: "Ouvrir votre e-mail pour obtenir le lien pour entrer l'événement maintenant.",
      registrationLink: 'Clique ici pour accéder à l’événement.',
      disclaimer: "En rejoignant cette salle vidéo, vous consentez par la présente à être enregistré ou filmé pour le programme. Vous acceptez que votre participation autorise {channel} et leurs licenciés, distributeurs et ayants droit, le droit, mais non l'obligation, de diffuser, rediffuser, distribuer, transmettre, exécuter, reproduire et créer des œuvres dérivées contenant votre image et / ou les déclarations que vous faites pendant le programme, de quelque manière et sur tout support maintenant connu ou développé dans le futur dans tout l'univers à perpétuité, et que vous n'aurez droit à aucune compensation de quelque nature que ce soit.",
      newsletter: "J’accepte de recevoir des informations et communications de la part de {group}",
    },
    chat: {
      placeholder: 'Présentez-vous',
      welcomeMessage: 'Salut!'
    },
    alert: {
      welcomeGoLive: `Clique ici pour accéder au live et rejoindre la visio-conférence {group}!`,
      welcomeMobile: "Faites pivoter votre téléphone vers le chat vidéo!",
      welcomeTestConnection: `Tester ma connexion`,
      welcomeErrorClient: `Oups! FanFest est incompatible avec ce navigateur et / ou appareil. Veuillez ouvrir Chrome sur un ordinateur pour rejoindre la visio-conférence {group}.`,
      invite: "Partage cette visio conférence avec tes membres! Ton lien est copié automatiquement, tu n'as plus qu'à le coller dans un message et l'envoyer à tes amis!",
      inviteMobile: 'Bienvenue! Cliquez ici pour inviter votre famille et vos amis!',
      joinQueue: `Demander à rejoindre l'émission avec votre caméra ?`,
      errorMobileVoiceChat: "Le chat audio n'est disponible que sur les ordinateurs et les tablettes",
      errorMultipleHost: 'Erreur: un autre modérateur contrôle déjà le contenu!',
    },
    tooltip: {
      invite: 'Inviter des fans',
      mute: 'Muet tout le monde',
      cam: "Caméra marche/arrêt",
      mic: "Micro marche/arrêt",
      content: 'Vidéos afficher/masquer',
      host: 'Contrôler les vidéos pour tous',
      streamers: 'Cliquer pour le son'
    },
    admin: {
      editprofilebutton: "Editer profil",
      adminsbutton: "Administrateur",
      adminemailstext: "Adresse Mail Admin",
      logotext: "Logo",
      replaceimagetext: "Remplacer Image",
      browsebutton: "Naviguer",
      landingpageimagetext: "Image Page d'Accueil",
      eventbackgroundtext: "Image de Fond Evènement",
      savebutton: "Enregistrer",
      eventsbutton: "Evènements",
      createeventbutton: "Créer Evènement",
      basicseventinfotext: "Basiques: Informations de l'évènement",
      freetoggle: "Gratuit",
      paidtoggle: "Payé",
      activecheckbox: "Actif",
      eventtypetext: "Type d'évènement",
      fansjoinsametext: "Fans rejoignez la même salle publique",
      fanscreatemanytext: "Fans créer plusieurs salles privées",
      vipstext: "Membres VIP",
      vipsemailstext: "Adresses mail des VIP",
      visiblefanstext: "Fans Visibles",
      visiblefanemailstext: "Adresses mail des Fans Visibles",
      savebutton1: "Enregistrer",
      backbutton: "Retour",
      offersbutton: "Offres",
      createofferbutton: "Créer Offre",
      offertexttext: "Offre texte",
      enterheadlinetext: "Ajouter Titre",
      enterofferdetailtext: "Ajouter Détails de l'Offre",
      offerlogotext: "Logo Offre",
      replacelogotext: "Remplacer Logo",
      offerurltext: "URL Offre",
      enterofferdestinationurltext: "EntrerURL Destination de l'Offre",
      videosbutton: "Vidéos",
      addvideofromyoutubetext: "Ajouter Vidéo YouTube",
      enteryoutubelinktext: "Entrer Lien YouTube",
      uploadbutton: "Télécharger",
      uploadvideofromdevicetext: "Télécharger Vidéo Depuis un Appareil",
      choosevideotouploadtext: "Choisir Vidéo à Télécharger",
      choosevideostext: "Choisir Vidéos",
    }
  },

  es: {
    alert: {
      welcomeGoLive: "¡Haz clic para unirte a este show!",
      welcomeMobile: "¡Gira tu teléfono para chatear con video!",
      welcomeTestConnection: `Probar mi conexión`,
      welcomeErrorClient: "Que pena! Fanfest es incompatible con este navegador y / o dispositivo. Favor de abrir Chrome en una computadora para unirte a este show.",
      invite: "Comparte este cuarto con tus amigos! Enlace de invitación esta copiado.",
      inviteMobile: '¡Bienvenidos! Toca para invitar a fans.',
      joinQueue: '¿Listo para unirte al show con tu cámara?',
      errorMobileVoiceChat: 'El chat de voz solo está disponible en computadoras y tabletas',
      errorMultipleHost: 'Error: ¡Otro moderador ya controla el contenido!',
    }, chat: {
      placeholder: "Di hola",
      send: 'enviar',
      welcomeMessage: '¡Hola!'
    }, profile: {
      live: "En Vivo",
      seats: "Asientos Libres",
      createButton: "Regístrate",
      chooseButton: 'seleccionar',
      inviteButton: "Unirse a",
    }, register: {
      firstNamePlaceholder: "Nombre",
      lastNamePlaceholder: "Apellido",
      agreement: "Estoy de acuerdo con ",
      agreementLink: "La Política de Privacidad",
      cancelButton: "Cancelar",
      purchaseButton: "Comparar ",
      registerButton: "Regístrarte",
      success: "Te has registrado correctamente",
      error: "Hay un error.",
      registrationLink: "Clic aquí para entrar el evento ahora. ",
      checkEmail: "Abre tu correo electrónico y obtendrás el enlace para acceder al evento.",
      disclaimer: "Al unirse a esta video, usted da su consentimiento para ser grabado o filmado para el programa. Usted acepta que su participación autoriza a {channel} y a sus concesionarios distribuidores y cesionarios el derecho, pero no la obligación, de transmitir, retransmitir, distribuir, transmitir reproducir, reproducir y realizar trabajos derivados que contengan su imagen y / o declaraciones que realice durante el programa de cualquier manera y en cualquier medio conocido ahora o desarrollado en el futuro en todo el universo a perpetuidad y que no tendrá derecho a compensación alguna.",
      newsletter: "Acepto recibir comunicaciones vía e-email",
    }, tooltip: {
      invite: "Invita Amigos",
      mute: "Silenciar / Activar streamers",
      mic: "Micrófono encendido / apagado",
      content: "Mostrar / Esconder Video",
      host: "Controlar Contenido en Vivo",
      streamers: "Clic el Streamer para audio",
    },
    admin: {
      editprofilebutton: "Editar Perfil",
      adminsbutton: "Administrador",
      adminemailstext: "Correo electrónico Administrador",
      logotext: "Logo",
      replaceimagetext: "Reemplazar Imagen",
      browsebutton: "Navegar",
      landingpageimagetext: "Página de Destino",
      eventbackgroundtext: "Imagen de Fondo del Evento",
      savebutton: "Guardar",
      eventsbutton: "Eventos",
      createeventbutton: "Crear Evento",
      basicseventinfotext: "Basicos: Información del Evento",
      freetoggle: "Gratis",
      paidtoggle: "Pagado",
      activecheckbox: "Activo",
      eventtypetext: "Tipo de Evento",
      fansjoinsametext: "Seguidores Unirse a la  Misma Sala Pública ",
      fanscreatemanytext: "Seguidores Crear Muchas Salas Privadas",
      vipstext: "Miembros VIP",
      vipsemailstext: "Correos Electrónicos VIP",
      visiblefanstext: "Seguidores Visibles",
      visiblefanemailstext: "Correos Electrónicos Seguidores VIP",
      savebutton1: "Guardar",
      backbutton: "Atrás",
      offersbutton: "Ofertas",
      createofferbutton: "Crear Oferta",
      offertexttext: "Ofrecer Texto",
      enterheadlinetext: "Ingresar Título",
      enterofferdetailtext: "Ingresar Detalles de la Oferta",
      offerlogotext: "Ofrecer Logo",
      replacelogotext: "Reemplazar Logo",
      offerurltext: "Ofrecer URL",
      enterofferdestinationurltext: "Ingresar Oferta de Destino URL",
      videosbutton: "Videos ",
      addvideofromyoutubetext: "Agregar Video de YouTube",
      enteryoutubelinktext: "Ingresar Link de YouTube",
      uploadbutton: "Subir",
      uploadvideofromdevicetext: "Subir Video Desde un Dispositivo",
      choosevideotouploadtext: "Escoger Video Para Subir",
      choosevideostext: "Escoger Videos",
    },
  },

  de: {
    alert: {
      welcomeGoLive: "Unten klicken, um Live zu gehen und an dieser Gruppen-Watch-Party teilzunehmen!",
      welcomeMobile: "Drehen Sie Ihr Telefon in den Video-Chat!",
      welcomeErrorClient: "Hoppla! FanFest ist mit diesem Browser und / oder Gerät nicht kompatibel. Bitte öffnen Sie Chrome auf einem Computer, um an dieser Gruppen-Watch-Party teilzunehmen.",
      invite: "Teilen Sie diesen Raum mit einem Freund! Einladungs-Link in die Zwischenablage kopiert.",
    }, chat: {
      placeholder: "Sag Hallo",
    }, profile: {
      live: "Live gehen",
      seats: "Sitzplätze verfügbar",
      createButton: "Raum erstellen",
      inviteButton: "Schau mit",
    }, register: {
      firstNamePlaceholder: "Vorname",
      lastNamePlaceholder: "Nachname",
      agreement: "Ich akzeptiere die ",
      agreementLink: "Datenschutzrichtlinie",
      cancelButton: "Kündigen",
      purchaseButton: "Kauf für  (Or maybe better: Kaufen = Purchase)",
      registerButton: "Registrieren",
      success: "Sie sind registriert",
      error: "Ein Fehler ist aufgetreten.",
      registrationLink: "Hier kilicken, um jetzt teilzunehmen.",
      checkEmail: "Öffnen Sie Ihre E-Mail für den Link, um jetzt an der Veranstaltung teilzunehmen.",
      disclaimer: "Wenn Sie diesem Videoraum beitreten, stimmen Sie hiermit zu, dass Sie für das Programm aufgenommen oder gefilmt werden. Sie erklären sich damit einverstanden, dass Ihre Teilnahme die {channel} und ihre Lizenznehmer-Distributoren und Abtretungsberechtigten zum Recht, aber nicht zur Verpflichtung berechtigt, Re-Broadcast-Distribute-Sendungen zu reproduzieren und abgeleitete Werke zu erstellen, die Ihr Bild und / oder Ihre Aussagen enthalten, die Sie während des Programms auf irgendeine Weise und in irgendeiner Weise machen in jedem Medium, das jetzt bekannt ist oder in Zukunft im gesamten Universum auf Dauer entwickelt wird, und dass Sie keinerlei Anspruch auf Entschädigung haben.",
      newsletter: "Ich möchte den {group}-Newsletter bekommen",
    }, tooltip: {
      invite: "Freunde einladen",
      mute: "Streamers stummschalten / Stummschaltung aufheben",
      mic: "Mikro ein / aus",
      content: "Video zeigen / ausblenden",
      host: "Live Inhalte steuern",
      streamers: "Für Audio auf den Streamer klicken",
    },
  },

  nl: {
    alert: {
      welcomeGoLive: "Klik hieronder om live te gaan en deel te nemen aan deze {group} watch party!",
      welcomeMobile: "Draai je telefoon naar videochat!",
      welcomeErrorClient: "Oeps! FanFest is niet compatibel met deze browser en/of dit apparaat. Open Chrome op een computer om deel te nemen aan deze {group} watch party.",
      invite: "Deel deze kamer met een vriend! Uitnodigingslink gekopieerd naar klembord.",
      inviteMobile: 'Welkom! Klik om familie en vrienden uit te nodigen!',
      errorMobileVoiceChat: "Audiochat is alleen beschikbaar op computers en tablets"
    }, chat: {
      placeholder: "zeg hallo",
    }, profile: {
      live: "live",
      seats: "resterende plaatsen",
      createButton: "ruimte creëren",
      inviteButton: "kijken met",
    }, register: {
      firstNamePlaceholder: "Voornaam",
      lastNamePlaceholder: "Achternaam",
      agreement: "Ik ga akkoord met het",
      agreementLink: "Privacybeleid",
      cancelButton: "annuleren",
      purchaseButton: "aankoop voor",
      registerButton: "aanmelden",
      success: "Je bent aangemeld.",
      error: "Er is een fout opgetreden.",
      registrationLink: "Klik hier om nu aan het evenement deel te nemen.",
      checkEmail: "Open je e-mail voor de link om nu aan het evenement deel te nemen.",
      disclaimer: "Door deel te nemen aan deze videoroom geef je hierbij toestemming om opgenomen of gefilmd te worden voor het programma. Je gaat ermee akkoord dat jouw deelname {channel} en hun erkende distributeurs en rechthebbenden het recht geeft, maar niet de verplichting om uit te zenden, opnieuw uit te zenden, te distribueren, te verzenden, uit te voeren, te reproduceren en afgeleide werken met jouw afbeelding en/of verklaringen die je tijdens het programma op enigerlei wijze en in enig medium dat nu bekend is of in de toekomst ontwikkeld wordt in het hele universum in de eeuwigheid te laten gebruiken en dat je geen recht heeft op welke vergoeding dan ook.",
      newsletter: "Ik wil de {group}-Nieuwsbrieven ontvangen",
    }, tooltip: {
      invite: "Vrienden uitnodigen",
      mute: "Mute/Unmute Streamers",
      mic: "Mic Aan/Uit",
      content: "Toon/verberg video",
      host: "Live-content controleren",
      streamers: "Klik op Streamer voor audio",
    },
  },

  ca: {
    alert: {
      welcomeGoLive: "Fes clic a continuació per a transmetre en viu i unir-te a aquesta festa d'observació de {group}!",
      welcomeMobile: "Gireu el vostre telèfon al xat de vídeo!",
      welcomeErrorClient: "Ups! FanFest és incompatible amb aquest navegador i/o dispositiu. Obre Chrome en una computadora per a unir-te a aquesta festa d'observació de {group}.",
      invite: "Comparteix aquesta habitació amb un amic! Enllaç d'invitació copiat al portapapers.",
    }, chat: {
      placeholder: "diu hola",
    }, profile: {
      live: "En Viu",
      seats: "seients a l'esquerra",
      createButton: "crear espai",
      inviteButton: "mirar amb",
    }, register: {
      firstNamePlaceholder: "Nom",
      lastNamePlaceholder: "Cognom",
      agreement: "Estic d'acord amb la",
      agreementLink: "Política de privacitat",
      cancelButton: "cancel·lar",
      purchaseButton: "compra per a",
      registerButton: "Registrar-se",
      success: "Estàs registrat.",
      error: "Va ocórrer un error.",
      registrationLink: "Faci clic aquí per a ingressar a l'esdeveniment ara.",
      checkEmail: "Obriu el vostre correu electrònic per obtenir l'enllaç per entrar a l'esdeveniment ara.",
      disclaimer: "En unir-se a aquesta sala de vídeo, vostè dóna el seu consentiment per a ser gravat o filmat per al programa. Vostè accepta que la seva participació autoritza {channel} i als seus concessionaris distribuïdors i cessionaris el dret, però no l'obligació, de transmetre retransmetre distribuir transmetre reproduir reproduir i fer treballs derivats que continguin la seva imatge i / o declaracions que realitzi durant el programa de qualsevol manera i en qualsevol mitjà ara conegut o desenvolupat en el futur en tot l'univers a perpetuïtat i que no tindrà dret a cap compensació.",
      newsletter: "Vull rebre els butlletins de {group}",
    }, tooltip: {
      invite: "Convidar a amics",
      mute: "Silenciar/Activar Streamers",
      mic: "Micròfon encès/apagat",
      content: "Mostrar/Ocultar vídeo",
      host: "Control de contingut en viu",
      streamers: "Faci clic en Streamer per a àudio",
    },
  },

  pt: {
    alert: {
      welcomeGoLive: "Clique abaixo para se juntar a esta {group} de show!",
      welcomeMobile: "Gire seu telefone para o chat de vídeo!",
      welcomeErrorClient: "Ops! A FanFest é incompatível com este navegador e/ou dispositivo. Por favor, abra o Google Chrome em um computador para participar deste {group} de show.",
      invite: "Compartilhe esta sala com um amigo! Link de convite copiado para a área de transferência.",
    }, chat: {
      placeholder: "diga olá",
    }, profile: {
      live: "ao-vivo",
      seats: "assentos restantes",
      createButton: "registro",
      chooseButton: 'selecionar',
      inviteButton: "assistir com",
    }, register: {
      firstNamePlaceholder: "Nome",
      lastNamePlaceholder: "Sobrenome",
      agreement: "Eu concordo com a",
      agreementLink: "Política de Privacidade",
      cancelButton: "Cancelar",
      purchaseButton: "comprar para",
      registerButton: "Registrar",
      success: "Você se registrou.",
      error: "Um erro ocorreu.",
      checkEmail: "Abra seu e-mail para obter o link para entrar no evento agora.",
      registrationLink: "Clique aqui para entrar no evento agora.",
      disclaimer: "Ao se juntar a esta sala de vídeo, você consente em ser gravado ou filmado para o programa. Você concorda que sua participação autoriza as {channel} e seus distribuidores licenciados e cede o direito, mas não a obrigação, de transmitir, retransmitir, distribuir, reproduzir e fazer trabalhos derivados contendo sua imagem e/ou declarações que você fizer durante o programa de qualquer maneira e em qualquer meio agora conhecido ou desenvolvido no futuro em todo o universo, perpetuamente, e que você não terá direito a nenhuma compensação de qualquer tipo.",
      newsletter: "Quero receber os boletins de notícias {group}",
    }, tooltip: {
      invite: "Convidar Amigos",
      mute: "Ativar/Desativar Áudio do Streamer",
      mic: "Microfone Lig/Des",
      content: "Mostrar/Esconder Vídeo",
      host: "Controlar Conteúdo Ao-vivo",
      streamers: "Clique no Streamer para Áudio",
    },
  },

  it: {
    alert: {
      welcomeGoLive: "Clicca qui sotto per collegarti in diretta e partecipare a questo watch party di {gruppo}!",
      welcomeMobile: "Ruota il telefono per la chat video!",
      welcomeErrorClient: "Ops! FanFest non è compatibile con questo browser e/o dispositivo. Si prega di aprire Chrome su un computer per partecipare a questo watch party di {gruppo}.",
      invite: "Condividi questa stanza con un amico! Link di invito copiato negli appunti.",
    }, chat: {
      placeholder: "invia un saluto",
    }, profile: {
      live: "live",
      seats: "posti residui",
      createButton: "crea stanza",
      inviteButton: "guarda con",
    }, register: {
      firstNamePlaceholder: "Nome",
      lastNamePlaceholder: "Cognome",
      agreement: "Acconsento alla",
      agreementLink: "Policy sulla privacy",
      cancelButton: "annulla",
      purchaseButton: "acquista per",
      registerButton: "registrati",
      success: "Sei registrato.",
      error: "Si è verificato un errore.",
      checkEmail: "Apri la tua email per il collegamento per accedere all'evento ora.",
      registrationLink: "Clicca qui per accedere subito all’evento.",
      disclaimer: "Entrando in questa sala video acconsenti ad essere registrato o filmato per il programma. Accetti che la la tua partecipazione autorizza {channel}, e i loro licenziatari, distributori e assegnatari il diritto ma non l'obbligo di trasmettere, ri-trasmettere, distribuire, eseguire, riprodurre e creare opere derivate contenenti la tua immagine e/o dichiarazioni nel corso del programma, in qualsiasi modo e con qualsiasi mezzo attualmente conosciuto o sviluppata in futuro, in tutto l'universo, in perpetuo, e che l'utente non avrà diritto ad alcun indennizzo di sorta.",
      newsletter: "Voglio ricevere le newsletter {group}",
    }, tooltip: {
      invite: "Invita Amici",
      mute: "Silenzia/abilita streamer",
      mic: "Microfono On/Off",
      content: "Mostra/Nascondi Video",
      host: "Controlla Il Contenuto Live",
      streamers: "Clicca Sullo Streamer Per L'Audio",
    },
  },
  ar: {
    profile: {
      live: 'يعيش',
      seats: 'مقاعد خالية',
      createButton: 'أدخل',
      chooseButton: 'أختر',
      inviteButton: 'شاهد مع'
    },
    register: {
      firstNamePlaceholder: 'الاسم الأول',
      lastNamePlaceholder: 'الكنية',
      agreement: 'أنا أوافق على',
      agreementLink: 'سياسة الخصوصية',
      cancelButton: 'إلغاء',
      purchaseButton: 'دفع',
      registerButton: 'تسجيل',
      success: 'أنت مسجل.',
      error: 'حدث خطأ.',
      registrationLink: 'انقر هنا لإدخال الحدث الآن.',
      checkEmail: 'افتح بريدك الإلكتروني لإدخال الحدث الآن.',
      checkEmailLater: 'سوف تتلقى رسالة بريد إلكتروني مع تعليمات الوصول قبل 48 ساعة قبل الحدث الظاهري.',
      // disclaimer: 'By joining this video room you hereby consent to being taped or filmed for the program. You agree that your participation authorizes the {channel}, distributors and assignees, the right, but not the obligation, to broadcast, re-broadcast, distribute, transmit, perform, reproduce, and make derivative works containing, your image and/or statements you make during the program, in any manner and in any medium now known or developed in the future throughout the universe in perpetuity, and that you will not be entitled to any compensation whatsoever.',
      newsletter: "أريد تلقي اتصالات {group}",
    },
    chat: {
      placeholder: 'قل مرحبا',
      send: 'يرسل',
      welcomeMessage: 'أهلاً!!'
    },
    alert: {
      welcomeGoLive: `انقر أدناه للذهاب مباشر وانضم إلى هذا العرض {group}!`,
      welcomeTestConnection: `اختبار اتصالي`,
      welcomeMobile: "استخدم الدردشة النصية للتواصل!",
      welcomeErrorClient: `أُووبس! Fanfest غير متوافق مع هذا المتصفح على هذا الجهاز. يرجى تجربة متصفح أو جهاز آخر للانضمام إلى هذا العرض {group}.`,
      invite: 'مشاركة هذه الغرفة مع صديق! دعوة رابط نسخ إلى الحافظة.',
      inviteMobile: 'أهلا بك! اضغط لدعوة الأسرة والأصدقاء!',
      joinQueue: `طلب الانضمام إلى العرض مع الكاميرا؟`,
      errorMobileVoiceChat: 'الدردشة الصوتية متوفرة فقط على أجهزة الكمبيوتر والأجهزة اللوحية',
      errorMultipleHost: 'خطأ: مشرف آخر يتحكم بالفعل في المحتوى!',
    },
    tooltip: {
      invite: 'ادعو أصدقاء',
      mute: 'كتم / لا قول',
      walkietalkie: 'وضع الكلام <> وضع مشاهدة',
      mic: "مايكروفون على / قبالة",
      content: 'إظهار / إخفاء الفيديو',
      host: 'السيطرة على المحتوى المباشر',
      streamers: 'انقر فوق أزلاء للصوت',
    },
    admin: {
      editprofilebutton: "تعديل الملف الشخصي",
      adminsbutton: "مدراء",
      adminemailstext: "طب العائلات",
      logotext: "شعار",
      replaceimagetext: "استبدال الصورة",
      browsebutton: "تصفح",
      landingpageimagetext: "صورة الصفحة الرئيسية",
      eventbackgroundtext: "صورة خلفية الحدث",
      savebutton: "يحفظ",
      eventsbutton: "الأحداث",
      createeventbutton: "انشاء حدث",
      basicseventinfotext: "أساسيات: معلومات الحدث",
      freetoggle: "حر",
      paidtoggle: "دفع",
      activecheckbox: "نشيط",
      eventtypetext: "نوع الحدث",
      fansjoinsametext: "المشجعين انضم إلى نفس الغرفة العامة",
      fanscreatemanytext: "المشجعين إنشاء العديد من الغرف الخاصة",
      vipstext: "المضيف و VIPS.",
      vipsemailstext: "مضيف ومحبوب رسائل البريد الإلكتروني",
      visiblefanstext: "الضيوف المرئي",
      visiblefanemailstext: "رسائل البريد الإلكتروني المرئية",
      savebutton1: "يحفظ",
      backbutton: "خلف",
      offersbutton: "عروض",
      createofferbutton: "إنشاء عرض",
      offertexttext: "عرض النص",
      enterheadlinetext: "أدخل العنوان",
      enterofferdetailtext: "أدخل عرض التفاصيل",
      offerlogotext: "عرض logo.",
      replacelogotext: "استبدال الشعار",
      offerurltext: "عرض عنوان URL",
      enterofferdestinationurltext: "أدخل عنوان URL المقصود",
      videosbutton: "أشرطة فيديو",
      addvideofromyoutubetext: "إضافة فيديو من YouTube",
      enteryoutubelinktext: "أدخل رابط YouTube",
      uploadbutton: "تحميل",
      uploadvideofromdevicetext: "تحميل الفيديو من الجهاز",
      choosevideotouploadtext: "اختر الفيديو لتحميل",
      choosevideostext: "اختيار مقاطع الفيديو",
    }
  },
}

console.log(`language: ${window.navigator.language}`)

const i18n = new VueI18n({
  locale: window.navigator.language, // set locale
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages // set locale messages
})

export default i18n;