<template lang="pug">
  #client(:style="profileCustomAppearance")
    b-container(fluid).h-100.p-0
      b-row.h-100(align-v="center")
        Video(v-if="loaded", :hasDevice="hasDevice")
        
        CookieLaw(theme='blood-orange' position='top' transitionName='slideFromTop')
</template>

<script>
import Video from "./Video.vue";
import axios from "axios";
import bowser from "bowser";
import CookieLaw from 'vue-cookie-law'
import LogRocket from "../services/logrocket";
import UserLeap from "../services/userleap";
import Amplitude from "../services/amplitude";
import Parse from "../services/parse";

export default {
  name: "client",
  metaInfo() {
    return {
      title: `${this.title}`,
    };
  },
  data() {
    return {
      loaded: false,
      platformCheck: true,
      platformType: null,
      osName: null,
      windowRatio: 0.0,
      hasDevice: false,
      title: "Welcome to FanFest",
    };
  },
  computed: {
    profileCustomAppearance: function() {
      if (!this.$store.state.fanfest.config) return {}

      if (this.$store.state.fanfest.config.name === '49ers') {
       return {
          'font-family': "factoria, serif",
          'font-weight': 700,
          'font-style': 'bold',
        }        
      }

      return {}
    }
  },
  components: {
    Video, CookieLaw
  },
  sockets: {
    connect: function () {
      console.log("socket connected!");
      if (this.loaded) {
        console.log('resending client info to sockets...')
        this.configSockets() // reconnect to sockets
      }
    },
    reload: function () {
      window.location.reload();
    },
    hostMute: function() {
      // ignore VIPs
      if (this.$store.state.fanfest.isVIP) return 
      
      this.$store.commit('muteStreamers', true)
    },
    bootFFUser: function(registrationID) {
      if (registrationID != this.$store.state.fanfest.registration.objectId) return
      window.location.reload()
    },
    banFFUser: function(registration) {
      if (registration == this.$store.state.fanfest.registration.objectId) {
        axios.post(`${process.env.VUE_APP_BACKEND}/app/live/banMe`, {
          registration: registration,
        });
        this.$store.commit("banFFUser");
        this.$router.push({ path: "error" });
      }
    },
    hideFFUser: async function(registrationID) {
      if (registrationID != this.$store.state.fanfest.registration.objectId) return

      let registrationResponse = await axios(
        `${process.env.VUE_APP_BACKEND}/app/register/${registrationID}`
      );
      let registration = registrationResponse.data;      
      if (registration.canStream) this.$store.commit("enableStreaming");
      else this.$store.commit("disableStreaming");
      if (registration.canHost) this.$store.commit("enableHost");
      if (registration.isVIP || this.$route.query.vip) {
        this.$store.commit("enableVIP");
        if (this.platformType === 'mobile') 
          this.$store.commit("forceMic");
      }
      this.$store.commit("updateRegistration", registration);
    },
     goBackstage: async function(registrationID) {
      if (registrationID != this.$store.state.fanfest.registration.objectId) return
      
      let confirmation = await this.$bvModal.msgBoxOk(`${this.$store.state.fanfest.config.group.name.trim()} invited you backstage.\nReady to go?`, {
        title: 'Go Backstage',
        size: 'sm',
        centered: true
      })

      if (!confirmation) return
      
      window.location.assign(`${window.location.href}&staging=1`)
    },
  },
  created: function () {
    this.checkClient()
  },
  mounted: function () {
  },
  methods: {
    checkClient: function () {
      console.log(`entered fanfest @ ${window.location.href}`)

      const browser = bowser.getParser(window.navigator.userAgent);
      console.log(
        "client: " + JSON.stringify(bowser.parse(window.navigator.userAgent))
      );

      // check device
      let platformType = browser.getPlatformType();
      console.log(`platform: ${platformType}`);

      // double-check device for spoofing
      /* let hasMobileTouchscreen = function () {
      try { document.createEvent("TouchEvent"); console.log(`touchscreen: yes`); return true; }
      catch(e){ console.log(`touchscreen: no`); return false; }
    } */

     /*  let hasMobileViewport = window.matchMedia(
        "only screen and (max-width: 400px)"
      ).matches; */

      // check browser
      let browserName = browser.getBrowserName();
      let osName = browser.getOS().name
      console.log(`browser: ${browserName} ${JSON.stringify(osName)}`);

      if (browserName != 'Safari' && osName == 'iOS') { // ignore Chrome on iOS
        this.platformCheck = false;
        // console.log('Skipping client check...')
      }
      else if (browserName == 'Firefox')
        this.platformCheck = false;

      this.platformType = platformType;
      this.osName = osName
      this.$store.commit("configPlatform", this.platformType);

      this.configClient().catch((error) => {
        console.log(error.message);
        this.$router.push({ name: "fanfest-home" });
      });
    },
    configClient: async function () {
      // check embed vs hosted
      if (this.$route.query.origin) this.$store.commit("enableEmbedMode");

      // check if backstage
      if (this.$route.query.staging) this.$store.commit("enableBackstageMode");

      // check for registration
      let registrationID = this.$route.query.r;
      if (!registrationID) this.$router.push({ name: "fanfest-home" });

      // get registration
      let registrationResponse = await axios(
        `${process.env.VUE_APP_BACKEND}/app/register/${registrationID}`
      );
      let registration = registrationResponse.data;

      if (!registration) this.$router.push({ name: "fanfest-home" });
      if (registration.isBanned) this.$router.push({ name: "error" });

      this.$store.commit("updateRegistration", registration);
      let fan = registration.fan;

      // config screenname
      let screenName = `${fan.name}`;
      this.$store.commit("updateScreenName", screenName);

      // config analytics
      LogRocket.identify(fan.objectId, {
        name: `${fan.fname} ${fan.lname}`,
        email: fan.email,
      });
      this.$gtag.config({
        'user_id': fan.objectId
      })
      UserLeap('setUserId', fan.objectId);
      UserLeap('setEmail', fan.email);
      UserLeap('setAttribute', 'name', `${fan.fname} ${fan.lname}`);
      UserLeap('setAttribute', 'profile', registration.profile.trim());
      UserLeap('setAttribute', 'episode', `${registration.eventTitle.trim()} (${registration.event.trim()})`)
      
      
      Amplitude.getInstance().setUserId(fan.objectId);
      Amplitude.getInstance().setUserProperties({
        'Name': `${fan.fname} ${fan.lname}`,
        'Email': fan.email,
        'Profile': registration.profile.trim(),
        'Episode': `${registration.eventTitle.trim()} (${registration.event.trim()})`,
      })

      // config streaming
      if (!this.platformCheck) this.$store.commit("flagInvalidClient");
      if (registration.canStream) this.$store.commit("enableStreaming");
      if (registration.canHost) this.$store.commit("enableHost");
      if (registration.isVIP || this.$route.query.vip) {
        this.$store.commit("enableVIP");
        if (this.platformType === 'mobile') 
          this.$store.commit("forceMic");
      }
      if (this.$route.query.forceMic == 1) this.$store.commit("forceMic");
      // if (this.platformType === 'mobile' && !this.$route.query.forceMic == 1) this.$store.commit("muteStreamers", true); // disables mic footer icon on mobile

      // config client
      let self = this;
      let fanfest = this.$route.params.fanfest;

      let clientResponse = await axios.get(
        `${process.env.VUE_APP_CONFIG_ROOT}/${fanfest}/id`
      );

      self.$store.commit(
        "configClient",
        clientResponse.data.organizer,
        this.platformType
      );
      self.$store.commit("configFanfest", clientResponse.data.fanfest);

      // config share link
      let data = {
        hostID: this.$store.state.fanfest.registration.host.objectId,
        eventID: this.$store.state.fanfest.registration.event,
        fanRegistrationID: this.$store.state.fanfest.registration.objectId,
        clientURL: this.$route.query.origin ? decodeURI(this.$route.query.origin) : `${window.location.protocol}//${window.location.hostname}`
      };

      let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/register/invite`, data)
      let url = response.data.link
      self.$store.commit("configShareLink", url);

      // get and config event
      let eventQuery = new Parse.Query('FanfestEvent')
      let event = await eventQuery.get(registration.event).catch((e)=>{
        console.error(`Error fetching event data on load: ${e.toJSON()}`)
      })
      self.$store.commit("configEvent", event.toJSON());

      self.configSockets();

      self.title = `${this.$store.state.fanfest.screenName} @ ${this.$store.state.fanfest.registration.eventTitle}`;

      self.$gtag.event('enter_fanfest', {
        'event_category': 'growth',
      })
      Amplitude.getInstance().logEvent('Entered FanFest');
    },
    configSockets: function () {
      const self = this;

      // socket for entire group
      let groupRoom = `${self.$store.state.client.group.objectId}`
      
      // socket for content = event (same for all rooms)
      let eventRoom = `Event-${self.$store.state.fanfest.registration.event}`
      
      // socket for streamers = event + host
      let fanRoom = `Fan-${self.$store.state.fanfest.config.name}-${self.$store.state.fanfest.registration.event}-${self.$store.state.fanfest.registration.host.objectId}`
      
      let roomForFans = fanRoom;
      let roomForContent = eventRoom ? eventRoom : groupRoom;

      if (this.$route.query.staging) {
        roomForFans += '-staging'
        roomForContent += '-staging'
        if (this.$store.state.fanfest.registration.canHost) this.$store.commit("enableStreaming");
      }
      
      if (process.env.NODE_ENV != "production" || window.location.href.indexOf('staging.fanfest.io') >= 0 || window.location.href.indexOf('dev.fanfest.io') >= 0) {
        roomForFans += '-dev'
        roomForContent += '-dev'
      }

      // self.$socket.emit("groupRoom", groupRoom);
      self.$socket.emit("eventRoom", roomForContent);
      self.$socket.emit("fanRoom", roomForFans);

      console.log(`room for fans ${roomForFans}`)
      console.log(`room for content ${roomForContent}`)

      self.$store.commit("configSockets", {
        content: roomForContent,
        fans: roomForFans
      });

      /* self.$socket.emit(
        "businessRoom",
        self.$store.state.client.business.objectId
      ); */      
      /* self.$socket.emit(
        "organizerRoom",
        self.$store.state.client.organizer.objectId
      ); */

      self.loaded = true;
    },

    configAppearance: function() {

    }
  },
};
</script>

<style>
#client {
  height: 100vh;
  background-color: black;
}
/*body,
html {
  background-color: black;
  min-height: 100%;
}*/

/*.wrapper-center-outer {
  display: table;
  height: 100vh;
  width: 100vw;
}

.wrapper-center-inner {
  display: table-cell;
  vertical-align: middle;
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  background-color: black;
  position: relative;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}*/
</style>