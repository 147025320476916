let loadUserLeap = function(l,e,a,p) {
    window.UserLeap = function(){U._queue.push(arguments)}
    var U = window.UserLeap;U.appId = a;U._queue = [];
    a=l.createElement('script');
    a.async=1;a.src=e+'?id='+U.appId;
    p=l.getElementsByTagName('script')[0];
    p.parentNode.insertBefore(a, p);
}

let appID = process.env.NODE_ENV === 'development' ? 'KpCm2PbZr' : '7MvkKzzhe3'
loadUserLeap(document, 'https://cdn.userleap.com/shim.js', appID)

let UserLeap = window.UserLeap
export default UserLeap