import Vue from 'vue'
import Router from 'vue-router';

import Parse from '@/services/parse.js'

import Error from '@/components/Error.vue'

// FanFest
import Profile from '@/components/Profile'
import Client from '@/components/Client.vue'

// Portal
import DashboardLogin from '@/components/DashboardLogin.vue'
import Dashboard from '@/components/Dashboard.vue'
import DashboardPreview from '@/components/DashboardPreview.vue'

// Admin
import ProfileAdmin from '@/components/admin/ProfileAdmin.vue'
import EventList from '@/components/admin/EventList.vue'
import EventEditor from '@/components/admin/EventEditor.vue'
import OfferEditor from '@/components/admin/OfferEditor.vue'
import VideoPreview from '@/components/admin/VideoPreview'
import SDK from '@/components/SDK'
import Twitter from '@/components/demo/Twitter'
import BulkRegister from '@/components/admin/BulkRegister'

// Beta
// import ProfileList from '@/components/admin/ProfileList.vue'

Vue.use(Router)

const routes = [
  /*{
    path: '/god',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      { 
        path:'register', 
        component: BulkRegister
      },
      { 
        path:':fanfest/event/:event', 
        component: EventEditor
      },
      { 
        path:':fanfest/events', 
        component: EventList
      },
      { 
        path:':fanfest/video/:vod', 
        component: VideoPreview
      },
      { 
        path:':fanfest/offer/:offer', 
        component: OfferEditor
      },
      { 
        path:':fanfest', 
        component: ProfileAdmin
      }, 
      { 
        path:'/', 
        component: ProfileList
      }
    ]        
  },*/
  {
    path: '/demo',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      { 
        path:'sdk', 
        component: SDK
      },
      { 
        path:'twitter', 
        component: Twitter
      },
    ]        
  },
  {
    path: '/admin',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      { 
        path:'register', 
        component: BulkRegister
      },
      { 
        path:':fanfest/event/:event', 
        component: EventEditor
      },
      { 
        path:':fanfest/events', 
        component: EventList
      },
      { 
        path:':fanfest/video/:vod', 
        component: VideoPreview
      },
      { 
        path:':fanfest/offer/:offer', 
        component: OfferEditor
      },
      { 
        path:':fanfest', 
        component: ProfileAdmin
      }
    ]        
  },
  {
    path: '/client-error',
    name: 'client-error',
    component: Error,
    props: { message: 'fanfest works in chrome browser on computers (for now)' }
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    props: { message: '' }
  },
  {
    path: '/dashboard/preview/:vod',
    name: 'dashboardpreview',
    component: DashboardPreview,
    beforeEnter: (to, from, next) => {
      if (!Parse.User.current()) {
        next({ name: 'login' })
      }
      else next()
    }
  },
  {
    path: '/dashboard/login',
    name: 'login',
    component: DashboardLogin,
    beforeEnter: (to, from, next) => {
      if (Parse.User.current()) {
        next({ name: 'dashboard' })
      }
      else next()
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (!Parse.User.current()) {
        next({ name: 'login' })
      }
      else next()
    }
  },
  /* {
    path: '/:fanfest/login',
    name: 'fanfest-login',
    component: FanFestLogin,
  }, */
  {
    path: '/:fanfest/live',
    component: Client,
  },
  {
    path: '/:fanfest',
    name: 'fanfest-home',
    component: Profile,
  },
  {
    path: '*',
    component: Error,
    props: { message: '' }
  }
];

const router = new Router({
  mode: 'history',
  routes
});

export default router;