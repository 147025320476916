<template lang="pug">
.bg
  //- h5.text-muted.text-center.mt-5 {{ $t('admin.addvideofromyoutubetext') }}
  //- b-form-input.my-2(v-model='newVideoURL' :placeholder="$t('admin.enteryoutubelinktext')" trim autocomplete='off')
  //- b-button(ref='uploadButton' block variant='primary' @click='uploadYT' :disabled="newVideoURL.indexOf('youtube.com') >= 0 ? false : true") {{ $t('admin.uploadbutton') }}
  
  h5.text-muted.text-center.mt-5 {{ $t('admin.uploadvideofromdevicetext') }}
  //- b-form-input.my-2(v-model='newVideoURL' placeholder='Select Video' trim autocomplete='off')
  .custom-file.mb-2
    input#inputVOD.custom-file-input(@change="updateForFileSelection" ref='inputVOD' type='file', accept="video/*, .mp4, .mov")
    label#labelVOD.custom-file-label(ref='labelVOD' for='inputVOD') {{ $t('admin.choosevideotouploadtext') }}
  b-form-input.mb-2(v-show='isReadyForUpload' v-model="uploadVODname" trim autocomplete="none" placeholder='Video Title (Optional)')
  b-button(ref='uploadVOD' block variant='primary' @click='startUpload' :disabled='!isReadyForUpload || isUploading') {{ $t('admin.uploadbutton') }}
  //- b-button#uploadVOD.mx-auto.d-block.animated.infinite(@click='startUpload' ref='uploadVOD' disabled :class="{ pulse: isReadyForUpload, visible: isReadyForUpload }") 
  //-   i.fas.fa-cloud-upload-alt.fa-4x.text-secondary(:class="[isReadyForUpload ? 'text-success' : 'text-secondary']")
  //- p.text-muted.text-center.text-uppercase.font-weight-light.small {{ isReadyForUpload ? 'Tap to upload' : 'no video selected'}}
  .progress.my-3(v-show='isUploading')
      #progressVOD.progress-bar.progress-bar-striped.progress-bar-animated.bg-success(ref='progressVOD' role="progressbar" :style="{width:`${uploadProgress}%`}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="1")

  // List
  h5.text-muted.text-center.mt-5 {{ $t('admin.choosevideostext') }}
  .table-responsive.border
    table.table.table-striped
      tbody
        tr(v-for="v,i in this.videos")
          td.align-middle
            router-link.font-weight-bold.text-dark.text-underline.text-uppercase(v-if="v.isActive && v.isApproved" :to="`${profile.get('name')}/video/${v.objectId}`" target='_blank') {{ v.name }}
            .text-secondary(v-else) {{ v.name }}
            br
            a(v-if="v.isActive && v.isApproved" :href="`${v.url}`" target='_blank').text-muted.m-0.small Uploaded {{ v.createdAt }}
            .text-muted.m-0.small(v-else) {{ v.createdAt }}                        
          td.align-middle
            .text-right
              button.btn.btn-sm.m-0.text-uppercase.btn-outline-dark(v-if="!v.isActive && !v.isApproved" :id="`${v.objectId}`" @click="toggleVOD" type='button' disabled) Processing
              button.btn.btn-dark.btn-sm.m-0.text-uppercase(v-else-if="v.isPrivate" :id="`${v.objectId}`" @click="togglePrivacy" type='button') Private
              button.btn.btn-sm.m-0.text-uppercase.btn-success(v-else :id="`${v.objectId}`" @click="togglePrivacy" type='button') Public
              //- button.btn.btn-success.btn-sm.m-0.text-uppercase(v-if="v.isApproved" :id="`${v.objectId}`" @click="toggleVOD" type='button') Active
              //- button.btn.btn-sm.m-0.text-uppercase.btn-outline-secondary(v-else-if="v.isActive" :id="`${v.objectId}`" @click="toggleVOD" type='button') Inactive
          //- td.align-middle
            .text-right
              button.btn.btn-dark.btn-sm.m-0.text-uppercase(v-if="v.isPrivate" :id="`${v.objectId}`" @click="togglePrivacy" type='button') Private
              button.btn.btn-sm.m-0.text-uppercase.btn-success(v-else :id="`${v.objectId}`" @click="togglePrivacy" type='button') Public
</template>


<script>
import axios from "axios";
import _ from "underscore";
import Parse from "../../services/parse";
let Profile = Parse.Object.extend("FanfestConfig");
let VOD = Parse.Object.extend("VOD");

const AWS = window.AWS

export default {
  name: "VideoList",
  data() {
    return {
      videos: [],
      profile: null,
      newVideoURL: '',
      isUploading: false,
      uploadVODname: '',
      uploadedVOD: 0,
      totalVOD: 0,
      uploadProgress: 0,
      didUploadSucceed: false,
      didUploadFail: false,
    };
  },
  mounted: function () {
    this.loadProfile();
  },
  computed: {
    isReadyForUpload: function() {
      return this.totalVOD > 0;
    }
  },
  watch: {},
  sockets: {},
  methods: {
    loadProfile: async function () {
      let query = new Parse.Query(Profile);
      query.equalTo('name', this.$route.params.fanfest)
      query.include('group')
      query.include('organizer')
      this.profile = await query.first().catch((error) => alert(error))

      this.loadVideos()
    },
    loadVideos: async function () {
      if (!this.profile) return

      let query = new Parse.Query(VOD);
      query.equalTo('audience', 'Group')
      query.equalTo("audienceID", this.profile.get('group').id)
      query.descending("createdAt")
      query.limit = 10000
      let data = await query.find().catch((error) => alert(error)) 
      
      this.videos = []
      for (var v of data) {
        let vjson = v.toJSON()
        if (vjson.isPrivate === undefined)
          vjson.isPrivate = false

        this.videos.push(vjson)
      }
    },
    uploadYT: async function() {
      let options = {
        user: this.profile.get('name'),
        url: this.newVideoURL,
        audienceID: this.profile.get('group').id
      }

      this.$refs.uploadButton.disabled = true 
      await axios.post(`https://backend.vici.cloud/app/admin/ytdl`, options).catch((error) => console.log(`Error occurred during YTDL processing: ${error}`))      
      
      await this.loadVideos()
      alert('Upload complete!')
      this.newVideoURL = ''
    },
    updateForFileSelection: function(event) {
      this.totalVOD = event.target.files.length;
      // if (this.isReadyForUpload) {
      //   this.$refs.uploadVOD.disabled = false;
      //   const self = this;
      //   setTimeout(() => {
      //     self.$refs.labelVOD.innerHTML = "Video Ready For Upload";
      //   }, 10);
      // }
    },
    startUpload: function(event) {
      event.preventDefault();
      // this.$refs.uploadVOD.disabled = true;
      this.isUploading = true
      this.uploadVOD();
    },
    uploadVOD: function() {
      // var totalUploadSize = 0;
      var keyTeamName = this.profile.get('name')
        .replace(/ /g, "")
        .replace("-", "")
        .toLowerCase();
      const self = this;

      _.each(this.$refs.inputVOD.files, function(f) {
        var selectedMovie = f;
        if (selectedMovie === undefined) return;

        // totalUploadSize += f.size;

        const contentType = selectedMovie.type;
        const s3 = new AWS.S3({
          accessKeyId: "AKIARKWJHIZ2SZ7XN3AJ",
          secretAccessKey: "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",
          useAccelerateEndpoint: true
        });

        var selectedMovieName = selectedMovie.name;
        var selectedMovieExtension = selectedMovieName.split(".").pop();

        const params = {
          Bucket: "vici-tv-content-091690059381-us-east-1",
          Key:
            "raw/" +
            keyTeamName +
            "/team/" +
            self.profile.get('organizer').get('user').id +
            Date.now() +
            "." +
            selectedMovieExtension,
          Body: selectedMovie,
          ContentType: contentType
        };

        s3.upload(params)
          .on("httpUploadProgress", evt => {
            console.log(evt.loaded + " / " + evt.total + " Bytes");
            self.uploadProgress = (evt.loaded / evt.total) * 100;
          })
          .send((err, data) => {
            if (err) {
              console.log(err);
              console.log(data);
              // showError();
              alert("Error uploading");
              return false;
            }

            self.notifyServer(data);
            // showSuccess();
          });
      });
    },
    notifyServer: async function(data) {
      let author = this.profile.get('organizer').get('user').id
      let name = this.uploadVODname.length > 0 ? this.uploadVODname : `Video from ${this.profile.get('group').get('name')}`
      let defaultAudience = 'Choose Audience: All Fan Clubs' // placeholder / legacy logic from location-based video targeting, to be updated
      const dataForServer = {
        vodAuthor: author,
        vodName: name,
        vodAudience: defaultAudience,
        // vodAudience: this.$refs.selectAudience.options[this.$refs.selectAudience.options.selectedIndex].text,
        vodData: data.Key
      };

      try {
        await axios.post(`${process.env.VUE_APP_BACKEND}/app/dashboard/video/new`,dataForServer);

        this.uploadedVOD++;
        if (this.uploadedVOD === this.totalVOD) {
          // now this happens on the backend for group/org
          // await axios.get(`${process.env.VUE_APP_BACKEND}/app/dashboard/refreshVODQueue/${this.profile.teamID}`, dataForServer);
          // location.reload();
          this.uploadVODname = ''
          this.isUploading = false
          this.uploadedVOD = 0
          this.totalVOD = 0
          // this.$refs.inputVOD.files = undefined
          await this.loadVideos()
          alert('Upload complete!')
        }
      } catch (err) {
        alert(err.message);
      }
    },
    toggleVOD: async function(event) {
      let selectedVOD = event.target.id;
      console.log(selectedVOD)
      try {
        let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/dashboard/video/toggle`,{vod:selectedVOD})
        let newStatus = response.data;
        this.videos.forEach(v => {
          if (v.objectId === selectedVOD) {
            v.isApproved = newStatus }
        })
      } catch (err) {
        alert(err.message);
      }

      event.target.blur()
    },
    togglePrivacy: async function(event) {
      let selectedVOD = event.target.id;
      console.log(selectedVOD)
      try {
        let response = await axios.post(`${process.env.VUE_APP_BACKEND}/app/dashboard/video/togglePrivacy`,{vod:selectedVOD})
        let newStatus = response.data;
        console.log(newStatus)
        this.videos.forEach(v => {
          if (v.objectId === selectedVOD) {
            v.isPrivate = newStatus 
          }
        })
      } catch (err) {
        alert(err.message);
      }
    },
  },
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 100vh;
  overflow-x: hidden;
}
</style>