<template lang="pug">
  .container-flex.bg
    form#payment-form.h-100(v-show='!didComplete && !useSSO')
      .row.m-3.h-100
        .col.align-self-center.h-100
          // Registration Info
          .d-xl-flex.mb-1             
            b-form-input.mr-3.my-1(v-model='fname' :placeholder="$t('register.firstNamePlaceholder')" trim)
            b-form-input.mr-3.my-1(v-model='lname' :placeholder="$t('register.lastNamePlaceholder')" trim)
            b-form-input.my-1(v-model='email' placeholder='Email' type='email' :state='emailValid' trim)

            //b-form-input#referral.my-1(v-model='referral'  type='hidden' :state='emailValid' trim)
          
          //b-form-input(v-if="profile.name === 'rbfa'" v-model='birthday' autocomplete='none' placeholder='Confirm birth date (YYYY-MM-DD)' :state='birthdayValid' trim)
          
          //.my-1.d-inline-flex.text-muted.border.px-3.py-2.w-100(v-if="profile.name === 'rbfa'" label='Gender') Gender
            b-form-radio-group.ml-2(v-model='gender' :options="[{text:'M', value:'M'},{text:'F', value:'F'},{text:'Other', value:'X'}]")
          // Payment
          .mb-3(v-if='(cost && !isVIP)')
            #card-element
            #card-errors.text-danger(role='alert')
          // Buttons  
          .mt-3
            b-button.text-uppercase.font-weight-bold.mx-2(ref='confirmButton' variant='success' @click="(cost && !isVIP && !disablePaymentsForDemo) ? pay() : register()" :disabled="!readyToSubmit") {{ action }}
            b-button.text-uppercase.mx-2(variant='outline-secondary' @click='close') {{ $t('register.cancelButton') }}
          // Agreements
          // .text-muted.text-center.mt-3 {{ $t('register.agreement') }}
          // a.text-muted(href='https://playvici.com/privacy') {{ $t('register.agreementLink') }}.

          .mt-3
            b-form-checkbox.text-muted.text-left(v-model='didAgreePolicy') {{ $t('register.agreement') }} 
              a.text-muted(:href='policyURL' target='_blank') {{ $t('register.agreementLink') }}
            b-form-checkbox.text-muted.text-left(v-model='didAgreeComms') {{ $t('register.newsletter', {group : $props.profile.group.name}) }}
                    
          .text-muted.text-center.mt-3(v-if='showDisclaimer') {{ $t('register.disclaimer', {channel: 'Univision'}) }}
          // #payment-request-button
    
    b-alert(v-show='didComplete && !useSSO' variant='success' :show='60') 
      p.m-0 {{ $t('register.success') }}
      a(:href='link' target='_blank') {{ $t('register.registrationLink') }}
      //- p.m-0(v-if="$props.event.hasOwnProperty('shouldSendRegistration') && $props.event.shouldSendRegistration != undefined && $props.event.shouldSendRegistration === false") {{ $t('register.checkEmailLater') }}
      //- p.m-0(v-else) {{ $t('register.checkEmail') }}

    b-alert(v-if='error' variant='danger' :show='5') {{ $t('register.error') }}

    .sso-overlay(v-if="useSSO")
      b-icon(icon='three-dots' animation='fade' font-scale='4')
      div.m-0.p-0
        a(:href='link' target='_blank') {{ $t('register.registrationLink') }}
          
</template>


<script>
import { mapState } from "vuex";
import axios from "axios";
import Amplitude from "../services/amplitude";

export default {
  name: "",
  data() {
    return {
      fname: "",
      lname: "",
      email: "",
      birthday: "",
      gender:"",
      stripe: null,
      card: null,
      token: null,
      didComplete: false,
      error: false,
      link: null,
      isVIP: false,
      didAgreePolicy: false,
      didAgreeComms: false,
      policyURL: 'https://playvici.com/privacy'
    };
  },
  props: {
    profile: Object,
    event: Object,
    host: Object,
    cost: Number,
    didAcceptCookies: Boolean,
    fanData: Object,
    useSSO: Boolean,
  },
  computed: {
    ...mapState({
      isEmbed: state => state.client.isEmbed,
    }),
    showDisclaimer: function() {
      if (this.$props.event.showDisclaimer) return true
      return false
    },
    emailValid: function() {
      if (this.email.length === 0) return null
      
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
    birthdayValid: function() {
      if (this.birthday.length === 0) return null
      
      var re = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
      re = /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/
      return re.test(this.birthday)
    },
    readyToSubmit: function() {
      /* if (!this.$props.didAcceptCookies)
        return false; */

      if (this.fname.length === 0 || this.lname.length === 0 || this.email.length === 0)
        return false;

      if (!this.emailValid)
        return false;

      if (this.$props.cost && !this.isVIP && !this.disablePaymentsForDemo) {
        var displayError = document.getElementById("card-errors");
        if (displayError.textContent.length > 0)
          return false;
      }

      /* if (this.profile.name === 'rbfa' && !this.birthdayValid)
        return false; */

      if (!this.didAgreePolicy)
        return false;

      return true;      
    },
    action: function() {
      return (this.cost && !this.isVIP) ? `${this.$t('register.purchaseButton')} €${this.finalCost.toFixed(2)}` : this.$t('register.registerButton');
    },
    finalCost: function() {
      return this.cost * 1.1
    },
    disablePaymentsForDemo: function() {
      return this.$props.profile.isDemo;
    }
  },
  components: {},
  mounted: function() {
    if (this.cost) this.configPayments();

    if (this.$props.fanData) {
      this.fname = this.$props.fanData.fname
      this.lname = this.$props.fanData.lname
      this.email = this.$props.fanData.email

      if (this.useSSO) {
        if (this.cost && !this.isVIP && !this.disablePaymentsForDemo) this.pay()
        else this.register()
      }
    }

    if (this.profile.name === 'rbfa') {
      if (window.navigator.language.indexOf('fr') > -1)
        this.policyURL = 'https://www.rbfa.be/fr/privacy'
      else
        this.policyURL = 'https://www.rbfa.be/en/privacy'
    }
    else if (this.profile.name === 'ol')
      this.policyURL = 'https://olstcweb.blob.core.windows.net/resources/FanFest/Appendix%203%20-%20Charter%20relating%20to%20content%20moderation%20-%20FANFEST.pdf'

  },
  watch: {
    email: function() {
      if (!this.cost) return

      if (this.$props.event.allowList.indexOf(this.email.toLowerCase()) >= 0)
        this.isVIP = true
      else
        this.isVIP = false; 
      
      this.configPayments();
    }
  },
  sockets: {},
  methods: {
    configPayments: function() {
      /* this.stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_KEY_PROD}`, {
        stripeAccount: this.configConnectedAccount()
      }); */
      this.stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_KEY_PROD}`)

      // Create an instance of Elements.
      let elements = this.stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      var style = {
        base: {
          color: "#32325d",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      this.card = elements.create("card", { style: style });
      this.card.mount("#card-element");

      // Handle real-time validation errors from the card Element.
      this.card.addEventListener("change", function(event) {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      /**
       * Payment Request Element
       */
      /* var paymentRequest = this.stripe.paymentRequest({
        country: "FR",
        currency: "usd",
        total: {
          amount: 4,
          label: "Total"
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true
      });

      var paymentRequestElement = elements.create("paymentRequestButton", {
        paymentRequest: paymentRequest
      });

      paymentRequest.canMakePayment().then(function(result) {
        if (result) {
          /* document.querySelector(".example4 .card-only").style.display = "none";
        document.querySelector(
          ".example4 .payment-request-available"
        ).style.display = "block";
          paymentRequestElement.mount("#payment-request-button");
        } else {
          // checkout manually
        }
      }); */
    },
    pay: async function() {
      this.$refs.confirmButton.disabled = true;
      let cost = this.finalCost.toFixed(2) * 100

      // Referral tracking      
      var config = {
        cookieKey: 'warmup.referral'
      }

      let getCookie = function() {
        if (hasCookie()) {
          var value = "; " + document.cookie;
          console.log('has cookie')
          var parts = value.split("; " + 'warmup.referral' + "=");
          if (parts.length == 2) {
              return parts.pop().split(";").shift()
          }
        }
        else {
          console.log('no cookie')
          return ""
        }
      }

      let hasCookie = function () {
        return document.cookie && document.cookie.includes(config.cookieKey)
      }

      console.log('referral cookie: '+getCookie())

      let dataForTokenRequest = {
        customer: {
          name: `${this.fname} ${this.lname}`,
          email: this.email
        },
        cost: cost.toFixed(0),
        event: this.$props.event,
        profile: this.$props.profile,
        referral: getCookie()
      };
      let response = await axios.post(
        `${process.env.VUE_APP_BACKEND}/app/payments/new`,
        dataForTokenRequest
      );
      let clientSecret = response.data.client_secret;

      console.log(clientSecret)

      let result = await this.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: `${this.fname} ${this.lname}`
          },
        },
        // setup_future_usage: "off_session"
      });
      console.log(result)
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error.message);
        var displayError = document.getElementById("card-errors");
        displayError.textContent = result.error.message;
        this.$refs.confirmButton.disabled = false;
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          console.log(JSON.stringify(result));
          this.register();
        }

        let data = {
          name: `${this.fname} ${this.lname}`,
          email: this.email,
          profile: this.$props.profile.name,
          token: getCookie()
        };

      axios
        .post(`${process.env.VUE_APP_BACKEND}/app/payments/referral/warmup`, data)
        .catch(err => {
          console.log('error logging referral: '+err.message);
        });
      }
    },
    configConnectedAccount: function() {
        if (this.$props.profile.name === 'lozenworld')  
          return 'acct_1I4RJKRd9cVVTV9g'
        else if (this.$props.profile.name === 'concert')
          return 'acct_1I9X5aDHIIXzZmXi'
        else
          return ''
    },
    getExternalID: function() {
      if (window.localStorage.getItem(process.env.VUE_APP_COOKIE_ID)) 
        return window.localStorage.getItem(process.env.VUE_APP_COOKIE_ID)      
      else if (this.$route.query.externalID) 
        return this.$route.query.externalID

      const url = URL.createObjectURL(new Blob())
      let uuid = url.substring(url.lastIndexOf('/') + 1)
      window.localStorage.setItem(process.env.VUE_APP_COOKIE_ID, uuid)
      return uuid
    },
    register: function() {
      this.$refs.confirmButton.disabled = true;
      console.log("Sending email after registration...");

      let fan = {
        fname: this.fname.trim(),
        lname: this.lname.trim(),
        email: this.email.toLowerCase().trim(),
        birthday: this.birthday,
        gender: this.gender,
        language: window.navigator.language,
        canEmail: this.didAgreeComms,
        externalID: this.getExternalID()
      };
      
      let data = {
        fan: fan,
        profile: this.$props.profile,
        event: this.$props.event, 
        host: this.$props.host ? this.$props.host.fan : null,
        clientURL: this.$route.query.origin ? decodeURI(this.$route.query.origin) : `${window.location.protocol}//${window.location.hostname}`
      };

      axios
        .post(`${process.env.VUE_APP_BACKEND}/app/register/v2`, data)
        .then((response) => {
          this.link = response.data.link;
          window.localStorage.setItem(process.env.VUE_APP_COOKIE_ID, response.data.registration.fan.externalID) // save external ID in case existing fan on new web client
          this.showSuccess();
        })
        .catch(err => {
          Promise.reject(err.message);
          this.showError();
        });
    },
    showSuccess: function() {
      let self = this;
      self.didComplete = true;
      setTimeout(() => {
        self.$emit("close");
      }, 60000);

      self.$gtag.event('register', {
        'event_category': 'growth',
      })
      Amplitude.getInstance().logEvent('Registered')

      setTimeout(() => {
        if (self.useSSO) 
          window.open(`${self.link}`, '_blank') 
      }, 50);
      setTimeout(() => {
        self.$emit("close");
      }, 60000);
    },
    showError: function() {
      this.$refs.confirmButton.disabled = false;
      let self = this;
      self.error = true;
      setTimeout(() => {
        self.error = false;
      }, 5000);
    },
    close: function() {
      this.$emit("close");
    }
  }
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 100vh;
  overflow-x: hidden;
}

.sso-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>