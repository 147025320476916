<template lang="pug">
    b-container.m-0.p-0(fluid style="background:black;")
        .row
          .col
            img.header.mx-auto.d-block(src="https://fanfest-resources.s3.amazonaws.com/nbatopshot-header2.png")
        //- .row(v-show="code.length === 0 && this.$route.query.r === undefined && this.$route.query.host === undefined")
          .col
            b-form-textarea.my-2.ml-3.d-block.w-75( v-model="code" size="lg" placeholder="embed code" rows="1")
        .row
          .col-1
          .col.p-3
            div(id='fanfest' profile='nbatopshot' style="height:75vh;")
          .col-1
            //- div(v-html="renderFrame()")
        //- .row(v-show="code.length > 0")
          .col
            div.iframe(v-html="renderFrame()")
        
        

</template>

<script>
import axios from "axios";

export default {
  name: "",
  metaInfo() {
    return {
      title: `FanFest SDK Demo`
    };
  },
  data() {
    return {
        url: 'http://localhost:8080/psg',
        header:'',
        code: "",
        demo: {}
    };
  },
  created: function() {
    loadExternalScript("/sdk.min.js")
    setTimeout(() => {loadExternalScript("/sdk-sso-test.js")}, 100);
  },
  mounted: function() {
    // this.checkDemo()
    // this.configDemo()
    // window.FanFestSSO('Adam','Jones','adamtest@playvici.com')
  },
  computed: {},
  watch: {
    /* code: function() {
      setTimeout(() => {
        // this.configDemo()
        // this.embedFanFest()
      }, 500);
    } */
  },
  sockets: {},
  methods: {
    checkDemo: async function() {
      let params = new URLSearchParams(window.location.search)
      var registrationID = ''
      if (params.has('r')) registrationID = params.get('r')
      else if (params.has('host')) registrationID = params.get('host')

      if (registrationID.length > 0) { 
        let registrationResponse = await axios(`${process.env.VUE_APP_BACKEND}/app/register/${registrationID}`);
        let registration = registrationResponse.data;
        this.code = `<div id="fanfest" profile="${registration.profile}" style="height:75vh; width=100vw;"></div>`
      }
    },
    configDemo: function() {
      let element = document.getElementById('fanfest')
      this.header = `/${element.getAttribute('profile').trim()}-header.png`

      let params = new URLSearchParams(window.location.search)
      document.body.style.backgroundColor = params.has('r') ? 'black' : 'white'
    },
    embedFanFest: function() {
      let iframeSource = function() {
        var src = `${window.location.protocol}//${window.location.hostname}${window.location.hostname === 'localhost' ? ':8080' : ''}/${document.getElementById('fanfest').getAttribute('profile').trim()}`
        let params = new URLSearchParams(window.location.search)

        if (params.has('r')) src += `/live?r=${params.get('r')}&origin=${encodeURIComponent(document.location)}`
        else if (params.has('host')) src += `?host=${params.get('host')}&origin=${encodeURIComponent(document.location)}`
        else src += `?origin=${encodeURIComponent(document.location)}`

        return src
      }
      
      let iframe = document.createElement('iframe')
      iframe.setAttribute('frameborder', 0)
      iframe.setAttribute('allowfullscreen', false)
      iframe.setAttribute('allow', `camera; microphone; clipboard-read; clipboard-write self "${window.location.protocol}//${window.location.hostname}";`)
      iframe.setAttribute('scrolling', 'yes')
      iframe.src = iframeSource()
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      iframe.sandbox = 'allow-same-origin allow-scripts allow-forms allow-modals allow-popups'

      let element = document.getElementById('fanfest')
      element.style.backgroundColor = document.body.style.backgroundColor
      element.appendChild(iframe)
    },
    renderFrame: function() {
      return `${this.code.trim()}`
    }
  }
};

function loadExternalScript(url) {
  console.log('loading script: '+url)
  let script = document.createElement("script");
  script.setAttribute("src", url);
  document.head.appendChild(script);
}
</script>


<style>
/* .bg {
  height: 100vh;
  /* background-color: white; 
  /* overflow-x: hidden; 
} */
/* .iframe {
  height: 70vh;
  width: 100vw;
}
 */.header {
  width: 100vw;
}

</style>