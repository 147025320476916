<template lang="pug">
  .bg.row.no-gutter(v-if='profile')
    // .col-md-4.col-lg-6.d-none.d-md-flex.bg-image(:style="{backgroundImage: 'url('+imageBackground+')'}")
    .col-md.col-lg.d-sm-flex.bg-white.h-25
      .container.d-flex.align-items-top.justify-content-center.my-2
        .d-flex.flex-column
          a(:href="`/${profileName}`" target='_blank')
            img.mx-auto.d-block(:src='logo' style="height:10vh")
            img#logo.mx-auto.d-block.my-1(src='/fanfest-logo.png' style="height:8vh")
          b-button.text-uppercase.font-weight-bold(variant='outline-secondary' @click='showEditor') {{ $t('admin.editprofilebutton') }}
          //- b-button.text-uppercase.font-weight-bold(variant='outline-secondary' :to="`/${profileName}`" target='_blank') View Profile
    .col-sm-12.col-lg-8.px-0.bg-white.h-75
      .p-4
        b-tabs(fill pills align='center')
          //- b-tab(title='Fans')
            FanList
          b-tab(:title="$t('admin.eventsbutton')" active)
            EventList
          b-tab(:title="$t('admin.offersbutton')")        
            OfferList
          b-tab.p-2(:title="$t('admin.videosbutton')")
            VideoList
    
    b-modal(id='modal-profile-editor' size='lg' centered hide-header hide-footer)
      ProfileEditor
</template>


<script>
import { mapState } from "vuex";

import EventList from '@/components/admin/EventList.vue'
import VideoList from '@/components/admin/VideoList.vue'
import OfferList from '@/components/admin/OfferList.vue'
import FanList from '@/components/admin/FanList.vue'
import ProfileEditor from '@/components/admin/ProfileEditor.vue'

import Parse from "../../services/parse";
let Profile = Parse.Object.extend("FanfestConfig");
let Registration = Parse.Object.extend("Registration");

//import axios from "axios";

export default {
  name: "ProfileAdmin",
  components: {
    EventList,
    VideoList,
    OfferList,
    FanList,
    ProfileEditor
  },
  data() {
    return {
      profile: null,
      profileName: '',
      imageBackground: '',
      logo: ''
    };
  },
  mounted: function() {
    this.load()
  },
  computed: {
    ...mapState({
      //groupID: state => state.client.group.objectId
    }),
    ...mapState({
      //canHost: state => state.fanfest.canHost,
    })
  },
  watch: {},
  sockets: {},
  methods: {
    load: async function () {
      let query = new Parse.Query(Profile);
      query.equalTo('name', this.$route.params.fanfest)
      query.include('group')

      let profile = await query.first().catch((error) => {
        if (!profile) alert(error);
      })

      this.profileName = profile.get('name')
      this.imageBackground = profile.get('loginBG')
      this.logo = profile.get('group').get('image')

      // security check
      let rID = this.$route.query.r;
      if (!rID) this.$router.push({ path: "/error" }); // no registration id present
      
      // superuser vibes
      if (rID === 'jeez' || rID === 'robbiefingers') {
        this.profile = profile
        return
      }

      let queryR = new Parse.Query(Registration)
      queryR.equalTo('objectId', rID)
      queryR.include('fan')
      queryR.descending('createdAt')
      let r = await queryR.first()
      if (!r) this.$router.push({ path: "/error" }); // invalid registration id
      

      let admin = r.get('fan')
      var adminList;
      adminList = profile.get('admins') ? profile.get('admins').slice() : []
      adminList.push(profile.get('admin').toLowerCase())
      if (adminList.indexOf(admin.get('email').toLowerCase()) < 0 && adminList.indexOf(admin.get('name').toLowerCase().trim()) < 0) 
        this.$router.push({ path: "/error" }); // invalid admin

      this.profile = profile
    },
    showEditor: function() {
      this.$bvModal.show('modal-profile-editor')
    }
  }
};
</script>


<style scoped>
.bg {
  background-color: white;
  height: 100vh;
}

.bg-image {
  background-size: cover;
  background-position: center;
}
</style>